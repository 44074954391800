import {
  Entity,
  EntityType,
  FpsComponent,
  Project,
  Root,
  SelectionSystem,
  TimeComponent,
  commitUndo,
  lerp,
  round,
} from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import {
  KeyModificator,
  TimeFormat,
  getGroupFromKeyframe,
  getSelection,
  getVisibleKeyframes,
  usePlayback,
  useSession,
  useSettings,
  useTimeline,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import * as timeConverters from '../../../../helpers/timeConverters'
import { useFormatTime } from '../../../properties-panel/components/formatted-time'
import * as styles from './index.scss'

function roundBy(value: number, options?: { by: number }): number {
  const roundBy = options?.by || 5
  const valueToWork = value
  const diff = valueToWork % roundBy
  const full = valueToWork - diff

  if (diff < roundBy / 2) {
    return full
  }

  return full + roundBy
}

/**
 * @todo move to separated folder
 */
export function useSnappedTime(payload: { project: Project }): {
  /**
   * Receive time and return snapped time to closest keyframe or time stamp.
   * Used when user hold shift.
   */
  getSnappedTime: (time: number) => number
} {
  const { timeFormat } = useSettings()
  const { project } = payload
  const projectFps = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(FpsComponent).value

  const minTimeThresholdToSnap =
    timeFormat === TimeFormat.Frames ? (1 / projectFps) * 10 : 0.1

  const getSnappedTime = (time: number) => {
    const sortedKeyframes = R.sort((left, right) => {
      return (
        Math.abs(time - left.getComponentOrThrow(TimeComponent).value) -
        Math.abs(time - right.getComponentOrThrow(TimeComponent).value)
      )
    }, getVisibleKeyframes(project))

    const closestKeyframe = R.head(sortedKeyframes)

    if (closestKeyframe != null) {
      const closestTime =
        closestKeyframe.getComponentOrThrow(TimeComponent).value
      const timeDifference = Math.abs(closestTime - time)
      if (timeDifference < minTimeThresholdToSnap) {
        return closestTime
      }
    }

    const closestTime = roundBy(time, { by: minTimeThresholdToSnap })
    const timeDifference = Math.abs(closestTime - time)

    if (timeDifference < minTimeThresholdToSnap) {
      return closestTime
    }

    return time
  }

  return {
    getSnappedTime,
  }
}

// @NOTE: in format [seconds, [width_from, width_to]]
const steps: [number, [number, number]][] = [
  // each 20 seconds
  [1 / 20, [0, 5]],
  // each 5 seconds
  [1 / 5, [5, 10]],
  // each 2 seconds
  [1 / 2, [10, 25]],
  // each 1 second
  [1 / 1, [25, 100]],
  // each 0.5 seconds
  [1 / 0.5, [100, 400]],
  // each 0.1 seconds
  [1 / 0.1, [400, 1000]],
  // each 0.05 seconds
  [1 / 0.05, [1000, 2500]],
  // each 0.01 seconds
  [1 / 0.01, [2500, 1000000]],
]

export interface IProps {
  project: Project
  projectDuration: number
  projectFps: number
  parentTrackWidth: number
}
export const TimeIndicators: React.FCC<IProps> = observer(
  ({ project, projectDuration, projectFps, parentTrackWidth }) => {
    const playback = usePlayback()
    const session = useSession()
    const selection = project.getSystemOrThrow(SelectionSystem)
    const timeline = useTimeline()
    const { timeFormat } = useSettings()

    const { toFormat } = useFormatTime()
    const { getSnappedTime } = useSnappedTime({ project })

    const updateTime = React.useCallback(
      (time: number) => {
        playback.pause()
        if (session.keyModificators.includes(KeyModificator.Shift)) {
          playback.updateTime(getSnappedTime(time))
        } else {
          playback.updateTime(time)
        }
        // @TODO: move to use case
        // @NOTE: deselct keyframes when slider moves
        const selectedKeyframes = getSelection(project, EntityType.Keyframe)
        if (selectedKeyframes.length > 0) {
          let nodesToSelect: Entity[] = []
          selectedKeyframes.forEach((keyframe) => {
            const group = getGroupFromKeyframe(keyframe)

            if (group.layer != null) {
              nodesToSelect.push(group.layer)
            }
          })
          selection.replace(R.uniq(nodesToSelect.map((n) => n.id)))
          commitUndo(nodesToSelect[0].getProjectOrThrow())
        }
      },
      [timeFormat, projectFps, playback, session, getSnappedTime, selection]
    )

    const duration = projectDuration
    const zoom = timeline.visibleRangeDuration / projectDuration

    const containerRef = React.useRef<any>(null)

    const { startAtX, offsetX, isListening, startListen } = useMouseMove()

    // TODO: refactor
    const handlerWidth = 6

    const roundDetails = React.useMemo(() => {
      const coefficient = lerp(1 - zoom, 2, 4)
      return Math.round(coefficient)
    }, [])

    const levelOfDetails = (() => {
      const width = parentTrackWidth
      const secondWidth = width / duration

      const target = steps.find(
        ([_, [from, to]]) => secondWidth >= from && secondWidth < to
      )

      if (target == null) {
        return 100
      }

      return target[0]
    })()

    const timeItems = R.range(
      0,
      Math.round((duration + 1) * levelOfDetails)
    ).map((item) => {
      return round(item * (1 / levelOfDetails), { fixed: roundDetails })
    })

    React.useEffect(() => {
      if (isListening) {
        const containerOffset =
          containerRef.current.getBoundingClientRect().left
        const offset = startAtX + offsetX - containerOffset - handlerWidth
        const time = timeConverters.convertPixelsToTime({
          pixels: offset,
          trackWidth: parentTrackWidth - handlerWidth * 2,
          projectDuration: duration,
        })

        updateTime(time)
      }
    }, [isListening, startAtX, offsetX])

    const onMouseDown = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const timeItemsPrepared = React.useMemo(
      () =>
        timeItems.map((timeItem) => ({
          left:
            timeConverters.convertTimeToPixels({
              time: timeItem,
              trackWidth: parentTrackWidth - handlerWidth * 2,
              projectDuration: duration,
            }) + handlerWidth,
          value: toFormat(timeItem),
        })),
      [timeItems, toFormat]
    )

    return (
      <button
        ref={containerRef}
        className={styles.container}
        onMouseDown={onMouseDown}
        // @NOTE: required to calculate selection in tracks
        data-model-type="time-indicators"
      >
        {timeItemsPrepared.map((timeItem, idx) => (
          <div
            key={`${timeItems.length}-${timeItem}-${idx}`}
            className={styles.item}
            style={{
              left: timeItem.left,
            }}
          >
            <span className={styles['item-text']}>{timeItem.value}</span>
            <span className={styles['item-separator']} />
          </div>
        ))}
      </button>
    )
  }
)
