import {
  useImagesStore,
  usePlayback,
  useProject,
  useSession,
  useViewport,
} from '@aninix/core'
import { Popover, PopoverProps } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { downloadUriAsBlob } from '../../../../helpers'
import { useUseCases } from '../../../../use-cases'
import { useMenuInteractor } from './interactor'
import { MenuRouter } from './router'

export interface IProps {
  dashboardUrl: string
  handleMenuClose: () => void
  isMenuOpen: boolean
  anchorEl: PopoverProps['anchorEl']
}
export const Menu: React.FCC<IProps> = observer(
  ({ dashboardUrl, handleMenuClose, isMenuOpen, anchorEl }) => {
    const session = useSession()
    const images = useImagesStore()
    const playback = usePlayback()
    const viewport = useViewport()
    const { useDownloadProjectUseCase } = useUseCases()
    const project = useProject()

    const downloadProjectUseCase = useDownloadProjectUseCase({
      images,
    })

    const interactor = useMenuInteractor({
      dashboardUrl,
      downloadFile: ({ strinfigiedData, fileName }) => {
        const blob = new Blob([strinfigiedData], { type: 'application/json' })
        downloadUriAsBlob(blob, fileName)
      },
      downloadProjectUseCase,
      openUrl: (url) => window.open(url, '_blank'),
      project,
      session,
      viewport,
      playback,
    })
    return (
      <Popover
        classes={{
          paper: 'overflow-visible',
        }}
        hideBackdrop
        PaperProps={{ style: { background: 'transparent' } }}
        onClick={handleMenuClose}
        elevation={0}
        open={isMenuOpen}
        anchorEl={anchorEl}
        transitionDuration={150}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="pt-4">
          {/* @TODO: replace dashboard url */}
          <MenuRouter interactor={interactor} />
        </div>
      </Popover>
    )
  }
)
