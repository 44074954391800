import {
  DashComponent,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  ExportPreset,
  FpsComponent,
  GapComponent,
  HashComponent,
  NameComponent,
  PaintTypeComponent,
  Project,
  RenderScaleComponent,
  RenderSuffixComponent,
  RenderTypeComponent,
  Root,
  TrimEndComponent,
  TrimStartComponent,
  getDuration,
  getEndTime,
  getSize,
  getSortedKeyframes,
  getStartTime,
} from '@aninix-inc/model'
import { PaintType, RenderType } from '@aninix-inc/model/legacy'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import {
  ImagesStore,
  featureFlags,
  useCases,
  useReloadOnAnyUpdate,
  yieldToMain,
} from '@aninix/core'
import {
  GIF_MAX_FPS,
  useRenderProjectToFile,
} from '@aninix/core/use-cases/render-project-to-file'
import { useLogger } from '@aninix/logger'
import { openDB } from 'idb'
import JsZip from 'jszip'
import * as R from 'ramda'
import * as React from 'react'
import { config } from '../../../../config'
import { downloadUriAsBlob } from '../../../../helpers/downloadUri'
import { ExportPresetView } from './view'

function formatRemainingTime(seconds?: number): string {
  if (seconds == null) {
    return 'Estimating time remaining...'
  }

  const m = Math.floor(seconds / 60)
  const s = seconds % 60

  if (m < 1) {
    return 'Less than a minute remaining'
  }

  let finalString = 'About'

  if (m === 1) {
    finalString += ` ${m} minute`
  } else {
    finalString += ` ${m} minutes`
  }

  finalString += ' remaining'

  return finalString
}

const OPTIMIAL_WIDTH_OF_VIDEO = 1920
const OPTIMAL_HEIGHT_OF_VIDEO = 1080
const OPTIMAL_FRAME_RATE_OF_VIDEO = 60
const OPTIMAL_DURATION_OF_VIDEO_IN_SECONDS = 5
const OPTIMAL_SIZE_OF_VIDEO =
  OPTIMIAL_WIDTH_OF_VIDEO *
  OPTIMAL_HEIGHT_OF_VIDEO *
  OPTIMAL_FRAME_RATE_OF_VIDEO *
  OPTIMAL_DURATION_OF_VIDEO_IN_SECONDS

const OPTIMIAL_WIDTH_OF_GIF = 1600
const OPTIMAL_HEIGHT_OF_GIF = 1200
const OPTIMAL_FRAME_RATE_OF_GIF = 60
const OPTIMAL_DURATION_OF_GIF_IN_SECONDS = 10
const OPTIMAL_SIZE_OF_GIF =
  OPTIMIAL_WIDTH_OF_GIF *
  OPTIMAL_HEIGHT_OF_GIF *
  OPTIMAL_FRAME_RATE_OF_GIF *
  OPTIMAL_DURATION_OF_GIF_IN_SECONDS

export type TextError = {
  title: string
  description: string
}

interface IProps {
  anchorEl: HTMLDivElement | null
  onClose: () => void
  project: Project
  previewRangeStart: number
  previewRangeEnd: number
  imagesStore: ImagesStore
}

export interface IRenderInteractor {
  anchorEl: HTMLDivElement | null
  onClose: () => void

  exportPresets: ExportPresetView[]

  activePresetIdx: number
  createPreset: () => void
  updatePreset: (payload: {
    presetId: string
    preset: Partial<ExportPresetView>
  }) => void
  deletePreset: (payload: { presetId: string }) => void

  startRender: () => void
  cancelRender: () => void
  // @NOTE: in range 0...1
  renderProgress: number
  isRendering: boolean

  isArchiving: boolean

  isRenderFinished: boolean
  startOver: () => void

  errorMessage?: React.ReactNode
  warningMessages?: React.ReactNode[]
  statusMessage?: React.ReactNode

  isViewVisible: boolean

  includeOnlySelectedRangeAllowed: boolean
  includeOnlySelectedRange: boolean
  toggleIncludeOnlySelectedRange: () => void
}

export const useRenderInteractor = ({
  anchorEl,
  onClose,
  project,
  imagesStore,
  previewRangeStart,
  previewRangeEnd,
}: IProps): IRenderInteractor => {
  useReloadOnAnyUpdate(project)
  const analytics = useAnalytics()
  const logger = useLogger()
  const renderStartTimeRef = React.useRef<number>(Date.now())
  const [shouldStartRender, setShouldStartRender] = React.useState(false)
  const [isRendering, setIsRendering] = React.useState(false)
  const [isArchiving, setIsArchiving] = React.useState(false)
  const [isRenderFinished, setIsRenderFinished] = React.useState(false)
  const [includeOnlySelectedRange, setIncludeOnlySelectedRange] =
    React.useState(false)

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  )

  const [activePresetIdx, setActivePresetIdx] = React.useState<number>(0)
  const renderedFiles = React.useRef<{ data: Blob; fileName: string }[]>([])
  const exportPresets = project.getEntitiesByType(ExportPreset)
  const root = project.getEntityByTypeOrThrow(Root)
  const entry = project.getEntitiesByPredicate((entity) =>
    entity.hasComponent(EntryComponent)
  )[0]!

  const addFileToDB = async (file: File) => {
    const db = await openDB('aninix-lottie-preview', 1, {
      upgrade(db) {
        db.createObjectStore('files', { keyPath: 'id' })
      },
    })
    await db.add('files', { id: '0', file })
  }

  const handleLottiePreivew = React.useCallback(
    ({ data, fileName }: { data: Blob; fileName: string }) => {
      const fileExtension = fileName.split('.').at(-1) ?? ''

      if (
        ['json', 'lottie', 'tgs'].includes(fileExtension) &&
        data !== undefined
      ) {
        addFileToDB(new File([data], fileName)).then(() => {
          window.open('/lottie-preview?post-render-preview=true', '_blank')
        })
      }
    },
    [addFileToDB]
  )

  const downloadFiles = React.useCallback(async () => {
    logger.log('[render] start downloading files')

    setIsArchiving(true)
    const getFinalBlob = async () => {
      // @NOTE: just download solo file
      if (renderedFiles.current.length === 1) {
        const file = R.head(renderedFiles.current)!

        return file
      }

      // @NOTE: compress all files into one *.zip
      const zip = new JsZip()

      const filesCount = renderedFiles.current.length
      for (let i = 0; i < filesCount; i += 1) {
        const file = renderedFiles.current[i]
        logger.log(`[render] archiving file "${file.fileName}"`)
        zip.file(file.fileName, file.data)
      }

      const archive = await zip.generateAsync({ type: 'blob' })
      logger.log('[render] archiving of rendered files finished')
      return {
        data: archive,
        fileName: `${root.getComponentOrThrow(NameComponent).value}.zip`,
      }
    }

    const fileToDownload = await getFinalBlob()
    downloadUriAsBlob(
      fileToDownload.data,
      fileToDownload.fileName,
      featureFlags.lottiePreview
        ? () => handleLottiePreivew(fileToDownload)
        : undefined
    )
    setIsRenderFinished(true)
    setIsArchiving(false)
    analytics.track({
      eventName: AnalyticsEvent.RenderFilesSuccessfullyDownloaded,
    })
    window.dispatchEvent(new CustomEvent('render-finished'))
  }, [analytics, handleLottiePreivew, logger, root])

  const handleNextPreset = React.useCallback(async () => {
    const nextIdx = activePresetIdx + 1

    if (nextIdx > exportPresets.length - 1) {
      setActivePresetIdx(0)
      await downloadFiles()
      return
    }

    setActivePresetIdx(nextIdx)
    setShouldStartRender(true)
  }, [exportPresets, activePresetIdx, downloadFiles])

  const activePreset = React.useMemo(
    () => exportPresets[activePresetIdx],
    [exportPresets, activePresetIdx]
  )

  const render = useRenderProjectToFile({
    project,
    startTime: includeOnlySelectedRange ? previewRangeStart : undefined,
    endTime: includeOnlySelectedRange ? previewRangeEnd : undefined,
    exportPreset: activePreset,
    imagesStore,
    onDone: ({ data, fileName }) => {
      setIsRendering(false)

      analytics.track({
        eventName: AnalyticsEvent.RenderFinished,
        properties: {
          renderDurationInSeconds:
            (Date.now() - renderStartTimeRef.current) / 1000,
        },
      })

      renderedFiles.current.push({ data, fileName })
      handleNextPreset()
    },
    onError: (message) => {
      setErrorMessage(message)

      analytics.track({
        eventName: AnalyticsEvent.RenderErrorHappen,
        properties: {
          message: message,
        },
      })
    },
    logger,
  })

  const createPreset: IRenderInteractor['createPreset'] =
    // @TODO: move this logic to the model
    React.useCallback(() => {
      const localExportPresets = project.getEntitiesByPredicate(
        (e) =>
          e.getComponentOrThrow(EntityTypeComponent).value ===
          EntityType.ExportPreset
      )
      const latestPreset = localExportPresets[localExportPresets.length - 1]
      const nextRenderType = (() => {
        const renderType =
          latestPreset.getComponentOrThrow(RenderTypeComponent).value
        switch (renderType) {
          case RenderType.mp4:
            return RenderType.gif
          case RenderType.gif:
            return RenderType.webm
          case RenderType.webm:
            return RenderType.mp4
          default:
            return RenderType.mp4
        }
      })()
      const exportPreset = project.createEntity(ExportPreset)
      exportPreset.updateComponent(RenderTypeComponent, nextRenderType)
    }, [project])

  const updatePreset: IRenderInteractor['updatePreset'] = React.useCallback(
    ({ presetId, preset }) => {
      const exportPreset = project.getEntityOrThrow(presetId)

      if (preset.scale != null) {
        exportPreset.updateComponent(RenderScaleComponent, preset.scale)
      }

      if (preset.type != null) {
        exportPreset.updateComponent(RenderTypeComponent, preset.type)
      }

      if (preset.suffix != null) {
        exportPreset.updateComponent(RenderSuffixComponent, preset.suffix)
      }
    },
    [project]
  )

  const deletePreset: IRenderInteractor['deletePreset'] = React.useCallback(
    ({ presetId }) => {
      project.removeEntity(presetId)
    },
    [project]
  )

  const startRender: IRenderInteractor['startRender'] =
    React.useCallback(async () => {
      setErrorMessage(undefined)
      setShouldStartRender(false)
      setIsRendering(true)
      await yieldToMain()
      setTimeout(() => {
        render.start()
      }, 1)

      // @NOTE: required to load all images to buffer
      const imagePaints = project.getEntitiesByPredicate(
        (entity) =>
          entity.getComponent(PaintTypeComponent)?.value === PaintType.Image
      )
      await imagesStore.loadImagesByHashes(
        imagePaints.map(
          (paint) => paint.getComponentOrThrow(HashComponent).value
        )
      )

      renderStartTimeRef.current = Date.now()
      const size = getSize(entry)
      analytics.track({
        eventName: AnalyticsEvent.RenderStarted,
        properties: {
          preset: {
            scale: activePreset.getComponentOrThrow(RenderScaleComponent).value,
            format: activePreset.getComponentOrThrow(RenderTypeComponent).value,
          },
          projectDurationInSeconds: getDuration(root),
          frameSize: {
            x: size.x,
            y: size.y,
          },
        },
      })
    }, [render, analytics, activePreset, entry, imagesStore, project, root])

  const cancelRender: IRenderInteractor['cancelRender'] =
    React.useCallback(() => {
      setErrorMessage(undefined)
      setActivePresetIdx(0)
      render.cancel()
      setIsRendering(false)

      analytics.track({
        eventName: AnalyticsEvent.RenderCancelled,
        properties: {
          renderDurationInSeconds:
            (Date.now() - renderStartTimeRef.current) / 1000,
        },
      })
    }, [render, analytics])

  const startRenderByUser = React.useCallback(async () => {
    if (exportPresets.length > 1) {
      analytics.track({
        eventName: AnalyticsEvent.RenderStartedWithMultiplePresets,
        properties: {
          presetsCount: exportPresets.length,
        },
      })
    }

    renderedFiles.current = []
    setShouldStartRender(true)
  }, [analytics, exportPresets])

  const handleClose: IRenderInteractor['onClose'] = React.useCallback(() => {
    if (isRendering) {
      return
    }

    if (isArchiving) {
      return
    }

    onClose()
  }, [onClose, isRendering, isArchiving])

  const toggleIncludeOnlySelectedRange = React.useCallback(() => {
    setIncludeOnlySelectedRange((v) => !v)
  }, [])

  React.useEffect(() => {
    if (shouldStartRender) {
      startRender()
    }
  }, [shouldStartRender])

  const warningMessages: React.ReactNode[] = (() => {
    if (isRendering) {
      return [
        <span key="1">
          Please keep Aninix in focus. Background rendering is not supported
          yet.
        </span>,
      ]
    }

    if (exportPresets.length === 0) {
      return []
    }

    let messages: Record<string, React.ReactNode> = {}

    const containsVideo = R.any(
      (preset: ExportPreset) =>
        [RenderType.mp4, RenderType.webm, RenderType.ogv].includes(
          preset.getComponentOrThrow(RenderTypeComponent).value
        ),
      exportPresets
    )
    const containsWebm = R.any(
      (preset: ExportPreset) =>
        preset.getComponentOrThrow(RenderTypeComponent).value ===
        RenderType.webm,
      exportPresets
    )
    const containsGif = R.any(
      (preset: ExportPreset) =>
        preset.getComponentOrThrow(RenderTypeComponent).value ===
        RenderType.gif,
      exportPresets
    )
    const containsLottie = R.any(
      (preset: ExportPreset) =>
        preset.getComponentOrThrow(RenderTypeComponent).value ===
          RenderType.lottie ||
        preset.getComponentOrThrow(RenderTypeComponent).value ===
          RenderType.dotLottie,
      exportPresets
    )
    const containsTgs = R.any(
      (preset: ExportPreset) =>
        preset.getComponentOrThrow(RenderTypeComponent).value ===
        RenderType.tgs,
      exportPresets
    )
    const containsSvg = R.any(
      (preset: ExportPreset) =>
        preset.getComponentOrThrow(RenderTypeComponent).value ===
        RenderType.svg,
      exportPresets
    )

    const rootNodeSize = getSize(entry)
    // @TODO: maybe add enum switch
    const pixelRatio =
      exportPresets[activePresetIdx].getComponentOrThrow(
        RenderScaleComponent
      ).value
    const relativeSizeOfOutput =
      rootNodeSize.x *
      rootNodeSize.y *
      getDuration(root) *
      root.getComponentOrThrow(FpsComponent).value *
      pixelRatio

    if (
      containsGif &&
      root.getComponentOrThrow(FpsComponent).value > GIF_MAX_FPS
    ) {
      messages['gif-fps'] = (
        <span>
          We will limit the FPS to {GIF_MAX_FPS} frames per second. To ensure
          the best possible quality.
          <br />
          If you need a higher FPS, please export to a different format.
        </span>
      )
    }

    if (containsGif && OPTIMAL_SIZE_OF_GIF < relativeSizeOfOutput) {
      messages['gif-big-size'] = (
        <span>
          Playback of the exported GIF can be slow.
          <br />
          GIFs play best at a maximum resolution of 1600x1200 and a duration of
          10 seconds.
        </span>
      )
    }

    if (containsLottie) {
      const features = useCases.getListOfNotSupportedLottieFeatures(project)

      if (features.length > 0) {
        messages['lottie-features'] = (
          <span>
            Some of the features in your designs may not be supported by lottie
            format:
            <ul className="mt-1">
              {features.map((f) => (
                <li className="list-inside list-disc" key={f}>
                  {f}
                </li>
              ))}
            </ul>
          </span>
        )
      }

      // project.forEachNode((node: Node) => {
      //   // @TODO: add warning message about skew animation by 2 axis.
      //   // Uncomment once we have article on website.
      //   if (node.skew.hasAnimation) {
      //     let hasAnimatedBothAxis = false
      //     const keyframes = node.skew.keyframes
      //     for (let i = 0; i < keyframes.length; i += 1) {
      //       const keyframe = keyframes[i]
      //       const nextKeyframe = keyframes[i + 1]
      //       if (
      //         keyframe.value.x !== nextKeyframe.value.x &&
      //         keyframe.value.y !== nextKeyframe.value.y
      //       ) {
      //         hasAnimatedBothAxis = true
      //         break
      //       }
      //     }
      //     if (hasAnimatedBothAxis) {
      //       messages['skew'] = (
      //         <span>
      //           Lottie doesn't properly support animation of both axis in Skew.
      //           Check the full list of{' '}
      //           <a
      //             href="https://www.aninix.com/wiki/supported-figma-features"
      //             target="_blank"
      //           >
      //             supported features
      //           </a>
      //           .
      //         </span>
      //       )
      //     }
      //   }
      // })
    }

    if (containsTgs) {
      const features = useCases.getListOfNotSupportedTgsFeatures(project)
      const size = getSize(entry)
      const duration = getDuration(entry)

      if (size.x !== 512 || size.y !== 512) {
        messages['tgs-size'] = (
          <span>Size of tgs should be equal to 512x512 pixels</span>
        )
      }

      if (duration > 3) {
        messages['tgs-duration'] = (
          <span>Duration of tgs should be less than 3 seconds</span>
        )
      }

      if (features.length > 0) {
        messages['tgs-features'] = (
          <span>
            Some of the features in your designs may not be supported by tgs
            format:
            <ul className="mt-1">
              {features.map((f) => (
                <li className="list-inside list-disc" key={f}>
                  {f}
                </li>
              ))}
            </ul>
          </span>
        )
      }
    }

    if (containsSvg) {
      const entitiesWithTrimPathAndDashes = project.getEntitiesByPredicate(
        (e) => {
          const trimStart = e.getComponent(TrimStartComponent)
          const trimEnd = e.getComponent(TrimEndComponent)
          const hasTrimStartAnimation =
            trimStart != null ? getSortedKeyframes(trimStart).length > 0 : false
          const hasTrimEndAnimation =
            trimEnd != null ? getSortedKeyframes(trimEnd).length > 0 : false
          return (
            (hasTrimStartAnimation || hasTrimEndAnimation) &&
            (e.hasComponent(DashComponent) || e.hasComponent(GapComponent))
          )
        }
      )

      if (entitiesWithTrimPathAndDashes.length > 0) {
        messages['svg-test'] = (
          <span>
            Trim path and Dash do not work together in the animated svg. Please
            keep only one property.
            <br />
            <br />
            Affected layer:{' '}
            <strong>
              {entitiesWithTrimPathAndDashes
                .map((e) => e.getComponentOrThrow(NameComponent).value)
                .join(', ')}
            </strong>
          </span>
        )
      }
    }

    if (containsVideo && OPTIMAL_SIZE_OF_VIDEO < relativeSizeOfOutput) {
      messages['big-video-size'] = (
        <span>
          Export might be slow.
          <br />
          You can reduce project duration, size or FPS to speed up process.
        </span>
      )
    }

    if (containsWebm) {
      const notChromium =
        ['electron', 'chrome'].includes(config.getEngine().toLowerCase()) ===
        false

      if (notChromium) {
        messages['webm-may-be-working-not-correctly'] = (
          <span>.webm format can work not properly in your browsers.</span>
        )
      }
    }

    return R.values(messages)
  })()

  return {
    anchorEl,
    onClose: handleClose,

    exportPresets: exportPresets.map((exportPreset) => ({
      id: exportPreset.id,
      type: exportPreset.getComponentOrThrow(RenderTypeComponent).value,
      scale: exportPreset.getComponentOrThrow(RenderScaleComponent).value,
      suffix: exportPreset.getComponentOrThrow(RenderSuffixComponent).value,
    })),

    activePresetIdx,
    createPreset,
    updatePreset,
    deletePreset,

    startRender: startRenderByUser,
    cancelRender,
    renderProgress: render.progress,
    isRendering,

    isArchiving,

    isRenderFinished,
    // @NOTE: close modal when it's on success state instead of run it again
    startOver: () => {
      handleClose()
      // setIsRenderFinished(false)
    },

    errorMessage,
    warningMessages,
    // statusMessage,
    statusMessage: isRendering
      ? formatRemainingTime(render.remainingTimeInSeconds)
      : undefined,

    isViewVisible: true,

    includeOnlySelectedRangeAllowed:
      previewRangeStart !== getStartTime(root) ||
      previewRangeEnd !== getEndTime(root),
    includeOnlySelectedRange,
    toggleIncludeOnlySelectedRange,
  }
}
