import {
  Component,
  ScaleLockedComponent,
  SizeLockedComponent,
  commitUndo,
  mixed,
} from '@aninix-inc/model'
import { InputWithIcon, buttons, icons } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useValueLockablePoint2d } from '../../../../../hooks/value-controllers/lockable-point-2d'

// @TODO: provide generic types
export interface IProps {
  components: Component[]
  lockableComponents: (ScaleLockedComponent | SizeLockedComponent)[]
  time?: number
  iconX?: React.ReactNode
  iconY?: React.ReactNode
  formatValue?: (value: number) => string
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  minX?: number
  maxX?: number
  minY?: number
  maxY?: number
}
export const LockablePoint2dValue: React.FCC<IProps> = observer(
  ({
    components,
    lockableComponents,
    time,
    iconX,
    iconY,
    formatValue,
    before = R.identity,
    after = R.identity,
    minX,
    maxX,
    minY,
    maxY,
  }) => {
    const {
      x,
      y,
      lockType,
      updateX,
      updateXByDelta,
      updateY,
      updateYByDelta,
      toggleLock,
    } = useValueLockablePoint2d({
      components,
      lockableComponents,
      time,
    })

    const id = components.map((c) => c.id).join('-')
    const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <InputWithIcon
            value={x === mixed ? x : before(x)}
            id={id}
            icon={iconX}
            onChange={(newValue) => updateX(after(newValue))}
            onDeltaChange={(newDelta) => updateXByDelta(after(newDelta))}
            onEndChange={endChange}
            format={formatValue}
            min={minX}
            max={maxX}
          />
          <InputWithIcon
            value={y === mixed ? y : before(y)}
            id={id}
            icon={iconY}
            onChange={(newValue) => updateY(after(newValue))}
            onDeltaChange={(newDelta) => updateYByDelta(after(newDelta))}
            onEndChange={endChange}
            format={formatValue}
            min={minY}
            max={maxY}
          />
          <buttons.Icon onClick={toggleLock}>
            {/* @ts-ignore */}
            <icons.Link type={lockType} />
          </buttons.Icon>
        </div>
      </div>
    )
  }
)
