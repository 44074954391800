import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useMouseMove } from '@aninix/app-design-system'
import { usePlayback, useTimeline } from '@aninix/core'
import classNames from 'classnames'
import * as timeConverters from '../../../../helpers/timeConverters'
import * as styles from './index.scss'

export interface IProps {
  projectDuration: number
  parentTrackWidth: number
}
export const VisibleRange: React.FCC<IProps> = observer(
  ({ parentTrackWidth, projectDuration }) => {
    const timeline = useTimeline()
    const startTime = timeline.visibleRangeStartTime
    const duration = timeline.visibleRangeDuration
    const playback = usePlayback()

    const updateDuration = React.useCallback(
      (duration: number) => {
        timeline.updateVisibleRangeDuration(duration)
      },
      [timeline]
    )

    const updateStartTime = React.useCallback(
      (time: number) => {
        const newTime = (() => {
          if (duration - time > projectDuration) {
            return startTime
          }

          if (time < playback.previewRange.start) {
            return playback.previewRange.start
          }

          return time
        })()

        timeline.updateVisibleRangeStartTime(newTime)
      },
      [projectDuration, playback, startTime, duration, timeline]
    )

    const reset = React.useCallback(() => {
      timeline.reset()
    }, [timeline])

    const minZoomThreshold = timeline.threshold
    const initialStartTime = React.useRef<number>(startTime)
    const initialDuration = React.useRef<number>(duration)

    const [isLeftMoving, setIsLeftMoving] = React.useState(false)
    const [isTrackMoving, setIsTrackMoving] = React.useState(false)
    const [isRightMoving, setIsRightMoving] = React.useState(false)
    // @NOTE: required to properly handle track events
    const isListeningRef = React.useRef(false)
    const {
      offsetX: distance,
      isListening,
      startListen,
    } = useMouseMove({
      onTrigger: () => {
        isListeningRef.current = true
      },
      onFinish: () => {
        isListeningRef.current = false
      },
    })

    // TODO: refactor
    const handlerWidth = 6

    const convertTimeToPixels = React.useCallback(
      (time: number) =>
        timeConverters.convertTimeToPixels({
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
          time,
        }),
      [projectDuration, parentTrackWidth]
    )

    const convertPixelsToTime = React.useCallback(
      (pixels: number) =>
        timeConverters.convertPixelsToTime({
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
          pixels,
        }),
      [projectDuration, parentTrackWidth]
    )

    const handleLeftMove = React.useCallback((offset: number) => {
      const isStart = initialStartTime.current + offset <= 0
      const isEnd = offset + minZoomThreshold >= initialDuration.current

      if (isStart) {
        updateStartTime(0)
        updateDuration(initialDuration.current + initialStartTime.current)
        return
      }

      if (isEnd) {
        return
      }

      updateStartTime(initialStartTime.current + offset)
      updateDuration(initialDuration.current - offset)
    }, [])

    const handleTrackMove = React.useCallback(
      (offset: number) => {
        const isStart = initialStartTime.current + offset <= 0
        const isEnd =
          initialStartTime.current + initialDuration.current + offset >=
          projectDuration

        if (isStart) {
          updateStartTime(0)
          return
        }

        if (isEnd) {
          updateStartTime(projectDuration - initialDuration.current)
          return
        }

        updateStartTime(initialStartTime.current + offset)
      },
      [projectDuration]
    )

    const handleRightMove = React.useCallback(
      (offset: number) => {
        const isEnd =
          initialStartTime.current + initialDuration.current + offset >=
          projectDuration

        if (isEnd) {
          updateDuration(projectDuration - initialStartTime.current)
          return
        }

        updateDuration(initialDuration.current + offset)
      },
      [projectDuration]
    )

    React.useEffect(() => {
      if (isListeningRef.current === false) {
        return
      }

      if (isLeftMoving) {
        handleLeftMove(convertPixelsToTime(distance))
      }

      if (isTrackMoving) {
        handleTrackMove(convertPixelsToTime(distance))
      }

      if (isRightMoving) {
        handleRightMove(convertPixelsToTime(distance))
      }
    }, [distance, isLeftMoving, isTrackMoving, isRightMoving])

    React.useEffect(() => {
      if (isListening === false) {
        setIsLeftMoving(false)
        setIsTrackMoving(false)
        setIsRightMoving(false)

        initialStartTime.current = startTime
        initialDuration.current = duration
      }
    }, [isListening, startTime, duration])

    const onReset = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.stopPropagation()
        e.preventDefault()
        reset()
      },
      []
    )

    const onMouseDownLeft = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isLeftMouseButtonClicked = e.button === 0
        if (isLeftMouseButtonClicked === false) {
          return
        }

        e.stopPropagation()
        e.preventDefault()
        setIsLeftMoving(true)
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const onMouseDownMiddle = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isLeftMouseButtonClicked = e.button === 0
        if (isLeftMouseButtonClicked === false) {
          return
        }

        e.stopPropagation()
        e.preventDefault()
        setIsTrackMoving(true)
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const onMouseDownRight = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isLeftMouseButtonClicked = e.button === 0
        if (isLeftMouseButtonClicked === false) {
          return
        }

        e.stopPropagation()
        e.preventDefault()
        setIsRightMoving(true)
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const left = React.useMemo(
      () => convertTimeToPixels(startTime),
      [convertTimeToPixels, startTime]
    )

    const width = React.useMemo(
      () => convertTimeToPixels(duration),
      [convertTimeToPixels, duration]
    )

    return (
      <div className={styles.container} style={{ left }}>
        <button
          type="button"
          // @NOTE: required to calculate selection in tracks
          data-model-type="visible-range"
          className={classNames(styles.handler, styles['handler-left'])}
          onMouseDown={onMouseDownLeft}
        >
          <span />
        </button>

        <button
          type="button"
          // @NOTE: required to calculate selection in tracks
          data-model-type="visible-range"
          className={styles.track}
          style={{ width }}
          onMouseDown={onMouseDownMiddle}
          onDoubleClick={onReset}
        >
          <span />
        </button>

        <button
          type="button"
          // @NOTE: required to calculate selection in tracks
          data-model-type="visible-range"
          className={classNames(styles.handler, styles['handler-right'])}
          onMouseDown={onMouseDownRight}
        >
          <span />
        </button>
      </div>
    )
  }
)
