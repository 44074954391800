// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VRx0LBGwMIDi6Y598yTQ{display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;margin:0;margin-right:8px;padding:0;background:none;border:none;outline:none;cursor:default;font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em}.VRx0LBGwMIDi6Y598yTQ:last-of-type{margin-right:0}.VRx0LBGwMIDi6Y598yTQ:hover .DJGrycC3fhauQncO2hbC{border-color:var(--figma-color-bg-brand)}.DJGrycC3fhauQncO2hbC{position:relative;width:48px;height:32px;overflow:hidden;border:1px solid rgba(255,255,255,.3);border-radius:8px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/animation-quick-presets/view-preset.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,0BAAA,CACA,QAAA,CACA,gBAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CCFA,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDCA,mCACE,cAAA,CAIA,kDACE,wCGnBc,CHwBpB,sBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,qCAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
export var wrapper = `VRx0LBGwMIDi6Y598yTQ`;
export var container = `DJGrycC3fhauQncO2hbC`;
export default ___CSS_LOADER_EXPORT___;
