import { getSelection, usePlayback, useSystem } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  EntityType,
  EntryComponent,
  Project,
  SelectionSystem,
  TargetRelationAspect,
  TimeComponent,
  isSegment as isSegmentV2,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { PresetsStoreProvider, useUi } from '../../stores'
import { AnimationPresets } from '../animation-presets'
import {
  EntryProperties,
  NodeProperties,
  ProjectProperties,
  SegmentProperties,
} from './components'
import { KeyframeProperties } from './components/keyframes'
import { Header } from './header'
import * as styles from './index.scss'
import { ViewTabs } from './view-tabs'

export interface IProps {
  project: Project
  time: number
  isPlaying: boolean
  tabs: string[]
  activeTab: string
  onChangeActiveTab: (tab: string) => void
}
const PropertiesPanelActual: React.FCC<IProps> = React.memo(
  ({ project, time, tabs, activeTab, onChangeActiveTab }) => {
    const analytics = useAnalytics()
    const selection = project.getSystemOrThrow(SelectionSystem)
    useSystem(selection)

    const selectedLayers = getSelection(project, EntityType.Node)
    const entryLayers = selectedLayers.filter((layer) =>
      layer.hasComponent(EntryComponent)
    )
    const selectedKeyframes = getSelection(project, EntityType.Keyframe)
    const selectedSegments = (() => {
      const sortedKeyframes = R.sortBy(
        (e) => e.getComponentOrThrow(TimeComponent).value,
        selectedKeyframes
      )
      const groupedByTarget = R.groupBy(
        (keyframe) =>
          keyframe.getAspectOrThrow(TargetRelationAspect).getRelationOrThrow(),
        sortedKeyframes
      )

      let segments: [Entity, Entity][] = []
      for (const keyframesByProperty of R.values(groupedByTarget)) {
        for (let i = 0; i < keyframesByProperty!.length - 1; i += 1) {
          const keyframe = keyframesByProperty![i]
          const nextKeyframe = keyframesByProperty![i + 1]
          if (isSegmentV2(keyframe, nextKeyframe)) {
            segments.push([keyframe, nextKeyframe])
          }
        }
      }
      return segments
    })()

    return (
      <div className={styles.container}>
        {(getSelection(project, EntityType.Node) ||
          getSelection(project, EntityType.Keyframe)) && (
          <Header project={project} />
        )}

        {getSelection(project, EntityType.Node).length > 0 &&
          entryLayers.length === 0 && (
            <ViewTabs
              tabs={tabs}
              activeTab={activeTab}
              onUpdateTab={(newTab) => {
                if (newTab === 'Presets') {
                  analytics.track({
                    eventName: AnalyticsEvent.AnimationPresetsClicked,
                    properties: {
                      source: 'properties-panel',
                    },
                  })
                }

                // @TODO: provide correct type
                onChangeActiveTab(newTab as any)
              }}
            />
          )}

        {activeTab === 'Properties' ||
        selection.isEmpty() ||
        selectedLayers.find((layer) => layer.hasComponent(EntryComponent)) !=
          null ||
        selectedSegments.length > 0 ||
        selectedKeyframes.length > 0 ? (
          <>
            {selection.isEmpty() && <ProjectProperties project={project} />}
            {entryLayers.length > 0 && (
              <EntryProperties layers={entryLayers} time={time} />
            )}
            {entryLayers.length === 0 && selectedLayers.length > 0 && (
              <NodeProperties layers={selectedLayers} time={time} />
            )}
            {selectedSegments.length > 0 && (
              <SegmentProperties segments={selectedSegments} />
            )}
            {selectedKeyframes.length > 0 && selectedSegments.length === 0 && (
              <KeyframeProperties keyframes={selectedKeyframes} />
            )}
          </>
        ) : (
          <PresetsStoreProvider>
            <AnimationPresets project={project} />
          </PresetsStoreProvider>
        )}
      </div>
    )
  },
  (_, current) => current.isPlaying
)

export const PropertiesPanel: React.FC<{ project: Project }> = observer(
  ({ project }) => {
    const playback = usePlayback()
    const uiStore = useUi()
    return (
      <PropertiesPanelActual
        project={project}
        time={playback.time}
        isPlaying={playback.isPlaying}
        tabs={uiStore.propertiesPanelTabs}
        activeTab={uiStore.propertiesPanelActiveTab}
        // @ts-ignore
        onChangeActiveTab={uiStore.propertiesPanelUpdateActiveTab}
      />
    )
  }
)

PropertiesPanel.displayName = 'PropertiesPanel'
