import { Component, ConstructorWithTag, Entity } from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { useComponent } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useKeyframeIndicator } from '../../../../hooks/keyframe-indicator'

const btnSize = {
  width: 16,
  height: 16,
}

const iconSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  property: Component
  time: number
  KeyframeConstructor: ConstructorWithTag<Entity>
}
export const KeyframesPropertyControl: React.FCC<IProps> = observer(
  ({ property, time, KeyframeConstructor }) => {
    useComponent(property)
    const { type, color, toggleKeyframe } = useKeyframeIndicator({
      components: [property],
      time,
      KeyframeConstructor,
    })

    return (
      <buttons.Icon onClick={toggleKeyframe} btnSize={btnSize}>
        <icons.KeyIndicator
          type={type}
          size={iconSize}
          style={{
            // @ts-ignore
            '--figma-color-text': color,
          }}
        />
      </buttons.Icon>
    )
  }
)
