import {
  DurationComponent,
  EntityType,
  EntityTypeComponent,
  HashComponent,
  Project,
  Root,
  getEntryOrThrow,
  getSize,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/static-types'
import { ImagesStore } from '../stores'
import { generateStaticSvg } from './generate-svg'
import { svgToRawImage } from './svg-to-image'

const COVER_MIN_SIDE_TARGET_SIZE = 800
function getCoverSize(projectSize: Point2D): Point2D {
  const width = projectSize.x
  const height = projectSize.y

  if (width > height) {
    return {
      x: (width / height) * COVER_MIN_SIDE_TARGET_SIZE,
      y: COVER_MIN_SIDE_TARGET_SIZE,
    }
  }

  if (width < height) {
    return {
      x: COVER_MIN_SIDE_TARGET_SIZE,
      y: (height / width) * COVER_MIN_SIDE_TARGET_SIZE,
    }
  }

  return {
    x: COVER_MIN_SIDE_TARGET_SIZE,
    y: COVER_MIN_SIDE_TARGET_SIZE,
  }
}

export async function generateCover(payload: {
  project: Project
  images: ImagesStore
}): Promise<Uint8Array> {
  const { project, images } = payload
  const root = project.getEntityByTypeOrThrow(Root)
  const entry = getEntryOrThrow(project)
  await images
    .clean()
    .loadImagesByHashes(
      project
        .getEntitiesByPredicate(
          (e) =>
            e.getComponentOrThrow(EntityTypeComponent).value ===
              EntityType.Paint && e.hasComponent(HashComponent)
        )
        .map((paint) => paint.getComponentOrThrow(HashComponent).value)
    )

  // @NOTE: add cover
  const coverSvg = generateStaticSvg({
    project,
    images,
    // @TODO: maybe provide current frame from playback
    time: root.getComponentOrThrow(DurationComponent).value / 2,
  })

  const size = getSize(entry)
  const coverBlob = await svgToRawImage({
    svgNode: coverSvg,
    size: getCoverSize(size),
    format: 'image/webp',
    quality: 0.85,
  })

  return new Uint8Array(await coverBlob.arrayBuffer())
}
