import { Node } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'

import { Entity } from '@aninix-inc/model'
import { getNodesBranch, getNodesBranchV2 } from './get-nodes-branch'
import {
  getTransformMatrix,
  getTransformMatrixV2,
} from './get-transform-matrix'

export function getAbsoluteTransformMatrix(payload: {
  node: Node
  time: number
}) {
  const { node, time } = payload
  const branch = getNodesBranch(node)
  const transformMatrix: paper.Matrix = branch.reduce(
    (matrix, _node) => getTransformMatrix({ node: _node, matrix, time }),
    new paper.Matrix()
  )

  return transformMatrix
}

export function getAbsoluteTransformMatrixV2(payload: {
  entity: Entity
  time: number
}) {
  const { entity, time } = payload
  const branch = getNodesBranchV2(entity)
  const transformMatrix: paper.Matrix = branch.reduce(
    (matrix, e) => getTransformMatrixV2({ entity: e, matrix, time }),
    new paper.Matrix()
  )

  return transformMatrix
}
