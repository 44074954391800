import {
  ChildrenRelationsAspect,
  Ellipse,
  Entity,
  EntityType,
  EntryComponent,
  FillsRelationsAspect,
  Frame,
  InnerRadiusComponent,
  Line,
  NameComponent,
  ParentRelationAspect,
  Point2D,
  PointCountComponent,
  Polygon,
  PositionComponent,
  Project,
  Rectangle,
  RgbaValueComponent,
  Root,
  SelectionSystem,
  Star,
  StrokesRelationsAspect,
  VisibleInViewportComponent,
  commitUndo,
  getEntryOrThrow,
  getSize,
  round,
} from '@aninix-inc/model'
import { usePostTime } from '@aninix-inc/renderer'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import tinycolor from 'tinycolor2'
import featureFlags from '../../feature-flags'
import { nodeColors } from '../../registries'
import '../../setup'
import {
  KeyModificator,
  ShapeType,
  Tool,
  useImagesStore,
  usePlayback,
  useSession,
  useTools,
  useViewport,
} from '../../stores'
import { getSelection, useReloadOnAnyUpdate } from '../../updates'
import { expandBranch, selectLayers } from '../../use-cases/layer-selection'
import { GradientTransform } from '../common/renderers/gradient-transform'
import { SmartSvgWrapper } from '../common/renderers/smart-svg-wrapper'
import { SvgDistances } from '../common/renderers/svg-distances'
import { SvgHighlighter } from '../common/renderers/svg-highlighter'
import { SvgLayerDataProvider } from '../common/renderers/svg-layer-data-provider'
import { SvgMotionPath } from '../common/renderers/svg-motion-path'
import { SvgTransform } from '../common/renderers/svg-transform'
import {
  BoundingBox,
  SvgTransformer,
} from '../common/renderers/svg-transformer'
import { SvgVertices } from '../common/renderers/svg-vertices'
import { WebglRender } from '../webgl-render'
import * as styles from './index.scss'

const FRAME_NAME_LENGTH = 40

const WEBGL_RENDER_CONFIG = {
  renderBackdropBlur: true,
  blurQuality: 12,
  renderBlur: true,
  renderInnerShadow: true,
  renderShadow: true,
  containerCaching: false,
  shapeCaching: false,
}

function trimTitle(title: string): string {
  if (title.length > FRAME_NAME_LENGTH) {
    return `${R.take(FRAME_NAME_LENGTH, title)}...`
  }

  return title
}

export interface IProps {
  project: Project
  propertyMenu?: React.ReactNode
  editable?: boolean
}
export const Viewport: React.FCC<IProps> = observer(
  ({ project, propertyMenu = null, editable = true }) => {
    useReloadOnAnyUpdate(project)
    const stageRef = React.useRef<SVGSVGElement>(null)
    const session = useSession()
    const imagesStore = useImagesStore()
    const playback = usePlayback()
    const viewport = useViewport()
    const tools = useTools()
    const selection = project.getSystemOrThrow(SelectionSystem)
    const rootNode = project.getEntityByTypeOrThrow(Root)
    const entryNode = project.entities.find((entity) =>
      entity.hasComponent(EntryComponent)
    )!

    const [transformerBoundingBox, setTransformerBoundingBox] =
      React.useState<BoundingBox>({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      })

    const cursorPositionRef = React.useRef<Point2D>({ x: 0, y: 0 })

    const isViewportMovingRef = React.useRef(false)

    const [selectionRectStartPoint, setSelectionRectStartPoint] =
      React.useState<Point2D>({ x: 0, y: 0 })
    const [selectionRectEndPoint, setSelectionRectEndPoint] =
      React.useState<Point2D>({ x: 0, y: 0 })
    const [isSelectionRectVisible, setIsSelectionRectVisible] =
      React.useState(false)

    const wrapperRef = React.useRef<any>(null)

    const selectionRectBoundingRect = React.useMemo(
      () => ({
        x: Math.min(selectionRectStartPoint.x, selectionRectEndPoint.x),
        y: Math.min(selectionRectStartPoint.y, selectionRectEndPoint.y),
        width: Math.abs(selectionRectEndPoint.x - selectionRectStartPoint.x),
        height: Math.abs(selectionRectEndPoint.y - selectionRectStartPoint.y),
      }),
      [selectionRectStartPoint, selectionRectEndPoint]
    )

    // @NOTE: required to keep size of viewport in sync
    React.useEffect(() => {
      const wrapperRefResizeObserver = new ResizeObserver((entries) => {
        const parent = entries[0].target as HTMLDivElement
        viewport.updateSize({
          x: parent.offsetWidth,
          y: parent.offsetHeight,
        })
      })

      wrapperRefResizeObserver.observe(wrapperRef.current)

      const width = wrapperRef.current.offsetWidth
      const height = wrapperRef.current.offsetHeight

      if (width !== 0 && height !== 0) {
        viewport.updateSize({
          x: wrapperRef.current.offsetWidth,
          y: wrapperRef.current.offsetHeight,
        })
      }

      return () => {
        wrapperRefResizeObserver.disconnect()
      }
    }, [])

    const handleWheel = React.useCallback((e: WheelEvent) => {
      e.stopPropagation()
      e.preventDefault()

      viewport.borrow()

      const dx = e.deltaX
      const dy = e.deltaY

      const zoomPoint: Point2D = cursorPositionRef.current
      const clampedDy = R.clamp(-50, 50, dy)
      const zoomStep = 1 - clampedDy * 0.01

      // @NOTE: hack taken from here https://kenneth.io/post/detecting-multi-touch-trackpad-gestures-in-javascript
      if (e.metaKey || e.ctrlKey) {
        viewport.zoomToPoint({
          point: zoomPoint,
          zoomStep,
        })
        return
      }

      viewport.updatePosition({
        x: viewport.position.x - dx,
        y: viewport.position.y - dy,
      })
    }, [])

    // @NOTE: required to do in this way because react does not allow to use wheel event with passive: false,
    // which required to prevent default behaviour.
    React.useEffect(() => {
      stageRef.current?.addEventListener('wheel', handleWheel, {
        passive: false,
      })

      return () => {
        stageRef.current?.removeEventListener('wheel', handleWheel)
      }
    }, [handleWheel])

    const insertEntity = React.useCallback(
      (entity: Entity) => {
        const selectedEntity = selection
          .getEntitiesByEntityType(EntityType.Node)
          .at(-1)
        const selectedParent = selectedEntity
          ?.getAspect(ParentRelationAspect)
          ?.getParentEntity()

        if (selectedEntity && selectedParent) {
          const selectedIndex = selectedParent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getIndexOfById(selectedEntity.id)
          selectedParent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .addChild(entity, selectedIndex)
        } else {
          entryNode.getAspectOrThrow(ChildrenRelationsAspect).addChild(entity)
        }

        selection.deselectAll()
        selection.select([entity.id])
        tools.changeTool(Tool.Selection)
      },
      [selection, entryNode]
    )

    const createShape = React.useCallback(() => {
      const type = tools.activeShape
      const Shape = shapesMap[type]

      const shapesCount = project.getEntitiesByType(Shape).length
      const shapeName = `${type.slice(0, 1).toUpperCase()}${type.slice(1)} ${shapesCount + 1}`
      const shapeEntity = project.createEntity(Shape)

      const x = cursorPositionRef.current.x
      const y = cursorPositionRef.current.y

      shapeEntity.updateComponent(PositionComponent, (pos) => ({
        ...pos,
        x,
        y,
      }))
      shapeEntity.updateComponent(NameComponent, shapeName)

      if (type !== ShapeType.Line) {
        shapeEntity.getAspectOrThrow(StrokesRelationsAspect).clear()
        const rgbaEntity = shapeEntity
          .getAspectOrThrow(FillsRelationsAspect)
          .getChildAtOrThrow(0)
        rgbaEntity.updateComponent(RgbaValueComponent, {
          r: 217,
          b: 217,
          g: 217,
          a: 1,
        })
      }

      if (type === ShapeType.Line) {
        shapeEntity.getAspectOrThrow(FillsRelationsAspect).clear()
      }

      if (type === ShapeType.Star) {
        shapeEntity.updateComponent(InnerRadiusComponent, 0.382)
      }

      if (type === ShapeType.Polygon) {
        shapeEntity.updateComponent(PointCountComponent, 3)
      }

      const size = getSize(shapeEntity)
      shapeEntity.updateComponent(PositionComponent, (position) => ({
        ...position,
        x: x - size.x / 2,
        y: y - size.y / 2,
      }))

      insertEntity(shapeEntity)
    }, [tools, insertEntity])

    const createFrame = React.useCallback(() => {
      const x = cursorPositionRef.current.x
      const y = cursorPositionRef.current.y
      const framesCount = project.getEntitiesByType(Frame).length
      const frameName = `Frame ${framesCount + 1}`
      const frameEntity = project.createEntity(Frame)
      const size = getSize(frameEntity)
      frameEntity.updateComponent(PositionComponent, (pos) => ({
        ...pos,
        x: x - size.x / 2,
        y: y - size.y / 2,
      }))

      frameEntity.updateComponent(NameComponent, frameName)
      frameEntity
        .getAspectOrThrow(FillsRelationsAspect)
        .getChildAtOrThrow(0)
        .updateComponent(RgbaValueComponent, { r: 255, g: 255, b: 255, a: 1 })

      insertEntity(frameEntity)
    }, [selection, entryNode])

    const handleTouchStart = React.useCallback(
      (
        e:
          | React.MouseEvent<SVGSVGElement, MouseEvent>
          | React.TouchEvent<SVGSVGElement>
      ) => {
        const layer = project.getEntity(session.buffer)

        // @ts-ignore
        const isTransformer = e.target.id === 'transformer'

        if (isTransformer) {
          return
        }

        if (tools.activeTool === Tool.Hand) {
          isViewportMovingRef.current = true
          return
        }

        if (tools.activeTool !== Tool.Selection) {
          return
        }

        const pointerPoint = cursorPositionRef.current

        if (!session.buffer) {
          setIsSelectionRectVisible(true)
          setSelectionRectStartPoint(pointerPoint)
          setSelectionRectEndPoint(pointerPoint)
          selection.deselectAll()
        } else {
          if (!session.keyModificators.includes(KeyModificator.Shift)) {
            selection.replace([session.buffer])
            expandBranch(layer!)
            commitUndo(project)
            session.cleanBuffer()
            return
          }

          selection.select([session.buffer])
          expandBranch(layer!)
          commitUndo(project)
          session.cleanBuffer()
        }
      },
      [session, project, tools]
    )

    const handleTouchMove = React.useCallback(
      (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        if (isViewportMovingRef.current) {
          viewport.updatePosition({
            x: viewport.position.x + e.movementX,
            y: viewport.position.y + e.movementY,
          })
          return
        }

        // @NOTE: required to update cursor position
        const svg = stageRef.current!.getBoundingClientRect()
        const defaultOffset = {
          x: svg.left,
          y: svg.top,
        }
        cursorPositionRef.current = {
          x:
            (e.clientX - defaultOffset.x - viewport.position.x) / viewport.zoom,
          y:
            (e.clientY - defaultOffset.y - viewport.position.y) / viewport.zoom,
        }

        if (tools.activeTool !== Tool.Selection) {
          return
        }

        setSelectionRectEndPoint(cursorPositionRef.current)

        selectLayers({
          node: entryNode,
          cursorPositionRef,
          images: imagesStore,
          session,
          playback,
          selectionRectBoundingRect,
          isSelectionRectVisible,
        })
      },
      [
        selectionRectStartPoint,
        selectionRectBoundingRect,
        isSelectionRectVisible,
      ]
    )

    const handleTouchEnd = React.useCallback(
      (
        e:
          | React.MouseEvent<SVGSVGElement, MouseEvent>
          | React.TouchEvent<SVGSVGElement>
      ) => {
        if (tools.activeTool === Tool.Hand) {
          isViewportMovingRef.current = false
        }

        if (tools.activeTool === Tool.Shape) {
          createShape()
          return
        }

        if (tools.activeTool === Tool.Frame) {
          createFrame()
          return
        }

        if (tools.activeTool !== Tool.Selection) {
          return
        }

        setIsSelectionRectVisible(false)
      },
      [isSelectionRectVisible]
    )

    const handleDblClick = React.useCallback(
      (
        e:
          | React.MouseEvent<SVGSVGElement, MouseEvent>
          | React.TouchEvent<SVGSVGElement>
      ) => {
        if (editable === false) {
          return
        }

        if (tools.activeTool !== Tool.Selection) {
          return
        }

        const selectedNode = R.head(getSelection(project, EntityType.Node))

        if (selectedNode == null) {
          return
        }

        if (
          !selectedNode.getAspect(ChildrenRelationsAspect)?.getChildrenList()
            .length
        ) {
          return
        }

        selectLayers({
          node: selectedNode,
          cursorPositionRef,
          images: imagesStore,
          session,
          playback,
          selectionRectBoundingRect,
          isSelectionRectVisible,
        })

        if (!!session.buffer) {
          selection.replace([session.buffer])
          session.cleanBuffer()
        }
      },
      [editable]
    )

    const isDraggable = tools.activeTool === Tool.Hand

    const handleRootNodeTitleClick = React.useCallback(
      (e: React.MouseEvent<SVGTextElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        selection.select([entryNode.id])
        commitUndo(project)
      },
      [selection, project, entryNode]
    )

    const propertyMenuCoords = React.useMemo<Point2D>(() => {
      return {
        x: transformerBoundingBox.x + transformerBoundingBox.width / 2,
        y: transformerBoundingBox.y,
      }
    }, [transformerBoundingBox])

    const viewBox = (() => {
      const x = -viewport.position.x / viewport.zoom
      const y = -viewport.position.y / viewport.zoom
      const width = viewport.size.x / viewport.zoom
      const height = viewport.size.y / viewport.zoom
      return [x, y, width, height]
        .map((number) => round(number, { fixed: 2 }))
        .join(' ')
    })()

    const [updateId, setUpdateId] = React.useState(0)

    usePostTime(playback.time)

    //use this to force get new snapshot for WebGL render
    React.useEffect(() => {
      setUpdateId(Math.random())
    }, [transformerBoundingBox])

    React.useEffect(() => {
      if (isSelectionRectVisible) {
        selection.deselectAll()
      }

      selectLayers({
        node: entryNode,
        cursorPositionRef,
        images: imagesStore,
        session,
        playback,
        selectionRectBoundingRect,
        isSelectionRectVisible,
      })
    }, [session.keyModificators.length])

    return (
      <div
        ref={wrapperRef}
        className={styles['viewport-wrapper']}
        id="canvas-wrapper"
      >
        {/* @NOTE: content */}

        {featureFlags.pixiRenderer === false ? (
          <svg
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
            className={styles.canvas}
            style={{
              pointerEvents: 'none',
              backgroundColor: rootNode.getComponentOrThrow(
                VisibleInViewportComponent
              ).value
                ? tinycolor(
                    rootNode.getComponentOrThrow(RgbaValueComponent).value
                  ).toRgbString()
                : 'transparent',
            }}
          >
            {featureFlags.smartSvg ? (
              <SmartSvgWrapper
                imagesStore={imagesStore}
                project={project}
                time={playback.time}
              />
            ) : (
              <SvgLayerDataProvider
                entity={entryNode}
                time={playback.time}
                imagesStore={imagesStore}
              />
            )}
          </svg>
        ) : (
          <WebglRender
            updateId={updateId}
            cacheRootOnPause={false}
            renderConfig={WEBGL_RENDER_CONFIG}
          />
        )}

        {/* @NOTE: controllers */}
        <svg
          id="stage"
          ref={stageRef}
          viewBox={viewBox}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          onMouseDown={handleTouchStart}
          // @TODO: enable touches
          // onTouchStart={handleTouchStart}
          onMouseMove={handleTouchMove}
          // onTouchMove={handleTouchMove}
          onMouseUp={handleTouchEnd}
          onDoubleClick={handleDblClick}
          className={classnames(
            styles.canvas,
            {
              [styles['canvas--hand-tool-selected']]: isDraggable,
              [styles['canvas--toggle-tangents']]:
                tools.activeTool === Tool.ToggleTangents,
              [styles['canvas--comments-tool-selected']]:
                tools.activeTool === Tool.Comments,
              [styles['canvas--shape-tool-selected']]:
                tools.activeTool === Tool.Shape,
              [styles['canvas--frame-tool-selected']]:
                tools.activeTool === Tool.Frame,
            },
          )}
        >
          <desc>
            We're glad you're so interested in our technologies! Contact us at
            info@aninix.com. We would like to discuss with you our software
            engineer position.
          </desc>

          <text
            x={0}
            y={-8 / viewport.zoom}
            fontSize={12 / viewport.zoom}
            fontFamily="Inter, sans-serif"
            fontWeight={400}
            style={{
              userSelect: 'none',
            }}
            onClick={handleRootNodeTitleClick}
            fill={
              selection.isSelected(getEntryOrThrow(project).id)
                ? styles.color_text_highlight
                : styles.color_text_regular
            }
          >
            {trimTitle(entryNode.getComponentOrThrow(NameComponent).value)}
          </text>

          {playback.isPlaying === false && (
            <>
              {isSelectionRectVisible && (
                <rect
                  x={selectionRectBoundingRect.x}
                  y={selectionRectBoundingRect.y}
                  width={selectionRectBoundingRect.width}
                  height={selectionRectBoundingRect.height}
                  fill={`${nodeColors.BLUE}35`}
                  stroke={nodeColors.BLUE}
                  strokeWidth={1 / viewport.zoom}
                />
              )}

              <SvgHighlighter project={project} />

              <g opacity={playback.isPlaying ? 0 : 1}>
                {featureFlags.transformSvg ? (
                  <SvgTransform />
                ) : (
                  <SvgTransformer project={project} />
                )}

                <SvgMotionPath project={project} />
                <SvgVertices
                  editable={editable}
                  selectionRectBoundingRect={
                    isSelectionRectVisible
                      ? selectionRectBoundingRect
                      : undefined
                  }
                />
                {featureFlags.gradientTransform && <GradientTransform />}
              </g>

              <SvgDistances project={project} />
            </>
          )}
        </svg>

        {propertyMenu && playback.isPlaying === false && editable && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1002,
              transform: `translate(${
                viewport.position.x + propertyMenuCoords.x * viewport.zoom
              }px, ${
                viewport.position.y + propertyMenuCoords.y * viewport.zoom
              }px)`,
            }}
          >
            {propertyMenu}
          </div>
        )}
      </div>
    )
  }
)

const shapesMap = {
  [ShapeType.Rectangle]: Rectangle,
  [ShapeType.Line]: Line,
  [ShapeType.Ellipse]: Ellipse,
  [ShapeType.Polygon]: Polygon,
  [ShapeType.Star]: Star,
} as const

Viewport.displayName = 'Viewport'
