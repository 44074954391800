import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { AninixUnavailable } from '../../components/status-pages'

interface IErrorBoundaryInternalProps {
  onError: (error: Error) => void
  fallback: React.ReactNode
  children: React.ReactNode
}
interface IState {
  hasError: boolean
}
class ErrorBoundaryInternal extends React.Component<
  IErrorBoundaryInternalProps,
  IState
> {
  constructor(props: IErrorBoundaryInternalProps) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch(error: Error) {
    this.props.onError(error)
  }

  public render() {
    return this.state.hasError ? this.props.fallback : this.props.children
  }
}

export interface IProps {}
export const ErrorBoundary: React.FCC<IProps> = observer(({ children }) => {
  return (
    // @ts-ignore
    <ErrorBoundaryInternal
      onError={console.error}
      fallback={
        <AninixUnavailable message="We could not connect to our servers. Please try to reload window in a few minutes." />
      }
    >
      {children}
    </ErrorBoundaryInternal>
  )
})
