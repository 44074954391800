import { Project, project as projectModel } from '@aninix-inc/model/legacy'
import { IJsonPatch, SnapshotIn, applyPatch } from 'mobx-state-tree'

import { config } from './config'
import { httpClient } from './http-client'

export async function sendPatches(payload: {
  projectId: string
  snapshotId: string
  patches: IJsonPatch[]
}): Promise<void> {
  return httpClient.post(
    `${config.apiUrl}/projects/${payload.projectId}/versions/${payload.snapshotId}/patches`,
    {
      snapshotId: payload.snapshotId,
      patches: payload.patches,
    }
  )
}

export async function listProjects(payload?: {
  teamId?: string
}): Promise<Array<{ id: string; name: string }>> {
  const { data } = await httpClient.get<
    Array<{
      id: string
      name: string
    }>
  >(`${config.apiUrl}/projects`, {
    params: {
      teamId: payload?.teamId,
    },
  })
  return data
}

export async function getProject(payload: { projectId: string }): Promise<{
  id: string
  snapshotId: string
  name: string
  data: SnapshotIn<Project>
  patches: { id: string; data: IJsonPatch; createdAt: string }[]
  createdAt: string
  editorIds: '*' | string[]
}> {
  const { data } = await httpClient.get<{
    id: string
    snapshotId: string
    name: string
    data: any
    patches: { id: string; data: IJsonPatch; createdAt: string }[]
    createdAt: string
    editorIds: '*' | string[]
  }>(`${config.apiUrl}/projects/${payload.projectId}`)

  return data
}

/**
 * Collaborative remote project in the cloud
 */
export class RemoteProject {
  constructor(private readonly projectId: string) {}

  unwrap = async (): Promise<{
    project: Project
    rawProject: Awaited<ReturnType<typeof getProject>>
    snapshotId: string
    editorIds: '*' | string[]
  }> => {
    const data = await getProject({ projectId: this.projectId })
    const project = projectModel.create(data.data)
    for (let patch of data.patches) {
      try {
        applyPatch(project, patch.data)
      } catch (err: unknown) {
        console.error('Cannot apply patch', patch, 'error:', err)
        // @TODO: add remote logger here
      }
    }
    return {
      project,
      rawProject: data,
      snapshotId: data.snapshotId,
      editorIds: data.editorIds,
    }
  }
}

export async function createProject(payload: {
  id: string
  project: Project
  teamId?: string
}) {
  await httpClient.post(`${config.apiUrl}/projects`, {
    id: payload.id,
    name: payload.project.name,
    data: payload.project.toJSON(),
    teamId: payload.teamId,
  })
}

export async function createProjectV2(payload: {
  id: string
  projectName: string
  teamId?: string
}) {
  await httpClient.post(`${config.apiUrl}/v2/projects`, {
    id: payload.id,
    name: payload.projectName,
    teamId: payload.teamId,
  })
}

export async function createProjectVersion(payload: {
  id: string
  project: Project
}) {
  await httpClient.post(`${config.apiUrl}/projects/${payload.id}/versions`, {
    data: payload.project.toJSON(),
  })
}

export async function deleteProject(
  payload: {
    projectId: string
  },
  options?: { forceDelete: boolean }
) {
  await httpClient.delete(`${config.apiUrl}/projects/${payload.projectId}`, {
    params: {
      forceDelete: options?.forceDelete,
    },
  })
}

export async function getAsset(payload: { hash: string }): Promise<Uint8Array> {
  const { data } = await httpClient.get<ArrayBuffer>(
    `${config.apiUrl}/assets/${payload.hash}`,
    {
      responseType: 'arraybuffer',
    }
  )

  return new Uint8Array(data)
}

export async function getCover(payload: {
  projectId: string
}): Promise<Uint8Array> {
  const { data } = await httpClient.get<ArrayBuffer>(
    `${config.apiUrl}/v2/projects/${payload.projectId}/covers/static`,
    {
      responseType: 'arraybuffer',
    }
  )

  return new Uint8Array(data)
}

/**
 * @todo replace with manual upload function
 */
export async function uploadCover(payload: {
  projectId: string
  hash: string
  image: Uint8Array
}): Promise<void> {
  const { projectId, hash, image } = payload
  const formData = new FormData()
  formData.append('hash', hash)
  formData.append('file', new Blob([image]), hash)
  await httpClient.post(
    `${config.apiUrl}/v2/projects/${projectId}/covers`,
    formData
  )
}
