import {
  DurationComponent,
  Entity,
  EntityType,
  EntityTypeComponent,
  FpsComponent,
  MatrixTransformValueComponent,
  NodeColorComponent,
  NumberValueComponent,
  OpacityComponent,
  Point2dValueComponent,
  RgbaValueComponent,
  Root,
  RotationComponent,
  ScaleComponent,
  SelectionSystem,
  SpatialPoint2dValueComponent,
  TargetRelationAspect,
  TimeComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  UpdatesSystem,
  ValueType,
  cleanupKeyframes,
  commitUndo,
  getKeyframeValueType,
  getNode,
  round,
  segmentsFromKeyframes,
} from '@aninix-inc/model'
import { Tooltip, useMouseMove } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { useClipboard } from '@aninix/clipboard'
import {
  KeyModificator,
  getSelection,
  useEntity,
  usePlayback,
  useSession,
  useSystem,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { defaults, hotkeysLabels } from '../../../../../defaults'
import * as timeConverters from '../../../../../helpers/timeConverters'
import { useUi } from '../../../../../stores'
import { useSegmentsReverseUseCase } from '../../../../../use-cases'
import { useFormatTime } from '../../../../properties-panel/components/formatted-time'

// TODO: refactor
const HANDLER_WIDTH = 6
const MIN_TIME_THRESHOLD_TO_SNAP = 0.05

/**
 * Round shortcut
 */
const r = (value: number): number => round(value, { fixed: 2 })

/**
 * Calculate time distance between 2 times
 */
const getTimeDistance = (left: number, right: number): number =>
  Math.abs(left - right)

function mapKeyframeValue(keyframe: Entity): string {
  const type = getKeyframeValueType(keyframe)
  const targetComponent = keyframe
    .getAspectOrThrow(TargetRelationAspect)
    .getTargetComponentOrThrow()

  switch (type) {
    case ValueType.Number: {
      const value = keyframe.getComponentOrThrow(NumberValueComponent).value

      if (
        [
          OpacityComponent.tag,
          TrimStartComponent.tag,
          TrimEndComponent.tag,
          TrimOffsetComponent.tag,
          // @ts-ignore
        ].includes(targetComponent.constructor.tag)
      ) {
        return `${r(value * 100)}%`
      }

      // @ts-ignore
      if (RotationComponent.tag == targetComponent.constructor.tag) {
        return `${value}°`
      }

      return `${value}`
    }

    case ValueType.Point2d: {
      const value = keyframe.getComponentOrThrow(Point2dValueComponent).value

      // @ts-ignore
      if (ScaleComponent.tag === targetComponent.constructor.tag) {
        return `${r(value.x * 100)}%, ${r(value.y * 100)}%`
      }

      return `${r(value.x)}, ${r(value.y)}`
    }

    case ValueType.SpatialPoint2d: {
      const value = keyframe.getComponentOrThrow(
        SpatialPoint2dValueComponent
      ).value
      return `${r(value.x)}px, ${r(value.y)}px`
    }

    case ValueType.Rgba: {
      const value = keyframe.getComponentOrThrow(RgbaValueComponent).value
      return `${r(value.r)}, ${r(value.g)}, ${r(value.b)}, ${r(value.a * 100)}%`
    }

    case ValueType.Matrix: {
      const value = keyframe.getComponentOrThrow(
        MatrixTransformValueComponent
      ).value
      return `[${value.join(', ')}]`
    }

    // @TODO: add all supported keyframes
    default: {
      const never: never = type
      throw new Error(`Not supported type "${never}"`)
    }
  }
}

export interface IProps {
  keyframe: Entity
  parentTrackWidth: number
}
export const Keyframe: React.FCC<IProps> = observer(
  ({ keyframe, parentTrackWidth }) => {
    useEntity(keyframe)
    const clipboard = useClipboard()
    const session = useSession()
    const playback = usePlayback()
    const uiStore = useUi()
    const segmentsReverseUseCase = useSegmentsReverseUseCase()
    const entity = getNode(keyframe)

    if (entity === undefined) {
      throw new Error('Invalid state. Node not found')
    }

    const project = entity.getProjectOrThrow()
    const selection = project.getSystemOrThrow(SelectionSystem)
    const updates = project.getSystemOrThrow(UpdatesSystem)
    useSystem(selection)
    const isSelected = selection.isSelected(keyframe.id)
    const root = project.getEntityByTypeOrThrow(Root)
    const projectDuration = root.getComponentOrThrow(DurationComponent).value
    const projectFps = root.getComponentOrThrow(FpsComponent).value

    const { toFormat } = useFormatTime()
    const keyframeTime = keyframe.getComponentOrThrow(TimeComponent).value

    const hasActiveKeyModificators =
      session.keyModificators.includes(KeyModificator.Ctrl) ||
      session.keyModificators.includes(KeyModificator.Shift)

    const select = React.useCallback(() => {
      if (selection.isSelected(keyframe.id) && hasActiveKeyModificators) {
        selection.deselect([keyframe.id])
        commitUndo(project)
        return
      }
      if (hasActiveKeyModificators) {
        selection.select([keyframe.id])
        commitUndo(project)
        return
      }
      selection.replace([keyframe.id])
      commitUndo(project)
    }, [keyframe, hasActiveKeyModificators, selection, project])

    /**
     * @todo refactor
     */
    const updateTime = React.useCallback(
      (time: number): void => {
        updates.batch(() => {
          const keyframes = getSelection(project, EntityType.Keyframe)
          const allKeyframesExceptCurrent = project.getEntitiesByPredicate(
            (e) =>
              e.getComponentOrThrow(EntityTypeComponent).value ===
                EntityType.Keyframe && e.id !== keyframe.id
          )

          const delta = (() => {
            // @ID: keyframe snapping
            // @TODO: move to common place
            if (session.keyModificators.includes(KeyModificator.Shift)) {
              // @TODO: include only visible keyframes
              const timesToSnap: number[] = allKeyframesExceptCurrent.map(
                (key) => key.getComponentOrThrow(TimeComponent).value
              )

              timesToSnap.push(playback.time)

              const ghostTime = playback.ghostTime
              if (ghostTime != null) {
                timesToSnap.push(ghostTime)
              }

              for (const timeToSnap of timesToSnap) {
                if (
                  getTimeDistance(timeToSnap, time) < MIN_TIME_THRESHOLD_TO_SNAP
                ) {
                  return timeToSnap - keyframeTime
                }
              }
            }

            return time - keyframeTime
          })()

          keyframes.forEach((currentKeyframe) => {
            currentKeyframe.updateComponent(TimeComponent, (v) => v + delta)
          })
        })
      },
      [entity, keyframe, projectFps, selection, toFormat, updates]
    )

    const reverse = React.useCallback((): void => {
      updates.batch(() => {
        const keyframes = getSelection(project, EntityType.Keyframe)
        const sortedByTime = R.sortBy(
          (key) => key.getComponentOrThrow(TimeComponent).value,
          keyframes
        )
        const segments = segmentsFromKeyframes(sortedByTime)
        segmentsReverseUseCase.execute({ segments })
      })
    }, [session, useSegmentsReverseUseCase])

    const onContextMenu = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const keyframes = getSelection(project, EntityType.Keyframe)

        if (keyframes.length === 0) {
          selection.replace([keyframe.id])
        }

        if (keyframes.length === 1) {
          uiStore.openContextMenu(
            [
              {
                title: 'Copy',
                onClick: () => {
                  clipboard.copyCurrentSelection()
                  uiStore.closeContextMenu()
                },
                rightPart: (
                  <p>
                    <HotkeyCombination keys={[hotkeysLabels().ctrl, 'C']} />
                  </p>
                ),
              },
            ],
            event
          )
          return
        }

        uiStore.openContextMenu(
          [
            {
              title: 'Copy',
              onClick: () => {
                clipboard.copyCurrentSelection()
                uiStore.closeContextMenu()
              },
              rightPart: (
                <p>
                  <HotkeyCombination keys={[hotkeysLabels().ctrl, 'C']} />
                </p>
              ),
            },
            'divider',
            {
              title: 'Reverse',
              onClick: () => {
                reverse()
                uiStore.closeContextMenu()
              },
            },
          ],
          event
        )
      },
      [keyframe]
    )

    const edit = React.useCallback(() => {
      document.getElementById(keyframe.id)?.focus()
    }, [])

    // const id = keyframe.getPath()
    const id = keyframe.id
    const endUndoGroup = React.useCallback(() => {
      cleanupKeyframes(project)
      commitUndo(project)
    }, [project])

    const node = getNode(entity)

    if (node === undefined) {
      throw new Error('Invalid state. Node not found')
    }

    const color =
      defaults.nodeColors[node.getComponentOrThrow(NodeColorComponent).value]
    // @TODO: provide proper value here
    const tooltipText = session.keyModificators.includes(KeyModificator.Alt)
      ? mapKeyframeValue(keyframe)
      : ''

    // @NOTE: required to handle the same interaction as in figma
    const wasTriggeredRef = React.useRef(false)
    const initialTime = React.useRef<number>(keyframeTime)
    const { offsetX, isListening, startListen, wasTriggered } = useMouseMove({
      threshold: 2,
      onTrigger: () => {
        wasTriggeredRef.current = true
      },
      onFinish: endUndoGroup,
    })

    const convertTimeToPixels = React.useCallback(
      (providedTime: number) =>
        timeConverters.convertTimeToPixels({
          time: providedTime,
          trackWidth: parentTrackWidth - HANDLER_WIDTH * 2,
          projectDuration,
        }),
      [parentTrackWidth, projectDuration]
    )

    const convertPixelsToTime = React.useCallback(
      (pixels: number) =>
        timeConverters.convertPixelsToTime({
          pixels,
          trackWidth: parentTrackWidth - HANDLER_WIDTH * 2,
          projectDuration,
        }),
      [parentTrackWidth, projectDuration]
    )

    React.useEffect(() => {
      if (isListening && wasTriggered) {
        updateTime(initialTime.current + convertPixelsToTime(offsetX))
      }
    }, [isListening, wasTriggered, offsetX])

    React.useEffect(() => {
      if (isListening === false) {
        initialTime.current = keyframeTime
      }
    }, [isListening, wasTriggered])

    const onMouseDown = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        if (isSelected === false) {
          return
        }

        e.preventDefault()
        e.stopPropagation()

        initialTime.current = keyframeTime
        // @ts-ignore
        startListen(e)
      },
      [startListen, isSelected, keyframeTime]
    )

    const onClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault()
        e.stopPropagation()

        if (wasTriggeredRef.current === false) {
          select()
        }

        wasTriggeredRef.current = false
      },
      [select]
    )

    const onDoubleClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault()
        e.stopPropagation()

        if (wasTriggeredRef.current === false) {
          edit()
        }

        wasTriggeredRef.current = false
      },
      [edit]
    )

    const left = convertTimeToPixels(keyframeTime) + HANDLER_WIDTH + 1

    return (
      <Tooltip
        title={tooltipText}
        arrow={false}
        placement="top"
        tooltipClassName="rounded-full bg-[#0B1118]"
        TransitionProps={{
          exit: false,
        }}
      >
        <button
          type="button"
          // @NOTE: required to calculate selection in tracks
          data-model-type="keyframe"
          // @NOTE: required to calculate selection in tracks
          data-id={id}
          style={{
            left,
          }}
          onMouseDown={onMouseDown}
          onClick={onClick}
          onContextMenu={onContextMenu}
          onDoubleClick={onDoubleClick}
          className="absolute top-1/2 h-[14px] w-[14px] -translate-x-[8px] -translate-y-1/2 cursor-default hover:opacity-80"
        >
          <span
            className="absolute left-1/2 top-1/2 block h-[calc(100%-3px)] w-[calc(100%-3px)] -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-sm"
            style={{
              backgroundColor: color,
              display: isSelected ? 'block' : 'none',
            }}
          />

          <span
            className="absolute left-1/2 top-1/2 block h-[calc(100%-6px)] w-[calc(100%-6px)] -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-[1px]"
            style={{
              backgroundColor: isSelected ? '#FFFFFF' : color,
            }}
          />
        </button>
      </Tooltip>
    )
  }
)
