import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { IAnimationPresetsInteractor } from './interactor'
import * as styles from './router.scss'
import { ViewPreset } from './view-preset'
import { ViewPresetType } from './view-preset-type'

export interface IProps {
  interactor: IAnimationPresetsInteractor
}
export const AnimationPresetsRouter: React.FCC<IProps> = observer(
  ({ interactor }) => {
    return (
      <div>
        <ViewPresetType
          type={interactor.type}
          updateType={interactor.updateType}
        />

        <ul>
          {interactor.presetGroups
            .filter((presetGroup) => presetGroup.presets.length > 0)
            .map((presetGroup, i) => (
              <li
                key={i}
                className="p-2 [&:not(:last-child)]:border-b-[1px] [&:not(:last-child)]:border-b-solid [&:not(:last-child)]:border-b-[var(--aninix-color-foreground-black-05)]"
              >
                <div className="px-2 py-2 flex flex-row justify-between">
                  <p className="txt-[11_16_500]">{presetGroup.title}</p>

                  {presetGroup.isLocked && (
                    <div className="flex flex-row items-center gap-1">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_3155_21540)">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.5 3.5V5H4.5V3.5C4.5 2.67157 5.17157 2 6 2C6.82843 2 7.5 2.67157 7.5 3.5ZM3.5 5V3.5C3.5 2.11929 4.61929 1 6 1C7.38071 1 8.5 2.11929 8.5 3.5V5H9C9.27614 5 9.5 5.22386 9.5 5.5V10.5C9.5 10.7761 9.27614 11 9 11H3C2.72386 11 2.5 10.7761 2.5 10.5V5.5C2.5 5.22386 2.72386 5 3 5H3.5Z"
                            fill="black"
                            fillOpacity="0.3"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_3155_21540">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <p className="txt-[8_8_700] text-white bg-green rounded-[2px] p-[2px]">
                        PRO
                      </p>
                    </div>
                  )}
                </div>

                <ul className={styles.presets}>
                  {presetGroup.presets.map((preset, j) => (
                    <li className={styles.preset} key={preset.id}>
                      <ViewPreset
                        preset={preset}
                        onApply={() => interactor.applyPreset(preset)}
                        forceApply={interactor.forceApply}
                        index={j}
                        isLocked={!interactor.isUserPro && preset.isPro}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            ))}
        </ul>
      </div>
    )
  }
)
