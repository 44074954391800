import {
  AnchorPointComponent,
  Entity,
  Point2D,
  UndoRedoSystem,
  UpdatesSystem,
  getAnchorPoint,
  getInitialSize,
  mixed,
  setAnchorPoint,
} from '@aninix-inc/model'
import {
  AnchorPointPosition,
  AnchorPoint as AnchorPointUiComponent,
  CompactPropertyRow,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { Point2dValue } from '../../values/point-2d'

const buttonSize = {
  width: 32,
  height: 32,
}
const iconSize = {
  x: 16,
  y: 16,
}

/**
 * @description compare 2 points to meet anchor point requirements
 */
const comparePoints = (left: number, right: number): boolean =>
  Math.abs(Math.floor(left - right)) <= 1

// @TODO: move to separated file, maybe to model
export const mapPoint2DToAnchorPoint = ({
  point,
  size,
}: {
  point: Point2D
  size: Point2D
}): AnchorPointPosition => {
  const newPoint = {
    x: Math.floor(point.x),
    y: Math.floor(point.y),
  }

  const newSize = {
    x: Math.floor(size.x),
    y: Math.floor(size.y),
  }

  if (newPoint.y === 0) {
    if (newPoint.x === 0) {
      return AnchorPointPosition.TopLeft
    }

    if (comparePoints(newPoint.x, newSize.x / 2)) {
      return AnchorPointPosition.Top
    }

    if (newPoint.x === newSize.x) {
      return AnchorPointPosition.TopRight
    }
  }

  if (comparePoints(newPoint.y, newSize.y / 2)) {
    if (newPoint.x === 0) {
      return AnchorPointPosition.Left
    }

    if (comparePoints(newPoint.x, newSize.x / 2)) {
      return AnchorPointPosition.Center
    }

    if (newPoint.x === newSize.x) {
      return AnchorPointPosition.Right
    }
  }

  if (newPoint.y === newSize.y) {
    if (newPoint.x === 0) {
      return AnchorPointPosition.BottomLeft
    }

    if (comparePoints(newPoint.x, newSize.x / 2)) {
      return AnchorPointPosition.Bottom
    }

    if (newPoint.x === newSize.x) {
      return AnchorPointPosition.BottomRight
    }
  }

  return AnchorPointPosition.Custom
}

// @TODO: move to separated file, maybe to model
export const mapAnchorPointToPoint2D = (
  anchorPoint: AnchorPointPosition,
  size: Point2D
): Point2D => {
  switch (anchorPoint) {
    case AnchorPointPosition.TopLeft:
      return { x: 0, y: 0 }
    case AnchorPointPosition.Top:
      return { x: size.x / 2, y: 0 }
    case AnchorPointPosition.TopRight:
      return { x: size.x, y: 0 }
    case AnchorPointPosition.Left:
      return { x: 0, y: size.y / 2 }
    case AnchorPointPosition.Center:
      return { x: size.x / 2, y: size.y / 2 }
    case AnchorPointPosition.Right:
      return { x: size.x, y: size.y / 2 }
    case AnchorPointPosition.BottomLeft:
      return { x: 0, y: size.y }
    case AnchorPointPosition.Bottom:
      return { x: size.x / 2, y: size.y }
    case AnchorPointPosition.BottomRight:
      return { x: size.x, y: size.y }
    default:
      // Handle AnchorPointPosition.Custom or any other values
      return { x: 0, y: 0 } // You may want to choose a default point based on your needs
  }
}

export interface IProps {
  layers: Entity[]
  time?: number
}
/**
 * @todo add mixed value
 */
export const AnchorPoint: React.FCC<IProps> = ({ layers, time }) => {
  const components = layers.map((layer) =>
    layer.getComponentOrThrow(AnchorPointComponent)
  )
  useComponents(components)

  const value: AnchorPointPosition | typeof mixed = (() => {
    const isXEquals =
      R.keys(
        layers.reduce((acc, node) => {
          const anchorPoint = getAnchorPoint(node, time)
          return {
            ...acc,
            [anchorPoint.x]: anchorPoint.x,
          }
        }, {})
      ).length === 1
    const isYEquals =
      R.keys(
        layers.reduce((acc, node) => {
          const anchorPoint = getAnchorPoint(node, time)
          return {
            ...acc,
            [anchorPoint.y]: anchorPoint.y,
          }
        }, {})
      ).length === 1

    if (isXEquals && isYEquals) {
      const anchorPoint = getAnchorPoint(layers[0], time)
      const size = getInitialSize(layers[0])
      return mapPoint2DToAnchorPoint({
        point: anchorPoint,
        size,
      })
    }

    return mixed
  })()

  const [isFreeAnchorPointEnabled, setIsFreeAnchorPointEnabled] =
    React.useState(() => value === AnchorPointPosition.Custom)

  React.useEffect(() => {
    if (value === AnchorPointPosition.Custom) setIsFreeAnchorPointEnabled(true)
  }, [value])

  const onValueChange = React.useCallback(
    (newValue: AnchorPointPosition): void => {
      if (layers.length === 0) {
        return
      }

      const project = layers[0].getProjectOrThrow()
      const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
      const updates = project.getSystemOrThrow(UpdatesSystem)
      updates.batch(() => {
        layers.forEach((node) => {
          const size = getInitialSize(node)
          setAnchorPoint(node, mapAnchorPointToPoint2D(newValue, size), time)
        })
      })
      undoRedo.commitUndo()
    },
    [layers, time]
  )

  const toggleFreeAnchroPoint = React.useCallback(() => {
    setIsFreeAnchorPointEnabled((v) => !v)
  }, [])

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex flex-row items-center justify-start pt-2">
          <buttons.Icon
            onClick={toggleFreeAnchroPoint}
            btnSize={buttonSize}
            className="flex flex-col items-center justify-center w-8 h-8 mr-2"
            active={isFreeAnchorPointEnabled}
            tooltip="Free Anchor Point"
          >
            <icons.FreeAnchorPoint size={iconSize} />
          </buttons.Icon>

          {isFreeAnchorPointEnabled ? (
            <Point2dValue
              components={components}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
            />
          ) : (
            <AnchorPointUiComponent
              value={value === mixed ? AnchorPointPosition.Custom : value}
              onValueChange={onValueChange}
              className="pb-2"
            />
          )}
        </div>
      }
      rightColumn={null}
    />
  )
}
