import { Operation, OperationKey } from '@aninix-inc/model'
import { config } from '../../../apps/web-app/src/config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

/**
 * It sends array of operations to the server.
 */
export async function pushOperations(
  projectId: string,
  operations: Operation[],
  eventId: string
): Promise<void> {
  await httpClient.patch(
    `${config.apiUrl}/v2/projects/${projectId}/patch`,
    {
      id: projectId,
      projectOps: operations.filter(
        (op) => op[OperationKey.AppliedBy] === projectId
      ),
      entitiesOps: operations.filter(
        (op) => op[OperationKey.AppliedBy] !== projectId
      ),
    } satisfies paths['/v2/projects/{projectId}/patch']['patch']['requestBody']['content']['application/json'],
    {
      headers: {
        'ani-request-id': eventId,
      },
    } satisfies {
      headers: paths['/v2/projects/{projectId}/patch']['patch']['parameters']['header']
    }
  )
}
