import {
  usePlayback,
  useProject,
  useSession,
  useSettings,
  useTimeline,
  useTools,
  useViewport,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'

import {
  useSegmentsPasteUseCase,
  useSegmentsReverseUseCase,
} from '../../use-cases'
import { useHotkeysInteractor } from './interactor'

export interface IProps {}
export const Hotkeys: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const timeline = useTimeline()
  const viewport = useViewport()
  const user = useSettings()
  const tools = useTools()
  const session = useSession()
  const playback = usePlayback()

  const segmentsPasteUseCase = useSegmentsPasteUseCase({ project })
  const segmentsReverseUseCase = useSegmentsReverseUseCase()

  useHotkeysInteractor({
    session,
    playback,
    timeline,
    tools,
    viewport,
    project,
    user,
    segmentsPasteUseCase,
    segmentsReverseUseCase,
  })

  return null
})
