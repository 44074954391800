import * as React from 'react'

export const OpenInEditor = ({ projectId }: { projectId: string }) => (
  <a
    href={`/edit/${projectId}`}
    target="_blank"
    className="flex flex-row items-center gap-2 rounded-lg border-[1px] border-gray-400 px-3 py-[6px] text-sm text-white"
    rel="noreferrer"
  >
    Open in editor
  </a>
)
