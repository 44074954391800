import { Tooltip, icons } from '@aninix/app-design-system'
import {
  getGroupFromKeyframe,
  getSelection,
  nodeColors,
  useSystem,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import {
  EntityType,
  EntityTypeComponent,
  NameComponent,
  NodeColorComponent,
  NodeTypeComponent,
  Project,
  SelectionSystem,
  TargetRelationAspect,
  commitUndo,
  getNode,
} from '@aninix-inc/model'
import classNames from 'classnames'
import tinycolor from 'tinycolor2'
import { hotkeysLabels } from '../../../defaults'

type Breadcrumb = {
  name: string
  isActive: boolean
  tooltipText: string
  onClick: () => void
}

const iconSize = {
  x: 16,
  y: 16,
}

interface IProps {
  project: Project
}
export const Header: React.FC<IProps> = observer(({ project }) => {
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  const selectedNodes = getSelection(project, EntityType.Node)
  const selectedKeyframes = getSelection(project, EntityType.Keyframe)

  const isNothingSelected =
    selectedNodes.length === 0 && selectedKeyframes.length === 0
  const isNodesSelected = selectedNodes.length > 0
  const isSegmentsSelected = selectedKeyframes.length > 1
  const isKeyframesSelected = selectedKeyframes.length > 0

  // @NOTE: also used in hotkeys shift+enter. Refactor and move to single place
  const handleLayerClick = React.useCallback(() => {
    const parentsToSelect = selectedKeyframes
      .map((k) => getGroupFromKeyframe(k).layer)
      .filter((l) => l != null)
    const uniqParents = R.uniqBy((node) => node.id, parentsToSelect)

    if (uniqParents.length === 0) {
      return
    }

    selection.replace(uniqParents.map((n) => n.id))
    commitUndo(project)
    return
  }, [selection, isSegmentsSelected, isKeyframesSelected, selectedKeyframes])

  // @NOTE: also used in hotkeys cmd+enter. Refactor and move to single place
  const handleSegmentsClick = React.useCallback(() => {
    if (isNodesSelected === false) {
      return
    }

    const keyframes = project.getEntitiesByPredicate(
      (e) =>
        e.getComponentOrThrow(EntityTypeComponent).value === EntityType.Keyframe
    )

    if (selectedNodes.length === 0) {
      return
    }

    selectedNodes.forEach((node) => {
      const keyframeIdsToSelect = keyframes
        .filter(
          (k) =>
            k.getAspectOrThrow(TargetRelationAspect).getTargetEntityOrThrow()
              .id === node.id
        )
        .map((k) => k.id)

      if (keyframeIdsToSelect.length === 0) {
        return
      }

      selection.deselect([node.id]).select(keyframeIdsToSelect)
    })
  }, [selection, isNodesSelected, selectedNodes])

  if (isNothingSelected) {
    return (
      <div
        className="border-b-solid flex h-10 flex-row items-center gap-1 border-b-[1px] px-4 py-3"
        style={{
          borderBottomColor: '#F0F0F0',
        }}
      >
        <p className="text-[11px] font-semibold leading-[16px]">
          Project properties
        </p>
      </div>
    )
  }

  // const hasAnimation = selectedNodes.reduce(
  //   (acc, node) => acc && node.hasAnimation,
  //   true
  // )
  const hasAnimation = false
  const selectedNode = isNodesSelected
    ? selectedNodes[0]
    : getNode(selectedKeyframes[0])

  if (selectedNode === undefined) {
    throw new Error('Invalid state. Node not found')
  }

  const type =
    selectedNodes.length > 1
      ? icons.LayerType.Frame
      : selectedNode.getComponentOrThrow(NodeTypeComponent).value
  const color =
    nodeColors[selectedNode.getComponentOrThrow(NodeColorComponent).value]
  const breadcrumbs = [
    {
      name:
        selectedNodes.length > 1
          ? 'Layers'
          : selectedNode.getComponentOrThrow(NameComponent).value,
      isActive: isNodesSelected,
      onClick: handleLayerClick,
      tooltipText: `Select layers (${hotkeysLabels().shift}${
        hotkeysLabels().enter
      })`,
    },
    hasAnimation
      ? {
          name: 'Segments',
          isActive: isSegmentsSelected || isKeyframesSelected,
          onClick: handleSegmentsClick,
          tooltipText: `Select segments (${hotkeysLabels().ctrl}${
            hotkeysLabels().enter
          })`,
        }
      : undefined,
  ].filter((item) => item != null) as Breadcrumb[]

  const colorInRgb = (() => {
    const rgb = tinycolor(color).toRgb()
    return `${rgb.r}, ${rgb.g}, ${rgb.b}`
  })()

  return (
    <div
      className="border-b-solid flex h-10 flex-row items-center gap-1 border-b-[1px] px-4 py-3"
      style={{
        background: `linear-gradient(180deg, rgba(${colorInRgb}, 0) 0%, rgba(${colorInRgb}, 0.1) 100%)`,
        borderBottomColor: `rgba(${colorInRgb}, 0.4)`,
      }}
    >
      {/* @ts-ignore */}
      <icons.Layer type={type} size={iconSize} />

      <ul className="flex flex-row items-center justify-start gap-1">
        {breadcrumbs.map((breadcrumb, idx, array) => (
          <React.Fragment key={breadcrumb.name}>
            <li
              className={classNames(
                'flex-shrink-0 text-[11px] font-normal leading-[16px]',
                {
                  '!font-semibold': breadcrumb.isActive,
                }
              )}
            >
              <Tooltip title={breadcrumb.tooltipText} arrow>
                <button
                  onClick={breadcrumb.onClick}
                  className="max-w-[64px] cursor-default overflow-hidden text-ellipsis whitespace-nowrap text-left"
                >
                  {breadcrumb.name}
                </button>
              </Tooltip>
            </li>

            {idx !== array.length - 1 && <p style={{ color }}>/</p>}
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
})
