import { Component, commitUndo } from '@aninix-inc/model'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useValueNumber } from '../../../../../hooks/value-controllers/number'
import { NumericValue } from '../numeric-value'

// @TODO: provide generic types
export interface IProps {
  components: Component[]
  color?: string
  time?: number
  format?: (value: number) => string
  min?: number
  max?: number
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  disabled?: boolean
  prefix?: string
  suffix?: string
}
export const NumberValue: React.FC<IProps> = observer(
  ({
    components,
    color = 'black',
    time,
    format,
    min,
    max,
    before = R.identity,
    after = R.identity,
    disabled,
    prefix,
    suffix,
  }) => {
    const { value, updateValue } = useValueNumber({ components, time })
    const endChange = React.useCallback(() => {
      commitUndo(components[0].entity.getProjectOrThrow())
    }, [components])

    return (
      <NumericValue
        value={before(value as number)}
        onChange={(newValue) => updateValue(after(newValue))}
        onEndChange={endChange}
        format={format}
        min={min}
        max={max}
        disabled={disabled}
        prefix={prefix}
        suffix={suffix}
        textColor={color}
      />
    )
  }
)
