import {
  ColorStopsRelationsAspect,
  Entity,
  EntityType,
  OpacityComponent,
  PaintTypeComponent,
  ProgressComponent,
  RgbaValueComponent,
  VisibleInViewportComponent,
  commitUndo,
  getAnimatableValue,
} from '@aninix-inc/model'
import { PaintControl } from '@aninix/app-design-system'
import { useEntities, useProject } from '@aninix/core'
import { useSelection } from '@aninix/editor/modules/selection'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useUpdates } from '../../../../../hooks/use-updates'

export interface IProps {
  paints: Entity[]
  time?: number
}
export const GradientValue: React.FCC<IProps> = observer(({ paints, time }) => {
  const project = useProject()
  const updates = useUpdates()
  const { selection } = useSelection()
  const selectedColorStopId = selection.getEntitiesByEntityType(
    EntityType.ColorStop
  )[0]?.id

  const aspects = paints.map((p) =>
    p.getAspectOrThrow(ColorStopsRelationsAspect)
  )

  const rawColorStops = aspects.flatMap((a) =>
    R.sort(
      (left, right) =>
        left.getComponentOrThrow(ProgressComponent).value -
        right.getComponentOrThrow(ProgressComponent).value,
      a.getChildrenList()
    )
  )
  useEntities(rawColorStops)

  const colorStops = R.uniqBy(
    (colorStop) => `${colorStop.progress}-${JSON.stringify(colorStop.value)}`,
    rawColorStops.map((colorStop) => ({
      id: colorStop.id,
      value: getAnimatableValue(
        colorStop.getComponentOrThrow(RgbaValueComponent),
        time
      ),
      progress: getAnimatableValue(
        colorStop.getComponentOrThrow(ProgressComponent),
        time
      ),
    }))
  )
  const opacity = aspects[0].entity.getComponentOrThrow(OpacityComponent).value
  const visible = R.all(
    (e) => e.getComponentOrThrow(VisibleInViewportComponent).value,
    aspects.map((aspect) => aspect.entity)
  )
  const paintType =
    aspects[0].entity.getComponentOrThrow(PaintTypeComponent).value
  const endChange = () => commitUndo(project)

  // @TODO: provide support of mixed values
  return (
    <div className="flex flex-col">
      <PaintControl
        colorStops={colorStops}
        opacity={opacity}
        onGradientChange={(newColorStops, newOpacity, newType) => {
          updates.batch(() => {
            const colorStopsMap = Object.fromEntries(
              rawColorStops.map((colorStop) => [colorStop.id, colorStop])
            )

            for (const newColorStop of newColorStops ?? []) {
              if (newColorStop.id == null) {
                continue
              }

              const targetColorStop = colorStopsMap[newColorStop.id!]!

              if (newColorStop.value != null) {
                targetColorStop.updateComponent(
                  RgbaValueComponent,
                  newColorStop.value
                )
              }

              if (newColorStop.progress != null) {
                targetColorStop.updateComponent(
                  ProgressComponent,
                  newColorStop.progress
                )
              }
            }

            if (newOpacity != null) {
              for (const aspect of aspects) {
                aspect.entity.updateComponent(OpacityComponent, newOpacity)
              }
            }

            if (newType != null) {
              // @TODO: implement
            }
          })
        }}
        visible={visible}
        onVisibilityClick={() => {
          updates.batch(() => {
            aspects
              .map((aspect) => aspect.entity)
              .forEach((e) =>
                e.updateComponent(VisibleInViewportComponent, (v) => !v)
              )
          })
        }}
        onEndChange={endChange}
        fill={{
          id: 'faux',
          type: paintType,
          visible,
          colorStops,
          opacity,
        }}
        onExpand={() => {
          const ids = paints.map((c) => c.id) ?? []
          if (rawColorStops[0]) ids.push(rawColorStops[0].id)
          selection.select(ids)
        }}
        onCollapse={() => {
          const selectedStops = selection.getEntitiesByEntityType(
            EntityType.ColorStop
          )
          selection.deselect(
            [paints.map((c) => c.id), selectedStops.map((s) => s.id)].flat()
          )
        }}
        onColorStopSelect={(id) => {
          const selectedStops = selection.getEntitiesByEntityType(
            EntityType.ColorStop
          )
          selection.deselect(selectedStops.map((s) => s.id))
          selection.select([id])
        }}
        selectedColorStopId={selectedColorStopId}
      />
    </div>
  )
})
