import {
  AnimationCurveAspect,
  DurationComponent,
  EntityType,
  FpsComponent,
  NameComponent,
  NodeColorComponent,
  Root,
  SelectionSystem,
  TimeComponent,
  UpdatesSystem,
  cleanupKeyframes,
  commitUndo,
  getNode,
  lerpClamped,
  segmentsFromKeyframes,
  type Entity,
} from '@aninix-inc/model'
import { Tooltip, useMouseMove } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { useClipboard } from '@aninix/clipboard'
import {
  KeyModificator,
  getSelection,
  useEntity,
  useSession,
  useSystem,
} from '@aninix/core'
import classNames from 'classnames'
import trim from 'lodash/trim'
import * as R from 'ramda'
import * as React from 'react'
import tinycolor from 'tinycolor2'
import { defaults, hotkeysLabels } from '../../../../../defaults'
import * as timeConverters from '../../../../../helpers/timeConverters'
import { useUi } from '../../../../../stores'
import { useSegmentsReverseUseCase } from '../../../../../use-cases'
import { useFormattedTime } from '../../../../properties-panel/components/formatted-time'
import * as styles from './index.scss'

export interface IProps {
  leftKeyframe: Entity
  rightKeyframe: Entity
  parentTrackWidth: number
  onDrag?: (e: any) => void
}
export const Segment: React.FCC<IProps> = ({
  leftKeyframe,
  rightKeyframe,
  parentTrackWidth,
  onDrag,
}) => {
  useEntity(leftKeyframe)
  useEntity(rightKeyframe)
  const clipboard = useClipboard()
  const session = useSession()
  const uiStore = useUi()
  const project = leftKeyframe.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)
  useSystem(selection)
  const root = project.getEntityByTypeOrThrow(Root)

  const layer = getNode(leftKeyframe)

  if (layer === undefined) {
    throw new Error('Invalid state. Node not found')
  }

  const color =
    defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
  const curveStyleName = leftKeyframe
    .getAspectOrThrow(AnimationCurveAspect)
    .hasCurveStyle()
    ? leftKeyframe
        .getAspectOrThrow(AnimationCurveAspect)
        .getCurveStyle()
        .getComponentOrThrow(NameComponent).value
    : undefined
  const segmentsReverseUseCase = useSegmentsReverseUseCase()
  const isSelected =
    selection.isSelected(leftKeyframe.id) &&
    selection.isSelected(rightKeyframe.id)
  const hasActiveKeyModificators =
    session.keyModificators.includes(KeyModificator.Ctrl) ||
    session.keyModificators.includes(KeyModificator.Shift)
  const projectFps = root.getComponentOrThrow(FpsComponent).value
  const projectDuration = root.getComponentOrThrow(DurationComponent).value

  const leftKeyframeTime = leftKeyframe.getComponentOrThrow(TimeComponent).value
  const rightKeyframeTime =
    rightKeyframe.getComponentOrThrow(TimeComponent).value

  const { value: formattedDuration, suffix: durationSuffix } = useFormattedTime(
    rightKeyframeTime - leftKeyframeTime
  )
  const duration = rightKeyframeTime - leftKeyframeTime

  const handleTimeChange = React.useCallback(
    (time: number): void => {
      const offset = time - leftKeyframeTime
      const keyframes = getSelection(project, EntityType.Keyframe)
      keyframes.forEach((k) =>
        k.updateComponent(TimeComponent, (v) => v + offset)
      )
    },
    [projectFps, leftKeyframe, selection, leftKeyframeTime]
  )

  const select = React.useCallback(() => {
    if (isSelected && hasActiveKeyModificators) {
      selection.deselect([leftKeyframe.id, rightKeyframe.id])
      commitUndo(project)
      return
    }

    if (hasActiveKeyModificators) {
      selection.select([leftKeyframe.id, rightKeyframe.id])
      commitUndo(project)
      return
    }

    selection.replace([leftKeyframe.id, rightKeyframe.id])
    commitUndo(project)
  }, [
    project,
    selection,
    leftKeyframe,
    rightKeyframe,
    isSelected,
    hasActiveKeyModificators,
  ])

  const reverse = React.useCallback((): void => {
    updates.batch(() => {
      const keyframes = getSelection(project, EntityType.Keyframe)
      const sortedByTime = R.sortBy(
        (key) => key.getComponentOrThrow(TimeComponent).value,
        keyframes
      )
      const segments = segmentsFromKeyframes(sortedByTime)
      segmentsReverseUseCase.execute({ segments })
    })
  }, [session, segmentsReverseUseCase, updates])

  const openContextMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (
        selection.isSelected(leftKeyframe.id) === false ||
        selection.isSelected(rightKeyframe.id) === false
      ) {
        selection.replace([leftKeyframe.id, rightKeyframe.id])
      }

      uiStore.openContextMenu(
        [
          {
            title: 'Copy',
            onClick: () => {
              clipboard.copyCurrentSelection()
              uiStore.closeContextMenu()
            },
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'C']} />
              </p>
            ),
          },
          'divider',
          {
            title: 'Reverse',
            onClick: () => {
              reverse()
              uiStore.closeContextMenu()
            },
          },
        ],
        event
      )
    },
    [leftKeyframe, rightKeyframe]
  )

  const time = leftKeyframeTime
  const endUndoGroup = React.useCallback(() => {
    cleanupKeyframes(project)
    commitUndo(project)
  }, [project])
  const onSelect = select

  // @NOTE: required to handle the same interaction as in figma
  const wasTrigerredRef = React.useRef(false)
  const initialTime = React.useRef<number>(time)
  const { offsetX, isListening, wasTriggered, startListen } = useMouseMove({
    threshold: 2,
    onTrigger: () => {
      wasTrigerredRef.current = true
    },
    onFinish: endUndoGroup,
  })

  // TODO: refactor
  const handlerWidth = 6
  const weight = lerpClamped(1 - duration / projectDuration, 0.1, 0.4)

  const convertTimeToPixels = React.useCallback(
    (providedTime: number) =>
      timeConverters.convertTimeToPixels({
        time: providedTime,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        projectDuration,
      }),
    [parentTrackWidth, projectDuration]
  )

  const convertPixelsToTime = React.useCallback(
    (pixels: number) =>
      timeConverters.convertPixelsToTime({
        pixels,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        projectDuration,
      }),
    [parentTrackWidth, projectDuration]
  )

  React.useEffect(() => {
    if (isListening && wasTriggered) {
      const newTime = initialTime.current + convertPixelsToTime(offsetX)
      handleTimeChange(newTime)
    }
  }, [isListening, wasTriggered, offsetX, convertPixelsToTime])

  React.useEffect(() => {
    if (isListening === false) {
      initialTime.current = time
    }
  }, [isListening])

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (isSelected === false) {
        return
      }

      e.preventDefault()
      e.stopPropagation()
      initialTime.current = leftKeyframeTime
      // @ts-ignore
      startListen(e)
      onDrag?.(e)
    },
    [startListen, isSelected, leftKeyframeTime]
  )

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.preventDefault()
      e.stopPropagation()

      if (wasTrigerredRef.current === false) {
        onSelect()
      }

      wasTrigerredRef.current = false
    },
    [onSelect]
  )

  const left = convertTimeToPixels(time) + handlerWidth
  const width = convertTimeToPixels(duration) + handlerWidth * 2

  return (
    <Tooltip
      title={
        session.keyModificators.includes(KeyModificator.Alt)
          ? `${formattedDuration}${durationSuffix}`
          : ''
      }
      arrow={false}
      placement="top"
      tooltipClassName="rounded-full bg-[#0B1118]"
      TransitionProps={{
        exit: false,
      }}
      followCursor
    >
      <button
        type="button"
        // @NOTE: required to calculate selection in tracks
        data-model-type="segment"
        className={styles.container}
        style={{
          left,
          width,
        }}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        onContextMenu={openContextMenu}
      >
        <span
          className={styles.selected}
          style={{
            backgroundColor: color,
            opacity: isSelected ? 1 : 0,
          }}
        />

        <span
          className={styles.regular}
          style={{
            backgroundColor: isSelected ? '#FFFFFF' : color,
            opacity: isSelected ? 0.3 : weight,
          }}
        />

        {curveStyleName != null && (
          <span
            className={classNames(styles['curve-style-name'], {
              [styles['curve-style-name--selected']]: isSelected,
            })}
            style={{
              // @ts-ignore
              ['--color']: isSelected
                ? '255, 255, 255'
                : `${tinycolor(color).toRgb().r}, ${
                    tinycolor(color).toRgb().g
                  }, ${tinycolor(color).toRgb().b}`,
              maxWidth: width - 32,
            }}
          >
            {trim(R.last(curveStyleName.split('/')))}
          </span>
        )}
      </button>
    </Tooltip>
  )
}
