import { usePlayback } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import {
  Project,
  SelectionSystem,
  UndoRedoSystem,
  UpdatesSystem,
} from '@aninix-inc/model'
import { useUi } from '../../stores'
import { useUseCases } from '../../use-cases'
import { useAnimationQuickPresetsInteractor } from './interactor'
import { AnimationQuickPresetsRouter } from './router'

export interface IProps {
  project: Project
  quickPresetsViewed: boolean
}
export const AnimationQuickPresets: React.FCC<IProps> = observer(
  ({ project, quickPresetsViewed }) => {
    const selection = project.getSystemOrThrow(SelectionSystem)
    const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
    const updates = project.getSystemOrThrow(UpdatesSystem)
    const playback = usePlayback()
    const { useApplyPresetUseCase } = useUseCases()
    const uiStore = useUi()
    const applyPresetUseCase = useApplyPresetUseCase({
      playback,
      selection,
      undoRedo,
      updates,
    })
    const interactor = useAnimationQuickPresetsInteractor({
      uiStore,
      selection,
      applyPresetUseCase,
      quickPresetsViewed,
    })

    return <AnimationQuickPresetsRouter interactor={interactor} />
  }
)
