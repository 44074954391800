import {
  BlurRadiusComponent,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  NumberValueComponent,
  Point2dValueComponent,
  RgbaValueComponent,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
} from '@aninix-inc/model'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Property } from './common/property'

export interface IProps {
  entity: Entity
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const BlendAnimation: React.FCC<IProps> = observer(
  ({ entity, timeRange, startEvent, endEvent }) => {
    if (entity.hasAspect(EffectsRelationsAspect) === false) {
      return null
    }

    return (
      <>
        {entity
          .getAspectOrThrow(EffectsRelationsAspect)
          .getChildrenList()
          .map((effect, idx) => {
            const effectType =
              effect.getComponentOrThrow(EffectTypeComponent).value
            const id = safeString(entity.id)

            if (effectType === EffectType.DropShadow) {
              return (
                <React.Fragment key={effect.id}>
                  <Property
                    component={effect.getComponentOrThrow(
                      ShadowRadiusComponent
                    )}
                    attributeName="stdDeviation"
                    getKeyframeValue={(keyframe) =>
                      keyframe
                        .getComponentOrThrow(NumberValueComponent)
                        .value.toString()
                    }
                    applyTo={`${id}_dropShadow_${idx}_radius`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />

                  <Property
                    component={effect.getComponentOrThrow(ShadowColorComponent)}
                    attributeName="fill"
                    getKeyframeValue={(keyframe) => {
                      const color =
                        keyframe.getComponentOrThrow(RgbaValueComponent).value
                      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
                    }}
                    applyTo={`${id}_dropShadow_${idx}_path`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />

                  <Property
                    component={effect.getComponentOrThrow(
                      ShadowOffsetComponent
                    )}
                    attributeName="dx"
                    getKeyframeValue={(keyframe) =>
                      `${
                        keyframe.getComponentOrThrow(Point2dValueComponent)
                          .value.x
                      }`
                    }
                    applyTo={`${id}_dropShadow_${idx}_offset`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />

                  <Property
                    component={effect.getComponentOrThrow(
                      ShadowOffsetComponent
                    )}
                    attributeName="dy"
                    getKeyframeValue={(keyframe) =>
                      `${
                        keyframe.getComponentOrThrow(Point2dValueComponent)
                          .value.y
                      }`
                    }
                    applyTo={`${id}_dropShadow_${idx}_offset`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />
                </React.Fragment>
              )
            }

            if (effectType === EffectType.InnerShadow) {
              return (
                <React.Fragment key={effect.id}>
                  <Property
                    component={effect.getComponentOrThrow(
                      ShadowRadiusComponent
                    )}
                    attributeName="stdDeviation"
                    getKeyframeValue={(keyframe) =>
                      keyframe
                        .getComponentOrThrow(NumberValueComponent)
                        .value.toString()
                    }
                    applyTo={`${id}_innerShadow_${idx}_radius`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />

                  <Property
                    component={effect.getComponentOrThrow(ShadowColorComponent)}
                    attributeName="fill"
                    getKeyframeValue={(keyframe) => {
                      const color =
                        keyframe.getComponentOrThrow(RgbaValueComponent).value
                      return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
                    }}
                    applyTo={`${id}_innerShadow_${idx}_path`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />

                  <Property
                    component={effect.getComponentOrThrow(
                      ShadowOffsetComponent
                    )}
                    attributeName="dx"
                    getKeyframeValue={(keyframe) =>
                      `${
                        keyframe.getComponentOrThrow(Point2dValueComponent)
                          .value.x
                      }`
                    }
                    applyTo={`${id}_innerShadow_${idx}_offset`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />

                  <Property
                    component={effect.getComponentOrThrow(
                      ShadowOffsetComponent
                    )}
                    attributeName="dy"
                    getKeyframeValue={(keyframe) =>
                      `${
                        keyframe.getComponentOrThrow(Point2dValueComponent)
                          .value.y
                      }`
                    }
                    applyTo={`${id}_innerShadow_${idx}_offset`}
                    timeRange={timeRange}
                    startEvent={startEvent}
                    endEvent={endEvent}
                  />
                </React.Fragment>
              )
            }

            if (effectType === EffectType.LayerBlur) {
              return (
                <Property
                  key={effect.id}
                  component={effect.getComponentOrThrow(BlurRadiusComponent)}
                  attributeName="stdDeviation"
                  getKeyframeValue={(keyframe) =>
                    keyframe
                      .getComponentOrThrow(NumberValueComponent)
                      .value.toString()
                  }
                  applyTo={`${id}_layerBlur_${idx}_radius`}
                  startEvent={startEvent}
                  endEvent={endEvent}
                />
              )
            }

            // @TODO: implement
            // if (effectType === PropertyGroupType.BackgroundBlur) {
            //   return (
            //     <Property
            //       key={effect.id}
            //       entity={entity}
            //       component={effect.radius}
            //       attributeName="stdDeviation"
            //       getKeyframeValue={(value) => value}
            //       applyTo={`${id}_backgroundBlur_${idx}_radius`}
            //       startEvent={startEvent}
            //       endEvent={endEvent}
            //     />
            //   )
            // }

            return <React.Fragment key={effect.id} />
          })}
      </>
    )
  }
)
