// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E6sFfVE5r9ga2fI4CoOw{font-weight:400;font-size:11px;font-family:"Inter",sans-serif;line-height:16px;letter-spacing:.005em;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:center;width:80px;height:48px;padding:6px 16px;color:rgba(255,255,255,.8);background:none;border:none;outline:none;cursor:default}.E6sFfVE5r9ga2fI4CoOw:hover{background-color:#000}.E6sFfVE5r9ga2fI4CoOw svg{margin-left:4px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/history/toolbar-legacy/viewport-zoom/view.scss","webpack://./packages/app-design-system/src/theme/typography.scss","webpack://./packages/app-design-system/src/theme/font.scss","webpack://./packages/app-design-system/src/theme/colors.scss"],"names":[],"mappings":"AAIA,sBCOE,eAAA,CACA,cAAA,CACA,8BCba,CDcb,gBAAA,CACA,qBAAA,CDTA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,UAAA,CACA,WAXiB,CAajB,gBAAA,CACA,0BGYmB,CHXnB,eAAA,CACA,WAAA,CACA,YAAA,CACA,cAAA,CAEA,4BACE,qBGsBe,CHnBjB,0BACE,eAAA","sourceRoot":""}]);
// Exports
var _1 = `E6sFfVE5r9ga2fI4CoOw`;
export { _1 as "zoom-button" };
export default ___CSS_LOADER_EXPORT___;
