import { Point2D, SpringCurve } from '@aninix-inc/model/legacy'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import { useMouseMove } from '../../../../../hooks'
import * as styles from './styles.scss'

const PADDING_X = 6
const PADDING_Y = 24
const GRAPH_DETAILS = 0.5
const MIN_DAMPING = 1
const MAX_DAMPING = 40
const MIN_STIFFNESS = 5
const MAX_STIFFNESS = 500

const noop = () => {}

export interface IProps {
  width: number
  height: number
  value: SpringCurve
  disableEditing?: boolean
  onChange: (payload: { stiffness: number; damping: number }) => void
  onDragStart?: () => void
  onDragEnd?: () => void
  showSpeed?: boolean
  color?: string
}

export const SpringGraph: React.FCC<IProps> = observer(
  ({
    width,
    height,
    value,
    disableEditing = false,
    onChange,
    onDragStart = noop,
    onDragEnd = noop,
    color = styles.highlight,
  }) => {
    const containerRef = React.useRef<any>()
    const {
      endAtX,
      endAtY,
      isListening,
      wasTriggered,
      shiftPressed,
      startListen,
    } = useMouseMove({
      threshold: 2,
      element: containerRef.current,
      delay: 8.3,
      onStart: onDragStart,
      onFinish: onDragEnd,
    })

    const activePointBuffer = React.useRef<Point2D>({ x: 0, y: 0 })
    const minY = value
      .getCache()
      .reduce((acc, point) => R.min(acc, point.value), 0)
    const maxY = value
      .getCache()
      .reduce((acc, point) => R.max(acc, point.value), 1)
    const coefficient = 1 / ((maxY - minY) * 1.2)
    const anchor = 0.5

    const scalePoint = (number: number) => {
      const fromCenter = number - anchor
      const scaledFromCenter = fromCenter * coefficient
      const normal = anchor - scaledFromCenter
      return normal
    }

    const downscalePoint = (number: number) => {
      const fromCenter = number - anchor
      const scaledFromCenter = fromCenter * (1 / coefficient)
      const normal = anchor - scaledFromCenter
      return normal
    }

    const getPositionFromPoint = (point: Point2D) => {
      const progression = scalePoint(point.y)

      return {
        x: PADDING_X + point.x * (width - PADDING_X * 2),
        y: PADDING_Y + progression * (height - PADDING_Y * 2),
      }
    }

    const getPointFromPosition = (position: Point2D) => ({
      x: (position.x - PADDING_X) / (width - PADDING_X * 2),
      y: downscalePoint((position.y - PADDING_Y) / (height - PADDING_Y * 2)),
    })

    const horizontalLines = React.useMemo(() => [0.25, 0.5, 0.75], [])
    const verticalLines = React.useMemo(() => [0.25, 0.5, 0.75], [])

    const valuePreview = (() => {
      const samplesCount = value.getCache().length * GRAPH_DETAILS

      if (samplesCount === 0) {
        return []
      }

      const range = R.range(0, Math.round(samplesCount) + 1).map(
        (idx) => idx / samplesCount
      )

      const previewPoints = range.map((progress) => {
        const result = value.applyCurve(progress)
        return {
          x: progress,
          y: result,
        }
      })

      return previewPoints
    })()

    const handlerPosition = (() => {
      const x = (value.damping - MIN_DAMPING) / (MAX_DAMPING - MIN_DAMPING)
      const y =
        (value.stiffness - MIN_STIFFNESS) / (MAX_STIFFNESS - MIN_STIFFNESS)

      return getPositionFromPoint({ x, y })
    })()

    const handleStartListen = React.useCallback(
      (e: any) => {
        // @ts-ignore
        startListen(e)
        activePointBuffer.current = handlerPosition
      },
      [handlerPosition]
    )

    React.useEffect(() => {
      if (isListening === false || wasTriggered === false) {
        return
      }

      const x = R.clamp(boundPointMin.x - PADDING_X, boundPointMax.x, endAtX)
      const y = R.clamp(boundPointMin.y, boundPointMax.y, endAtY)

      // @NOTE: needed to handle vertical and horizontal stickness when shift pressed
      const finalValue = (() => {
        const pointToCompare = activePointBuffer.current

        if (shiftPressed) {
          const xDelta = Math.abs(x - pointToCompare.x)
          const yDelta = Math.abs(y - pointToCompare.y)

          if (xDelta > yDelta) {
            return {
              x,
              y: pointToCompare.y,
            }
          }

          return {
            x: pointToCompare.x,
            y,
          }
        }

        return { x, y }
      })()

      const point = getPointFromPosition(finalValue)
      const newStiffness =
        (MAX_STIFFNESS - MIN_STIFFNESS) * point.y + MIN_STIFFNESS
      const newDamping = (MAX_DAMPING - MIN_DAMPING) * point.x + MIN_DAMPING
      onChange({ stiffness: newStiffness, damping: newDamping })
    }, [isListening, wasTriggered, endAtX, endAtY, shiftPressed])

    const boundPointMin = React.useMemo(
      () => getPositionFromPoint({ x: MIN_DAMPING / MAX_DAMPING, y: 1 }),
      [getPositionFromPoint]
    )

    const boundPointMax = React.useMemo(
      () => getPositionFromPoint({ x: 1, y: MIN_STIFFNESS / MAX_STIFFNESS }),
      [getPositionFromPoint]
    )

    const keyframe = React.useCallback(
      (type: 'in' | 'out') => {
        const position =
          type === 'out'
            ? getPositionFromPoint({ x: 1, y: 1 })
            : getPositionFromPoint({ x: 0, y: 0 })

        return (
          <rect
            width={8}
            height={8}
            style={{
              transform: `translate(${position.x - 4}px, ${
                position.y - 4
              }px) rotate(45deg)`,
              transformOrigin: '4px 4px',
            }}
            stroke={styles.value}
            strokeWidth={2}
            fill={styles.bg}
            rx={2}
          />
        )
      },
      [getPositionFromPoint, disableEditing]
    )

    return (
      <svg
        ref={containerRef}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          userSelect: 'none',
        }}
      >
        <rect
          x={PADDING_X}
          y={PADDING_Y + ((height - PADDING_Y * 2) * (1 - coefficient)) / 2}
          width={width - PADDING_X * 2}
          height={(height - PADDING_Y * 2) * coefficient}
          fill={styles.rect}
          fillOpacity={0.5}
        />

        {horizontalLines.map((line, idx) => (
          <line
            key={idx}
            strokeWidth={1}
            stroke={styles.lines}
            strokeOpacity={0.7}
            x1={getPositionFromPoint({ x: 0, y: line }).x}
            y1={getPositionFromPoint({ x: 0, y: line }).y}
            x2={getPositionFromPoint({ x: 1, y: line }).x}
            y2={getPositionFromPoint({ x: 1, y: line }).y}
          />
        ))}

        {verticalLines.map((line, idx) => (
          <line
            key={idx}
            strokeWidth={1}
            stroke={styles.lines}
            strokeOpacity={0.7}
            x1={getPositionFromPoint({ x: line, y: minY - 1 }).x}
            y1={getPositionFromPoint({ x: line, y: minY - 1 }).y}
            x2={getPositionFromPoint({ x: line, y: maxY + 1 }).x}
            y2={getPositionFromPoint({ x: line, y: maxY + 1 }).y}
          />
        ))}

        <polyline
          points={R.flatten(
            valuePreview.map((point) => {
              const modifiedPoint = getPositionFromPoint(point)
              return [modifiedPoint.x, modifiedPoint.y]
            })
          ).join(',')}
          stroke={disableEditing ? styles.valueDisabled : styles.value}
          strokeWidth={disableEditing ? 2 : 3}
          fill="none"
        />

        {disableEditing === false && (
          <>
            {keyframe('in')}
            {keyframe('out')}
          </>
        )}

        {disableEditing === false && (
          <circle
            cx={handlerPosition.x}
            cy={handlerPosition.y}
            r={4}
            fill={color}
            onMouseDown={handleStartListen}
          />
        )}
      </svg>
    )
  }
)
