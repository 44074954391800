import { Node, protect } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'

import { Entity, LayoutAspect } from '@aninix-inc/model'
import {
  getAbsoluteTransformMatrix,
  getAbsoluteTransformMatrixV2,
} from './get-absolute-transform-matrix'

// @TODO: improve bounding box finding algorithm
export function getAbsoluteBoundingBoxAtTime(payload: {
  node: Node
  time: number
}) {
  const { node, time } = payload

  const absoluteTransformMatrix = getAbsoluteTransformMatrix({ node, time })
  const baseSize = node.initialSize.value
  const anchorPoint = node.anchorPoint.getValue(time)
  const size = node.size.getValue(time)
  const rect = new paper.Path.Rectangle(
    new paper.Point(0, 0),
    new paper.Size(baseSize.x, baseSize.y)
  )
  rect.scale(
    size.x / protect(baseSize.x),
    size.y / protect(baseSize.y),
    new paper.Point(anchorPoint.x, anchorPoint.y)
  )
  rect.transform(absoluteTransformMatrix)
  const bounds = rect.bounds

  return {
    x: bounds.topLeft.x,
    y: bounds.topLeft.y,
    width: bounds.width,
    height: bounds.height,
  }
}

// @TODO: improve bounding box finding algorithm
export function getAbsoluteBoundingBoxAtTimeV2(payload: {
  entity: Entity
  time: number
}) {
  const { entity, time } = payload

  const absoluteTransformMatrix = getAbsoluteTransformMatrixV2({ entity, time })
  const layout = entity.getAspectOrThrow(LayoutAspect)
  const baseSize = layout.initialSize
  const anchorPoint = layout.anchorPoint.getValue(time)
  const size = layout.size.getValue(time)
  const rect = new paper.Path.Rectangle(
    new paper.Point(0, 0),
    new paper.Size(baseSize.x, baseSize.y)
  )
  rect.scale(
    size.x / protect(baseSize.x),
    size.y / protect(baseSize.y),
    new paper.Point(anchorPoint.x, anchorPoint.y)
  )
  rect.transform(absoluteTransformMatrix)
  const bounds = rect.bounds

  return {
    x: bounds.topLeft.x,
    y: bounds.topLeft.y,
    width: bounds.width,
    height: bounds.height,
  }
}
