import { commitUndo, Component } from '@aninix-inc/model'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValueSpatialPoint2d } from '../../../../../hooks/value-controllers/spatial-point-2d'
import { NumericValue } from '../numeric-value'

// @TODO: provide generic types
export interface IProps {
  components: Component[]
  color?: string
  time?: number
  formatValue?: (value: number) => string
}
export const SpatialPoint2dValue: React.FCC<IProps> = observer(
  ({ components, color = 'black', time, formatValue }) => {
    const { x, y, updateX, updateY } = useValueSpatialPoint2d({
      components,
      time,
    })
    const project = components[0].entity.getProjectOrThrow()
    const onEndChange = React.useCallback(() => {
      commitUndo(project)
    }, [components])

    return (
      <div className="flex flex-row">
        <NumericValue
          onEndChange={onEndChange}
          suffix=", "
          value={x as number}
          onChange={updateX}
          textColor={color}
          format={formatValue}
        />
        <NumericValue
          onEndChange={onEndChange}
          value={y as number}
          onChange={updateY}
          textColor={color}
          format={formatValue}
        />
      </div>
    )
  }
)
