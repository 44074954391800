import { DurationComponent, Root, UpdatesSystem } from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import { usePlayback, useProject } from '@aninix/core'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import * as timeConverters from '../../../../helpers/timeConverters'
import {
  useFormatTime,
  useFormattedTime,
} from '../../../properties-panel/components/formatted-time'
import * as styles from './index.scss'

// TODO: refactor
const handlerWidth = 6

export interface IProps {
  projectDuration: number
  parentTrackWidth: number
}
export const PreviewRange: React.FCC<IProps> = observer(
  ({ projectDuration, parentTrackWidth }) => {
    const { toFormat } = useFormatTime()
    const project = useProject()
    const playback = usePlayback()
    const updatesSystem = project.getSystemOrThrow(UpdatesSystem)
    const root = project.getEntityByTypeOrThrow(Root)
    const previewRange = playback.previewRange

    React.useEffect(
      function autoUpdatePreviewRangeDuration() {
        return updatesSystem.onUpdate((updates) => {
          if (updates.includes(root.id)) {
            playback.previewRange
              .updateStart(0)
              .updateDuration(root.getComponentOrThrow(DurationComponent).value)
          }
        })
      },
      [updatesSystem, playback]
    )

    const handleStartTimeChange = React.useCallback(
      (time: number) => {
        previewRange.updateStart(toFormat(time))
      },
      [previewRange, toFormat]
    )

    const handleDurationChange = React.useCallback(
      (duration: number) => {
        previewRange.updateDuration(toFormat(duration))
      },
      [previewRange, toFormat]
    )

    const onReset = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation()
        e.preventDefault()
        previewRange.reset()
      },
      [previewRange]
    )

    const startTime = previewRange.start
    const updateStartTime = handleStartTimeChange
    const duration = previewRange.duration
    const updateDuration = handleDurationChange

    const initialStartTime = React.useRef<number>(startTime)
    const initialDuration = React.useRef<number>(duration)

    const [isLeftMoving, setIsLeftMoving] = React.useState(false)
    const [isTrackMoving, setIsTrackMoving] = React.useState(false)
    const [isRightMoving, setIsRightMoving] = React.useState(false)
    // @NOTE: required to properly handle track events
    const isListeningRef = React.useRef(false)
    const {
      offsetX: distance,
      isListening,
      startListen,
    } = useMouseMove({
      onTrigger: () => {
        isListeningRef.current = true
      },
      onFinish: () => {
        isListeningRef.current = false
      },
    })

    const newDuration = useFormattedTime(duration)

    const convertTimeToPixels = (time: number) =>
      timeConverters.convertTimeToPixels({
        projectDuration: projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        time,
      })

    const convertPixelsToTime = (pixels: number) =>
      timeConverters.convertPixelsToTime({
        projectDuration: projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        pixels,
      })

    const handleLeftMove = (offset: number) => {
      const isStart = initialStartTime.current + offset <= 0

      if (isStart) {
        updateStartTime(0)
        updateDuration(initialDuration.current + initialStartTime.current)
        return
      }

      updateStartTime(initialStartTime.current + offset)
      updateDuration(initialDuration.current - offset)
    }

    const handleTrackMove = (offset: number) => {
      const isStart = initialStartTime.current + offset <= 0
      const isEnd =
        initialStartTime.current + initialDuration.current + offset >=
        projectDuration

      if (isStart) {
        updateStartTime(0)
        return
      }

      if (isEnd) {
        updateStartTime(projectDuration - initialDuration.current)
        return
      }

      updateStartTime(initialStartTime.current + offset)
    }

    const handleRightMove = (offset: number) => {
      const isEnd =
        initialStartTime.current + initialDuration.current + offset >=
        projectDuration

      if (isEnd) {
        updateDuration(projectDuration - initialStartTime.current)
        return
      }

      updateDuration(initialDuration.current + offset)
    }

    React.useEffect(() => {
      if (isListeningRef.current === false) {
        return
      }

      if (isLeftMoving) {
        handleLeftMove(convertPixelsToTime(distance))
      }

      if (isTrackMoving) {
        handleTrackMove(convertPixelsToTime(distance))
      }

      if (isRightMoving) {
        handleRightMove(convertPixelsToTime(distance))
      }
    }, [distance, isLeftMoving, isTrackMoving, isRightMoving])

    React.useEffect(() => {
      if (isListening === false) {
        setIsLeftMoving(false)
        setIsTrackMoving(false)
        setIsRightMoving(false)

        initialStartTime.current = startTime
        initialDuration.current = duration
      }
    }, [isListening, startTime, duration])

    const onMouseDownLeft = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isLeftMouseButtonClicked = e.button === 0
        if (isLeftMouseButtonClicked === false) {
          return
        }

        e.stopPropagation()
        e.preventDefault()
        setIsLeftMoving(true)
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const onMouseDownMiddle = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isLeftMouseButtonClicked = e.button === 0
        if (isLeftMouseButtonClicked === false) {
          return
        }

        e.stopPropagation()
        e.preventDefault()
        setIsTrackMoving(true)
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const onMouseDownRight = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const isLeftMouseButtonClicked = e.button === 0
        if (isLeftMouseButtonClicked === false) {
          return
        }

        e.stopPropagation()
        e.preventDefault()
        setIsRightMoving(true)
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const left = convertTimeToPixels(startTime)
    const width = convertTimeToPixels(duration)

    return (
      <div className={styles.container}>
        <div
          className={styles.content}
          style={{
            left,
          }}
        >
          <button
            type="button"
            // @NOTE: required to calculate selection in tracks
            data-model-type="preview-range"
            className={classNames(styles.handler, styles['handler-left'])}
            onMouseDown={onMouseDownLeft}
          >
            <span />
          </button>

          <button
            type="button"
            // @NOTE: required to calculate selection in tracks
            data-model-type="preview-range"
            className={styles.track}
            style={{ width }}
            onMouseDown={onMouseDownMiddle}
            onDoubleClick={onReset}
          >
            <span className={styles['track-text']}>
              {newDuration.formatted}
            </span>
          </button>

          <button
            type="button"
            // @NOTE: required to calculate selection in tracks
            data-model-type="preview-range"
            className={classNames(styles.handler, styles['handler-right'])}
            onMouseDown={onMouseDownRight}
          >
            <span />
          </button>
        </div>
      </div>
    )
  }
)
