import { NestedMenu, buttons, icons } from '@aninix/app-design-system'
import { TimeFormat, useSettings } from '@aninix/core'
import { Popover } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

// TODO: move to common
function uppercaseFirstLetter(string: string): string {
  return `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`
}

export interface IProps {}
export const TimeFormatSwitcher: React.FCC<IProps> = observer(() => {
  const user = useSettings()
  const popoverIconRef = React.useRef<any>(null)
  const [isExpanded, setIsExpanded] = React.useState(false)
  const expand = React.useCallback(() => setIsExpanded(true), [])
  const collapse = React.useCallback(() => setIsExpanded(false), [])

  const iconButtonSize = React.useMemo(() => ({ width: 16, height: 16 }), [])
  const expandIconSize = React.useMemo(() => ({ x: 8, y: 8 }), [])

  return (
    <>
      <div ref={popoverIconRef}>
        <buttons.Icon btnSize={iconButtonSize} onClick={expand}>
          <icons.Expand size={expandIconSize} />
        </buttons.Icon>
      </div>

      <Popover
        open={isExpanded}
        onClose={collapse}
        anchorEl={popoverIconRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <NestedMenu
          width="120px"
          menuItems={[
            R.values(TimeFormat).map((item) => ({
              content: uppercaseFirstLetter(item),
              onClick: () => {
                user.updateTimeFormat(item)
                collapse()
              },
            })),
          ]}
        />
      </Popover>
    </>
  )
})
