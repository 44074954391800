import classnames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

interface BaseProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  className?: string
  disabled?: boolean
  withBorder?: boolean
  onEnterPress?: () => void
  onEscPress?: () => void
  onKeyDown?: (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
  onBlur?: () => void
  size?: 'small' | 'medium'
  ref?: any
  icon?: React.ReactNode
}

export type IProps = BaseProps
export const Input: React.FCC<IProps> = React.forwardRef<any, IProps>(
  (
    {
      value,
      onChange,
      placeholder,
      className,
      withBorder,
      onEnterPress,
      onEscPress,
      onKeyDown,
      onFocus,
      onBlur,
      size = 'medium',
      icon,
    },
    ref
  ) => {
    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(e.target.value)
      },
      [onChange]
    )

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const isEnter = e.key.toLowerCase() === 'enter'
        const isEsc = e.key.toLowerCase() === 'escape'

        if (isEnter && onEnterPress != null) {
          onEnterPress()
        }

        if (isEsc && onEscPress != null) {
          onEscPress()
        }

        if (onKeyDown) {
          onKeyDown(e)
        }
      },
      [onEnterPress, onEscPress, onKeyDown]
    )

    return (
      <div className={styles.container}>
        <input
          ref={ref}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={classnames(styles.input, className, {
            [styles.border]: withBorder,
            [styles.small]: size === 'small',
            [styles.medium]: size === 'medium',
            [styles['with-icon']]: icon != null,
          })}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {icon != null && <div className={styles.icon}>{icon}</div>}
      </div>
    )
  }
)
