import {
  Helper as HelperComponent,
  type HelperProps,
} from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { config } from '../../config'

const version = config.version
function redirect(url: string) {
  window.open(url, '_blank')
}
const groups: HelperProps['groups'] = [
  [
    {
      url: '#',
      onClick: () => {
        redirect('https://www.aninix.com/releases')
      },
      title: 'Release notes',
    },
    {
      url: '#',
      onClick: () => {
        redirect('https://www.aninix.com/wiki/hotkeys')
      },
      title: 'Keyboard hotkeys',
    },
  ],
  [
    {
      url: '#',
      onClick: () => {
        redirect('https://discord.gg/qf4A396qey')
      },
      title: '👾 Discord',
    },
    {
      url: '#',
      onClick: () => {
        redirect('https://www.youtube.com/channel/UCIOURq8ExveSHjjiMAAz2SQ')
      },
      title: '🎥 Youtube',
    },
    {
      url: '#',
      onClick: () => {
        redirect('https://www.aninix.com')
      },
      title: '🌎 Website',
    },
  ],
  [
    {
      url: '#',
      onClick: () => {
        redirect('mailto:info@aninix.com')
      },
      title: 'Contact support',
    },
  ],
  [
    {
      url: '#',
      onClick: () => {
        redirect(`https://aninix.com/releases/v${version.replaceAll('.', '-')}`)
      },
      title: `Version: ${version}`,
    },
  ],
]

export interface IProps {}
export const Helper: React.FCC<IProps> = observer(() => {
  return <HelperComponent groups={groups} />
})
