import { ExportPreset, Project } from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { useEntities, usePlayback } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Render } from '../../export/render'

export interface IProps {
  project: Project
}
export const Export: React.FCC<IProps> = observer(({ project }) => {
  const exportPresets = project.getEntitiesByType(ExportPreset)
  useEntities(exportPresets)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const playback = usePlayback()
  const analytics = useAnalytics()
  const [isExporting, setIsExporting] = React.useState(false)

  const openModal = React.useCallback(() => {
    playback.pause()

    if (exportPresets.length === 0) {
      project.createEntity(ExportPreset)
    }

    analytics.track({
      eventName: AnalyticsEvent.ExportPopupViewed,
    })
    setIsExporting(true)
  }, [analytics, exportPresets])

  const closeModal = React.useCallback(() => {
    setIsExporting(false)
  }, [])

  return (
    <div ref={containerRef} style={{ width: 'max-content' }}>
      <button
        onClick={openModal}
        className="group flex h-8 cursor-default items-center justify-center rounded-lg bg-accent transition-transform duration-150 hover:scale-[1.02]"
      >
        <p className="px-3 text-xs font-medium text-white">Export</p>
      </button>

      {/* @NOTE: inserted here to avoid props drilling with containerRef */}
      {isExporting && (
        <Render
          anchorEl={containerRef.current}
          onClose={closeModal}
          project={project}
        />
      )}
    </div>
  )
})
