import { icons } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { featureFlags } from '@aninix/core'
import * as React from 'react'
import { hotkeysLabels } from '../../../../defaults'
import { IMenuInteractor } from './interactor'
import { MenuViewModal } from './view-modal'

export interface IProps {
  interactor: IMenuInteractor
}
export const MenuRouter: React.FCC<IProps> = ({ interactor }) => {
  const menuContainerRef = React.useRef<HTMLDivElement>(null)
  const emptyFn = React.useCallback(() => {}, [])

  const toolIconSize = React.useMemo(
    () => ({
      x: 36,
      y: 36,
    }),
    []
  )

  const mainSection = React.useMemo(
    () => ({
      content: 'Open dashboard',
      onClick: interactor.goToDashboard,
    }),
    [interactor]
  )

  const setCoverSection = React.useMemo(
    () => ({
      content: 'Set current frame as cover',
      onClick: interactor.setCurrentCoverTime,
    }),
    [interactor]
  )

  const fileSection = React.useMemo(
    () => ({
      content: 'File',
      onClick: emptyFn,
      rightPart: (
        <div className="translate-x-2">
          <icons.MenuChevron />
        </div>
      ),
      children: [
        [
          featureFlags.importSvg && {
            content: 'Import svg file',
            onClick: interactor.importSvg,
          },
          {
            content: 'Show version history',
            onClick: interactor.versionHistory,
          },
        ].filter(Boolean),
      ],
    }),
    [interactor, emptyFn]
  )

  const editSection = React.useMemo(
    () => ({
      content: 'Edit',
      onClick: emptyFn,
      rightPart: (
        <div className="translate-x-2">
          <icons.MenuChevron />
        </div>
      ),
      children: [
        [
          {
            content: 'Undo',
            onClick: interactor.undo,
            disabled: interactor.canUndo === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'Z']} />
              </p>
            ),
          },
          {
            content: 'Redo',
            onClick: interactor.redo,
            disabled: interactor.canRedo === false,
            rightPart: (
              <p>
                <HotkeyCombination
                  keys={[hotkeysLabels().ctrl, hotkeysLabels().shift, 'Z']}
                />
              </p>
            ),
          },
        ],
        [
          {
            content: 'Flip horizontally',
            onClick: interactor.flipHorizontally,
            disabled: interactor.canFlip === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().shift, 'H']} />
              </p>
            ),
          },
          {
            content: 'Flip vertically',
            onClick: interactor.flipVertically,
            disabled: interactor.canFlip === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().shift, 'V']} />
              </p>
            ),
          },
        ],
        [
          {
            content: 'Cut',
            onClick: interactor.cut,
            disabled: interactor.canCut === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'X']} />
              </p>
            ),
          },
          {
            content: 'Copy',
            onClick: interactor.copy,
            disabled: interactor.canCopy === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'C']} />
              </p>
            ),
          },
          {
            content: 'Paste',
            onClick: interactor.paste,
            disabled: interactor.canPaste === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'V']} />
              </p>
            ),
          },
          {
            content: 'Delete',
            onClick: interactor.delete,
            disabled: interactor.canDelete === false,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().delete]} />
              </p>
            ),
          },
        ],
        [
          {
            content: 'Select all',
            onClick: interactor.selectAll,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'A']} />
              </p>
            ),
          },
          {
            content: 'Deselect all',
            onClick: interactor.deselectAll,
            rightPart: (
              <p>
                <HotkeyCombination
                  keys={[hotkeysLabels().ctrl, hotkeysLabels().shift, 'A']}
                />
              </p>
            ),
          },
        ],
        [
          {
            content: 'Move down',
            onClick: interactor.moveUp,
            disabled: !interactor.canMoveUp,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, '[']} />
              </p>
            ),
          },
          {
            content: 'Move up',
            onClick: interactor.moveDown,
            disabled: !interactor.canMoveDown,
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, ']']} />
              </p>
            ),
          },
        ],
        // [
        //   {
        //     content: 'Duplicate',
        //     onClick: emptyFn,
        //     rightPart: 'Hot key',
        //   },
        // ],
      ],
    }),
    [interactor, emptyFn]
  )

  const viewSection = React.useMemo(
    () => ({
      content: 'View',
      onClick: emptyFn,
      rightPart: (
        <div className="translate-x-2">
          <icons.MenuChevron />
        </div>
      ),
      children: [
        [
          {
            content: 'Zoom in',
            onClick: interactor.zoomIn,
            rightPart: <HotkeyCombination keys={['+']} />,
          },
          {
            content: 'Zoom out',
            onClick: interactor.zoomOut,
            rightPart: <HotkeyCombination keys={['-']} />,
          },
          {
            content: 'Zoom to 100%',
            onClick: interactor.zoomTo100,
            rightPart: '',
          },
          {
            content: 'Zoom to fit',
            onClick: interactor.zoomToFit,
            rightPart: '',
          },
        ],
      ],
    }),
    [interactor, emptyFn]
  )

  const settingsSection = React.useMemo(
    () => ({
      content: 'Settings',
      onClick: emptyFn,
      rightPart: (
        <div className="translate-x-2">
          <icons.MenuChevron />
        </div>
      ),
      children: [
        [
          {
            content: 'Use legacy exporter',
            onClick: interactor.toggleLegacyExporter,
            icon: (
              <icons.Check
                style={{
                  // @ts-ignore
                  '--figma-color-text': interactor.legacyExporter
                    ? 'white'
                    : 'transparent',
                }}
              />
            ),
          },
          {
            content: 'Loop GIFs',
            onClick: interactor.toggleGifInfiniteLoop,
            icon: (
              <icons.Check
                style={{
                  // @ts-ignore
                  '--figma-color-text': interactor.gifInfiniteLoop
                    ? 'white'
                    : 'transparent',
                }}
              />
            ),
          },
        ],
      ],
    }),
    [interactor, emptyFn]
  )

  const helpSection = React.useMemo(
    () => ({
      content: 'Help',
      onClick: emptyFn,
      rightPart: (
        <div className="translate-x-2">
          <icons.MenuChevron />
        </div>
      ),
      children: [
        [
          {
            content: 'Release notes',
            onClick: interactor.openReleaseNotes,
            rightPart: '',
          },
          {
            content: 'Keyboard shortcuts',
            onClick: interactor.openHotkeys,
            rightPart: '',
          },
        ],
        [
          {
            content: '👾 Discord',
            onClick: interactor.openDiscord,
            rightPart: '',
          },
          {
            onClick: interactor.openYoutube,
            content: '🎥 Youtube',
            rightPart: '',
          },
          {
            onClick: interactor.openWebsite,
            content: '🌎 Website',
            rightPart: '',
          },
        ],
        [
          // @TODO: implement
          // {
          //   content: 'Submit feedback',
          //   onClick: emptyFn,
          //   rightPart: '',
          // },
          {
            content: 'Contact support',
            onClick: interactor.contactSupport,
            rightPart: '',
          },
        ],
      ],
    }),
    [interactor, emptyFn]
  )

  const developmentSection = React.useMemo(
    () => ({
      content: 'Development',
      onClick: emptyFn,
      rightPart: (
        <div className="translate-x-2">
          <icons.MenuChevron />
        </div>
      ),
      children: [
        [
          {
            content: 'Download Project File',
            onClick: interactor.downloadProject,
          },
          {
            content: 'Download Project Snapshot File',
            onClick: interactor.downloadProjectSnapshot,
          },
        ],
      ],
    }),
    [interactor, emptyFn]
  )

  const sections = React.useMemo(() => {
    if (interactor.isProduction) {
      return [
        [mainSection],
        [setCoverSection],
        [fileSection, editSection, viewSection, settingsSection],
        [helpSection],
      ]
    }

    return [
      [mainSection],
      [setCoverSection],
      [fileSection, editSection, viewSection, settingsSection],
      [helpSection],
      [developmentSection],
    ]
  }, [
    interactor,
    mainSection,
    editSection,
    viewSection,
    settingsSection,
    helpSection,
    fileSection,
    developmentSection,
  ])

  return (
    <>
      <div ref={menuContainerRef} style={{ width: 'min-content' }}>
        {/* <MenuView onClick={interactor.openMenu} toolIconSize={toolIconSize} /> */}
        <MenuViewModal
          isOpen={true}
          onClose={interactor.closeMenu}
          anchorEl={menuContainerRef.current}
          sections={sections}
        />
      </div>
    </>
  )
}
