import { FullPageLoader } from '@aninix/app-design-system'
import {
  IImageProvider,
  ImagesStore,
  ImagesStoreProvider,
  Playback,
  Preview as PreviewComponent,
  ProjectProvider,
  Settings,
  SettingsProvider,
  Timeline,
  ToolsProvider,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import {
  EntityType,
  EntityTypeComponent,
  HashComponent,
  Project,
  createComponentsProvider,
  createEntitiesProvider,
  createSystemsProvider,
} from '@aninix-inc/model'
import { syncProjectWithRemote } from '@aninix/api'
import { ImageProvider } from '../inspector'
import { NotFoundView } from '../not-found-view'

const user = new Settings()

export interface IProps {}
export const Preview: React.FCC<IProps> = observer(() => {
  const { projectId } = useParams()

  const [playback, setPlayback] = React.useState<Playback>()
  const [timeline, setTimeline] = React.useState<Timeline>()
  const [imagesStore, setImagesStore] = React.useState<ImagesStore>()
  const [isLoading, setIsLoading] = React.useState(true)
  const [imageProvider, setImageProvider] = React.useState<
    IImageProvider | undefined
  >(undefined)
  const [localProject, setLocalProject] = React.useState<Project | undefined>(
    undefined
  )

  React.useEffect(() => {
    if (projectId == null) {
      return
    }

    const project = new Project({
      id: projectId,
      componentsProvider: createComponentsProvider(),
      entitiesProvider: createEntitiesProvider(),
      systemsProvider: createSystemsProvider(),
    })
    setLocalProject(project)

    syncProjectWithRemote(project)
      .then(() => {
        setImageProvider(new ImageProvider())
      })
      .catch((err) => {
        console.error('Error happen while fetching project', err)
        window.location.replace('/404')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  React.useEffect(() => {
    if (imagesStore == null || isLoading || localProject == null) {
      return
    }

    // @NOTE: trigger store to load all images
    const imagePaints = localProject
      .getEntitiesByPredicate(
        (e) =>
          e.getComponentOrThrow(EntityTypeComponent).value ===
            EntityType.Paint && e.hasComponent(HashComponent)
      )
      .map((paint) => paint.getComponentOrThrow(HashComponent).value)
    imagesStore
      .clean()
      .loadImagesByHashes(imagePaints.map((paint: any) => paint.hash))
  }, [imagesStore, isLoading, localProject])

  React.useEffect(() => {
    if (isLoading === false) {
      setPlayback(new Playback({ project: localProject!, user }))
      setTimeline(new Timeline({ project: localProject! }))
      setImagesStore(new ImagesStore(imageProvider!))
    }
  }, [isLoading, localProject, imageProvider])

  if (isLoading || R.any(R.isNil, [playback, timeline, imagesStore])) {
    return <FullPageLoader />
  }

  return (
    <ProjectProvider project={localProject!}>
      <ImagesStoreProvider store={imagesStore!}>
        <ToolsProvider>
          <SettingsProvider>
            <div className="h-screen w-screen">
              {isLoading === false && localProject == null ? (
                <NotFoundView />
              ) : (
                <PreviewComponent project={localProject!} />
              )}
            </div>
          </SettingsProvider>
        </ToolsProvider>
      </ImagesStoreProvider>
    </ProjectProvider>
  )
})
