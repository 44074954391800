import { Component, commitUndo } from '@aninix-inc/model'
import { RGBA } from '@aninix-inc/model/legacy'
import { PopupHeader, SolidColorPicker } from '@aninix/app-design-system'
import { Card, Popover } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValueRgba } from '../../../../../hooks/value-controllers/rgba'
import { InputHex } from '../../input-hex'
import * as styles from './index.scss'

export interface IProps {
  components: Component[]
  time?: number
}
export const RgbaValue: React.FCC<IProps> = observer(({ components, time }) => {
  const rgbaPopupAnchorRef = React.useRef<any>(null)
  const [isRgbaModalVisible, setIsRgbaPopupVisible] = React.useState(false)
  const { value, updateValue } = useValueRgba({ components, time })

  const project = components[0].entity.getProjectOrThrow()
  const onEndChange = React.useCallback(() => {
    commitUndo(project)
  }, [components])

  // @TODO: add support of mixed values
  return (
    <div ref={rgbaPopupAnchorRef}>
      <InputHex
        color={value as RGBA}
        onChange={updateValue}
        onEndChange={onEndChange}
        onPreviewClick={() => {
          setIsRgbaPopupVisible(true)
        }}
      />

      <Popover
        open={isRgbaModalVisible}
        anchorEl={rgbaPopupAnchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          onEndChange()
          setIsRgbaPopupVisible(false)
        }}
        disableRestoreFocus
      >
        <Card className={styles.wrapper}>
          <PopupHeader
            onClose={() => {
              onEndChange()
              setIsRgbaPopupVisible(false)
            }}
          >
            Solid
          </PopupHeader>

          <SolidColorPicker
            color={value as RGBA}
            onEndChange={onEndChange}
            onChange={updateValue}
          />
        </Card>
      </Popover>
    </div>
  )
})
