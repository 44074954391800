import {
  ColorStop,
  ColorStopsRelationsAspect,
  Entity,
  EntityType,
  getAbsoluteTransformMatrix,
  getSortedKeyframes,
  GradientTransformComponent,
  LinearGradientPaint,
  ParentRelationAspect,
  ProgressComponent,
  RadialGradientPaint,
  RGBA,
  RgbaValueComponent,
  SelectionSystem,
  setAnimatableValue,
  UndoRedoSystem,
  UpdatesSystem,
} from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import {
  KeyModificator,
  useImagesStore,
  usePlayback,
  useProject,
  useSession,
  useViewport,
} from '@aninix/core/stores'
import { useEntities, useSystem } from '@aninix/core/updates'
import { getOutlineBox, OutlineBox } from '@aninix/core/utils'
import { createCursor, removeCursor } from '@aninix/core/utils/cursor'
import { observer } from 'mobx-react-lite'
import * as paper from 'paper'
import React, { useRef, useState } from 'react'
import { Snapping, SnappingItem, snapPoint } from '../snapping'
import { ColorStopHandle } from './color-stop-handle'
import {
  getLinearGradientFromMatrix,
  getMatrixFromLinearGradient,
} from './linear-utils'
import { PointHandle } from './point-handle'
import { ProgressHandle } from './progress-handle'
import {
  getMatrixFromRadialGradient,
  getRadialGradientFromMatrix,
  getRadiusPointByVector,
  getRadiusPointByVectorAndOffset,
} from './radial-utils'
import { RotationHandle } from './rotation-handle'
import {
  getAngle,
  getClientPoint,
  getColorAtProgress,
  getProgressByClientCoords,
} from './utils'

export enum Handle {
  START_POINT = 'START_POINT',
  END_POINT = 'END_POINT',
  RADIUS = 'RADIUS',
  ROTATE = 'ROTATE',
  COLOR_STOP = 'COLOR_STOP',
}

export const GradientTransform: React.FCC = observer(() => {
  const session = useSession()
  const playback = usePlayback()
  const images = useImagesStore()
  const viewport = useViewport()
  const project = useProject()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)
  useSystem(selection)

  const { isListening, startListen, offsetX, offsetY } = useMouseMove()

  const paintMatrix = React.useRef<paper.Matrix | null>(null)
  const gradientPaint = React.useRef<
    LinearGradientPaint | RadialGradientPaint | null
  >(null)

  const [paintEntity, setPaintEntity] = React.useState<Entity | null>(null)
  const [selectedColorStop, setSelectedColorStop] =
    React.useState<Entity | null>(null)
  const [outlineBox, setOutlineBox] = React.useState<OutlineBox | null>(null)
  const [size, setSize] = React.useState<paper.Size | null>(null)

  const [colorStops, setColorStops] = React.useState<Entity[]>([])

  const key = useEntities(
    [paintEntity, selectedColorStop].filter((e) => e !== null)
  )

  const [startPoint, setStartPoint] = React.useState<paper.Point | null>(null)
  const [endPoint, setEndPoint] = React.useState<paper.Point | null>(null)
  const [radiusPoint, setRadiusPoint] = React.useState<paper.Point | null>(null)
  const [angle, setAngle] = React.useState<number | null>(null)
  const [snappingItems, setSnappingItems] = React.useState<SnappingItem[]>([])

  const dragStartState = useRef<{
    startPoint: paper.Point
    endPoint: paper.Point
    radiusPoint: paper.Point | null
    angle: number
  } | null>(null)

  const dragStartPoint = useRef<paper.Point | null>(null)
  const initColorStopProgress = useRef<number | null>(null)
  const cursorOffset = useRef<number | null>(null)
  const [currentHandle, setCurrentHandle] = useState<Handle | null>(null)

  const [wasTriggered, setWasTriggered] = useState(false)
  const [floatingColorStop, setFloatingColorStop] = useState<{
    color: RGBA
    progress: number
  } | null>(null)

  React.useEffect(() => {
    const updateSelection = () => {
      const selectedGradients = selection
        .getEntitiesByEntityType(EntityType.Paint)
        .filter(
          (p) =>
            p instanceof LinearGradientPaint || p instanceof RadialGradientPaint
        ) as (LinearGradientPaint | RadialGradientPaint)[]

      const selectedGradient = selectedGradients[0]

      if (!selectedGradient) {
        setPaintEntity(null)
        setOutlineBox(null)
        setStartPoint(null)
        setEndPoint(null)
        setRadiusPoint(null)
        setAngle(null)
        return
      }

      const paint = selectedGradient
        .getAspectOrThrow(ParentRelationAspect)
        .getParentEntityOrThrow()

      gradientPaint.current = selectedGradient

      setColorStops(
        selectedGradient
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
      )
      setPaintEntity(paint)
      setSelectedColorStop(
        selection.getEntitiesByEntityType(EntityType.ColorStop)?.[0] ?? null
      )
    }

    updateSelection()
    return selection.onSelectionUpdate(updateSelection)
  }, [selection])

  React.useEffect(() => {
    if (!currentHandle && paintEntity && gradientPaint.current) {
      const box = getOutlineBox(paintEntity, playback.time)
      setOutlineBox(box)

      const size = new paper.Size(box.width, box.height)
      setSize(size)

      paintMatrix.current = new paper.Matrix(
        getAbsoluteTransformMatrix({
          entity: paintEntity,
          time: playback.time,
        })
      )

      const transfromMatirx = gradientPaint.current.getComponentOrThrow(
        GradientTransformComponent
      ).value

      if (gradientPaint.current instanceof LinearGradientPaint) {
        const { startPoint, endPoint, angle } = getLinearGradientFromMatrix(
          transfromMatirx,
          paintMatrix.current,
          size
        )

        setStartPoint(startPoint)
        setEndPoint(endPoint)
        setAngle(angle)
      }

      if (gradientPaint.current instanceof RadialGradientPaint) {
        const { center, radiusX, radiusY, angle } = getRadialGradientFromMatrix(
          transfromMatirx,
          paintMatrix.current,
          size
        )

        setStartPoint(center)
        setEndPoint(radiusX)
        setRadiusPoint(radiusY)
        setAngle(angle)
      }
    }
  }, [playback.time, currentHandle, paintEntity, key])

  const applyTransform = React.useCallback(
    (
      startPoint: paper.Point,
      endPoint: paper.Point,
      radiusPoint?: paper.Point
    ) => {
      updates.batch(() => {
        if (!paintMatrix.current || !size) return

        if (gradientPaint.current instanceof RadialGradientPaint) {
          if (!radiusPoint) return
          gradientPaint.current.updateComponent(
            GradientTransformComponent,
            getMatrixFromRadialGradient(
              startPoint,
              endPoint,
              radiusPoint,
              paintMatrix.current,
              size
            )
          )
        }

        if (gradientPaint.current instanceof LinearGradientPaint) {
          gradientPaint.current.updateComponent(
            GradientTransformComponent,
            getMatrixFromLinearGradient(
              startPoint,
              endPoint,
              paintMatrix.current,
              size
            )
          )
        }
      })
    },
    [outlineBox, updates]
  )

  const commitTransform = React.useCallback(() => {
    if (currentHandle === Handle.COLOR_STOP && selectedColorStop) {
      updates.batch(() => {
        const progress =
          selectedColorStop.getComponentOrThrow(ProgressComponent)
        const keyframes = getSortedKeyframes(progress)
        if (keyframes.length)
          setAnimatableValue(progress, progress.value, playback.time, true)
      })
    }

    setCurrentHandle(null)
    setSnappingItems([])
    setWasTriggered(false)
    undoRedo.commitUndo()
  }, [undoRedo, colorStops, selectedColorStop, playback.time, currentHandle])

  const moveRadialStartPoint = React.useCallback(
    (x: number, y: number) => {
      if (!dragStartState.current || !outlineBox || !paintMatrix.current) return
      const { startPoint, endPoint, radiusPoint } = dragStartState.current
      if (!radiusPoint) return

      let newStartPoint = startPoint.add(new paper.Point(x, y))
      const { snappedPoint, items } = snapPoint(
        newStartPoint,
        outlineBox,
        viewport.zoom
      )
      newStartPoint = snappedPoint

      const newRadiusPoint = getRadiusPointByVector(
        { start: startPoint, end: endPoint },
        { start: newStartPoint, end: endPoint },
        radiusPoint
      )

      const angle = getAngle(newStartPoint, endPoint)

      setSnappingItems(items)
      setRadiusPoint(newRadiusPoint)
      setAngle(angle)
      setStartPoint(newStartPoint)
      applyTransform(newStartPoint, endPoint, newRadiusPoint)
    },
    [applyTransform, outlineBox, viewport.zoom]
  )

  const moveRadialEndPoint = React.useCallback(
    (x: number, y: number) => {
      if (!dragStartState.current || !outlineBox || !paintMatrix.current) return
      const { startPoint, endPoint, radiusPoint } = dragStartState.current
      if (!radiusPoint) return

      let newEndPoint = endPoint.add(new paper.Point(x, y))
      const { snappedPoint, items } = snapPoint(
        newEndPoint,
        outlineBox,
        viewport.zoom
      )
      newEndPoint = snappedPoint

      const newRadiusPoint = getRadiusPointByVector(
        { start: startPoint, end: endPoint },
        { start: startPoint, end: newEndPoint },
        radiusPoint
      )

      const angle = getAngle(startPoint, newEndPoint)

      setSnappingItems(items)
      setRadiusPoint(newRadiusPoint)
      setAngle(angle)
      setEndPoint(newEndPoint)
      applyTransform(startPoint, newEndPoint, newRadiusPoint)
    },
    [applyTransform, outlineBox, viewport.zoom]
  )

  const moveRadialRadius = React.useCallback(
    (x: number, y: number) => {
      if (!dragStartState.current || !paintMatrix.current) return
      const { startPoint, endPoint, radiusPoint } = dragStartState.current
      if (!radiusPoint) return

      const offset = new paper.Point(x, y)

      const newRadiusPoint = getRadiusPointByVectorAndOffset(
        offset,
        startPoint,
        endPoint,
        radiusPoint
      )

      setStartPoint(startPoint)
      setEndPoint(endPoint)
      setRadiusPoint(newRadiusPoint)
      applyTransform(startPoint, endPoint, newRadiusPoint)
    },
    [applyTransform, viewport.zoom]
  )

  const rotateRadial = React.useCallback(
    (x: number, y: number, isShift: boolean) => {
      if (!dragStartState.current || !dragStartPoint.current) return
      const { startPoint, endPoint, radiusPoint, angle } =
        dragStartState.current

      if (!radiusPoint) return

      const initialVector = dragStartPoint.current.subtract(startPoint)
      const currentVector = dragStartPoint.current
        .add(new paper.Point(x, y))
        .subtract(startPoint)

      let deltaAngle = currentVector.angle - initialVector.angle
      deltaAngle = ((deltaAngle + 180) % 360) - 180

      let newAngle = angle + deltaAngle
      newAngle = ((newAngle + 180) % 360) - 180

      if (isShift) {
        newAngle = Math.round(newAngle / 45) * 45
        deltaAngle = newAngle - angle
      }

      const xVector = radiusPoint.subtract(startPoint)
      const yVector = endPoint.subtract(startPoint)

      const newRadiusXPoint = startPoint.add(
        xVector.rotate(deltaAngle, new paper.Point(0, 0))
      )
      const newEndPoint = startPoint.add(
        yVector.rotate(deltaAngle, new paper.Point(0, 0))
      )

      setAngle(newAngle)
      setRadiusPoint(newRadiusXPoint)
      setEndPoint(newEndPoint)
      applyTransform(startPoint, newEndPoint, newRadiusXPoint)
    },
    [applyTransform]
  )

  const moveLinearStartPoint = React.useCallback(
    (x: number, y: number) => {
      if (!dragStartState.current || !outlineBox || !paintMatrix.current) return
      const { startPoint, endPoint } = dragStartState.current
      let newStartPoint = startPoint.add(new paper.Point(x, y))
      const { snappedPoint, items } = snapPoint(
        newStartPoint,
        outlineBox,
        viewport.zoom
      )
      newStartPoint = snappedPoint
      const newAngle = getAngle(newStartPoint, endPoint)
      setSnappingItems(items)
      setAngle(newAngle)
      setStartPoint(newStartPoint)
      applyTransform(newStartPoint, endPoint)
    },
    [applyTransform, outlineBox, viewport.zoom]
  )

  const moveLinearEndPoint = React.useCallback(
    (x: number, y: number) => {
      if (!dragStartState.current || !outlineBox || !paintMatrix.current) return
      const { startPoint, endPoint } = dragStartState.current
      let newEndPoint = endPoint.add(new paper.Point(x, y))
      const { snappedPoint, items } = snapPoint(
        newEndPoint,
        outlineBox,
        viewport.zoom
      )
      newEndPoint = snappedPoint
      const newAngle = getAngle(startPoint, newEndPoint)
      setSnappingItems(items)
      setAngle(newAngle)
      setEndPoint(newEndPoint)
      applyTransform(startPoint, newEndPoint)
    },
    [applyTransform, outlineBox, viewport.zoom]
  )

  const rotateLinear = React.useCallback(
    (x: number, y: number, isShift: boolean) => {
      if (!dragStartState.current || !dragStartPoint.current) return
      const { startPoint, endPoint, angle } = dragStartState.current

      const center = startPoint.add(endPoint).divide(2)
      const initVector = dragStartPoint.current.subtract(center)
      const currentVector = dragStartPoint.current
        .add(new paper.Point(x, y))
        .subtract(center)

      let deltaAngle = currentVector.angle - initVector.angle
      deltaAngle = ((deltaAngle + 180) % 360) - 180

      let newAngle = angle + deltaAngle
      newAngle = ((newAngle + 180) % 360) - 180
      if (isShift) {
        newAngle = Math.round(newAngle / 45) * 45
        deltaAngle = newAngle - angle
      }

      const vector = endPoint.subtract(startPoint)
      const rotatedVector = vector.rotate(deltaAngle, new paper.Point(0, 0))
      const newStartPoint = center.subtract(rotatedVector.divide(2))
      const newEndPoint = center.add(rotatedVector.divide(2))

      setAngle(newAngle)
      setStartPoint(newStartPoint)
      setEndPoint(newEndPoint)
      applyTransform(newStartPoint, newEndPoint)
    },
    [applyTransform]
  )

  const moveColorStop = React.useCallback(
    (x: number, y: number) => {
      if (initColorStopProgress.current === null) return
      if (!startPoint || !endPoint) return

      const vector = endPoint.subtract(startPoint)
      const vectorLength = vector.length

      const cursorPoint = startPoint
        .add(vector.multiply(initColorStopProgress.current))
        .add(new paper.Point(x, y))

      const pointVector = cursorPoint.subtract(startPoint)
      const dotProduct = vector.dot(pointVector)

      const clampedDotProduct = Math.max(
        0,
        Math.min(dotProduct, vectorLength * vectorLength)
      )
      const projection = vector.multiply(
        clampedDotProduct / (vectorLength * vectorLength)
      )

      let progress = projection.length / vectorLength
      progress = Math.max(0, Math.min(1, progress))

      updates.batch(() => {
        if (!selectedColorStop) return
        selectedColorStop.updateComponent(ProgressComponent, progress)
      })
    },
    [updates, startPoint, endPoint, angle, selectedColorStop]
  )

  React.useEffect(() => {
    if (!isListening && currentHandle) {
      if (wasTriggered) commitTransform()
      else {
        setCurrentHandle(null)
        clearCursor()
      }
      return
    }

    if (!wasTriggered) {
      setWasTriggered(Math.max(Math.abs(offsetX), Math.abs(offsetY)) > 3)
      return
    }

    if (!currentHandle || !gradientPaint.current) return

    const x = offsetX / viewport.zoom
    const y = offsetY / viewport.zoom
    const isShfit = session.keyModificators.includes(KeyModificator.Shift)

    if (currentHandle === Handle.ROTATE) setCursor('rotate')
    if (currentHandle === Handle.RADIUS) setCursor('move')
    if (currentHandle.includes('POINT')) setCursor('move')
    if (currentHandle === Handle.COLOR_STOP) setCursor('move')

    if (gradientPaint.current instanceof RadialGradientPaint) {
      if (currentHandle === Handle.START_POINT) moveRadialStartPoint(x, y)
      if (currentHandle === Handle.END_POINT) moveRadialEndPoint(x, y)
      if (currentHandle === Handle.ROTATE) rotateRadial(x, y, isShfit)
      if (currentHandle === Handle.RADIUS) moveRadialRadius(x, y)
    }

    if (gradientPaint.current instanceof LinearGradientPaint) {
      if (currentHandle === Handle.START_POINT) moveLinearStartPoint(x, y)
      if (currentHandle === Handle.END_POINT) moveLinearEndPoint(x, y)
      if (currentHandle === Handle.ROTATE) rotateLinear(x, y, isShfit)
    }

    if (currentHandle === Handle.COLOR_STOP) moveColorStop(x, y)
  }, [
    isListening,
    currentHandle,
    wasTriggered,
    offsetX,
    offsetY,
    viewport.zoom,
    session.keyModificators,
  ])

  const setCursor = React.useCallback(
    (cursor: Parameters<typeof createCursor>[0]) => {
      const rotation = (angle ?? 0) + (cursorOffset.current ?? 0)
      createCursor(cursor, rotation, document.getElementById('stage'))
    },
    [angle]
  )

  const clearCursor = React.useCallback(() => {
    cursorOffset.current = null
    removeCursor(document.getElementById('stage'))
  }, [])

  const setDragStartState = React.useCallback(() => {
    if (!startPoint || !endPoint || angle === null) return
    dragStartState.current = {
      startPoint,
      endPoint,
      radiusPoint,
      angle,
    }
  }, [startPoint, endPoint, radiusPoint, angle])

  const onMovePoint = React.useCallback(
    (e: React.MouseEvent<SVGElement>, handle: Handle) => {
      e.preventDefault()
      e.stopPropagation()
      setCurrentHandle(handle)
      setDragStartState()
      startListen(e)
    },
    [startListen, setDragStartState]
  )

  const onMoveColorStop = React.useCallback(
    (e: React.MouseEvent<SVGElement>, progress: number) => {
      e.stopPropagation()
      e.preventDefault()

      const id = e.currentTarget.dataset.stopId
      if (!id) return

      startListen(e)
      selectColorStop(id)
      setCurrentHandle(Handle.COLOR_STOP)
      initColorStopProgress.current = progress
    },
    [startListen]
  )

  const onRotate = React.useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      e.stopPropagation()
      e.preventDefault()
      startListen(e)
      setCurrentHandle(Handle.ROTATE)
      setDragStartState()
      cursorOffset.current = Number(e.currentTarget.dataset.cursorOffset) ?? 0
      dragStartPoint.current = getClientPoint(e)
    },
    [startListen, setDragStartState]
  )

  const onRotateHandleMouseMove = React.useCallback(
    (e: React.MouseEvent<SVGElement>) => {
      if (currentHandle) return
      cursorOffset.current = Number(e.currentTarget.dataset.cursorOffset) ?? 0
      setCursor('rotate')
    },
    [setCursor, currentHandle]
  )
  const selectColorStop = React.useCallback(
    (id: string) => {
      updates.batch(() => {
        const stopIds = selection
          .getEntitiesByEntityType(EntityType.ColorStop)
          .map((s) => s.id)
        selection.deselect(stopIds)
        selection.select([id])
      })
      undoRedo.commitUndo()
    },
    [updates, selection, undoRedo]
  )

  const createColorStop = React.useCallback(
    (progress: number) => {
      updates.batch(() => {
        if (!gradientPaint.current) return

        const color = getColorAtProgress(progress, colorStops)
        const stop = project.createEntity(ColorStop, { progress, color })

        gradientPaint.current
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .addChild(stop)

        selectColorStop(stop.id)
        setFloatingColorStop(null)
      })
      undoRedo.commitUndo()
    },
    [updates, project, undoRedo, gradientPaint, selectColorStop, colorStops]
  )

  const createFloatingColorStop = React.useCallback(
    (e: React.MouseEvent<SVGLineElement>, create?: boolean) => {
      if (currentHandle || !colorStops.length || !startPoint || !endPoint)
        return

      e.preventDefault()
      e.stopPropagation()

      const progress = getProgressByClientCoords(e, startPoint, endPoint)
      if (create) {
        createColorStop(progress)
        return
      }

      const color = getColorAtProgress(progress, colorStops)
      setFloatingColorStop({ color, progress })
    },
    [colorStops, currentHandle, startPoint, endPoint, createColorStop]
  )
  const renderColorStop = React.useCallback(
    (stop: Entity) => {
      const progress = stop.getComponentOrThrow(ProgressComponent).value
      const color = stop.getComponentOrThrow(RgbaValueComponent).value
      const isSelected = selection.isSelected(stop.id)

      return (
        <ColorStopHandle
          key={stop.id}
          stopId={stop.id}
          startPoint={startPoint}
          endPoint={endPoint}
          progress={progress}
          color={color}
          isSelected={isSelected}
          zoom={viewport.zoom}
          onMouseDown={(e) => onMoveColorStop(e, progress)}
          onMouseMove={() => !currentHandle && setCursor('move')}
          onMouseLeave={() => !currentHandle && clearCursor()}
        />
      )
    },
    [viewport.zoom, selection, key, startPoint, endPoint, currentHandle]
  )

  return (
    <g>
      {floatingColorStop && (
        <ColorStopHandle
          color={floatingColorStop.color}
          progress={floatingColorStop.progress}
          zoom={viewport.zoom}
          startPoint={startPoint}
          endPoint={endPoint}
        />
      )}
      <ProgressHandle
        startPoint={startPoint}
        endPoint={endPoint}
        zoom={viewport.zoom}
        onMouseMove={(e) => {
          createFloatingColorStop(e)
          !currentHandle && setCursor('crosshair')
        }}
        onMouseDown={(e) => createFloatingColorStop(e, true)}
        onMouseLeave={() => {
          setFloatingColorStop(null)
          !currentHandle && clearCursor()
        }}
      />
      <RotationHandle
        point={radiusPoint}
        zoom={viewport.zoom}
        angle={angle}
        onMouseDown={onRotate}
        onMouseMove={onRotateHandleMouseMove}
        onMouseLeave={() => !currentHandle && clearCursor()}
      />
      <RotationHandle
        point={endPoint}
        zoom={viewport.zoom}
        angle={angle}
        onMouseDown={onRotate}
        onMouseMove={onRotateHandleMouseMove}
        onMouseLeave={() => !currentHandle && clearCursor()}
      />
      <RotationHandle
        point={startPoint}
        zoom={viewport.zoom}
        angle={angle}
        onMouseDown={onRotate}
        onMouseMove={onRotateHandleMouseMove}
        onMouseLeave={() => !currentHandle && clearCursor()}
      />
      {colorStops.map(renderColorStop)}
      <PointHandle
        point={startPoint}
        angle={angle}
        zoom={viewport.zoom}
        onMouseMove={() => !currentHandle && setCursor('move')}
        onMouseLeave={() => !currentHandle && clearCursor()}
        onMouseDown={(e) => onMovePoint(e, Handle.START_POINT)}
      />
      <PointHandle
        point={endPoint}
        angle={angle}
        zoom={viewport.zoom}
        onMouseMove={() => !currentHandle && setCursor('move')}
        onMouseLeave={() => !currentHandle && clearCursor()}
        onMouseDown={(e) => onMovePoint(e, Handle.END_POINT)}
      />
      <PointHandle
        point={radiusPoint}
        angle={angle}
        zoom={viewport.zoom}
        onMouseMove={() => !currentHandle && setCursor('move')}
        onMouseLeave={() => !currentHandle && clearCursor()}
        onMouseDown={(e) => onMovePoint(e, Handle.RADIUS)}
      />
      {snappingItems?.map((item, key) => (
        <Snapping key={key} zoom={viewport.zoom} item={item} />
      ))}
    </g>
  )
})
