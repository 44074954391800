import {
  ChildrenExpandedComponent,
  ChildrenRelationsAspect,
  Entity,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  FillsRelationsAspect,
  LayerColor,
  LockedComponent,
  MaskComponent,
  NameComponent,
  NodeColorComponent,
  NodeSoloType,
  NodeType,
  NodeTypeComponent,
  PaintType,
  PaintTypeComponent,
  ParentRelationAspect,
  PropertiesExpandedComponent,
  SoloComponent,
  StrokesRelationsAspect,
  UpdatesSystem,
  VisibleInViewportComponent,
  collapseChildren,
  collapseProperties,
  commitUndo,
  expandChildren,
  expandProperties,
  hasOwnAnimation,
} from '@aninix-inc/model'
import { LockType, VisibilityType } from '@aninix-inc/model/legacy'
import {
  ColorSelector,
  InputTimeline,
  TimelineTrack,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { LayerType } from '@aninix/app-design-system/components/common/icons'
import { useClipboard } from '@aninix/clipboard'
import {
  KeyModificator,
  getSelection,
  nodeColors,
  trimString,
  useEntity,
  useSession,
} from '@aninix/core'
import { useSelection } from '@aninix/editor/modules/selection'
import { clearAnimation } from '@aninix/editor/use-cases/clear-animation'
import { copyPositionKeyframes } from '@aninix/editor/use-cases/copy-position-keyframes'
import { Popover } from '@material-ui/core'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { defaults } from '../../../../defaults'
import { useUi } from '../../../../stores'
import { ContextMenuItem } from '../../../../stores/ui'
import { NodeExpandType } from '../../../../types'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { useLayerDnd } from '../drag-n-drop'
import * as styles from './index.scss'

function getLayerType(layer: Entity): LayerType {
  const type = layer.getComponentOrThrow(NodeTypeComponent).value

  if (
    layer.hasComponent(MaskComponent) &&
    layer.getComponentOrThrow(MaskComponent).value
  ) {
    return LayerType.Mask
  }

  if (
    (layer.hasAspect(FillsRelationsAspect) &&
      R.any(
        (fill) =>
          fill.getComponentOrThrow(PaintTypeComponent).value ===
          PaintType.Image,
        layer.getAspectOrThrow(FillsRelationsAspect).getChildrenList()
      )) ||
    (layer.hasAspect(StrokesRelationsAspect) &&
      R.any(
        (stroke) =>
          stroke.getComponentOrThrow(PaintTypeComponent).value ===
          PaintType.Image,
        layer.getAspectOrThrow(StrokesRelationsAspect).getChildrenList()
      ))
  ) {
    return LayerType.VectorWithImageFill
  }

  if (type === NodeType.Ellipse) {
    return LayerType.Ellipse
  }

  if (type === NodeType.Rectangle) {
    return LayerType.Rectangle
  }

  if (type === NodeType.Polygon || type === NodeType.Star) {
    return LayerType.Polygon
  }

  if (type === NodeType.Frame) {
    return LayerType.Frame
  }

  if (type === NodeType.Instance) {
    return LayerType.Instance
  }

  if (type === NodeType.Group) {
    return LayerType.Group
  }

  if (type === NodeType.Text) {
    return LayerType.Text
  }

  return LayerType.Vector
}

export interface IProps {
  layer: Entity
  indent: number
}
export const Layer: React.FCC<IProps> = ({ layer, indent: providedIndent }) => {
  useEntity(layer)
  const { selection } = useSelection()
  const project = layer.getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const nameRef = React.useRef<HTMLInputElement | null>(null)
  const [isNameFocused, setIsNameFocused] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState(false)
  const [colorSelectionAnchorEl, setColorSelectionAnchorEl] =
    React.useState<HTMLButtonElement | null>(null)

  const iconButtonSize = React.useMemo(() => ({ width: 16, height: 16 }), [])
  const iconSize = React.useMemo(() => ({ x: 16, y: 16 }), [])
  const selectorColors = React.useMemo(
    () =>
      R.values(LayerColor).map((id) => ({
        id,
        name: id.toLowerCase(),
        value: defaults.nodeColors[id],
      })),
    []
  )

  const {
    isEntityDragging,
    isEntityDropTarget,
    isEntityParentOfDropTarget,
    getDropZoneIndent,
    ...dndHandlers
  } = useLayerDnd()

  const isDragging = isEntityDragging(layer)
  const isDropTarget = isEntityDropTarget(layer)
  const isParentOfDropTarget = isEntityParentOfDropTarget(layer)

  // @TODO: move to index because using useStores in interactor is anti-pattern
  const clipboard = useClipboard()
  const session = useSession()
  const uiStore = useUi()

  const type = getLayerType(layer)
  const name = layer.getComponentOrThrow(NameComponent).value
  const [tempName, setTempName] = React.useState(name)

  const soloType = layer.getComponentOrThrow(SoloComponent).value
  const indent = providedIndent - 1
  // const maskColor = nodeColors[node.color as LayerColor]
  const maskColor = 'rgba(0, 0, 0, 0.1)'
  const isRoot = layer.hasComponent(EntryComponent)
  const hasAnimatedProperties = isRoot === false && hasOwnAnimation(layer)
  const solo = layer.getComponentOrThrow(SoloComponent).value
  const isHighlighted = session.buffer === layer.id
  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isLayerSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = R.any(
    (component) => isComponentSelected(component),
    layer.components
  )
  const color = React.useMemo(() => {
    if (isAnyParentSelectedValue || isParentOfDropTarget) {
      return `${
        nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }10`
    }

    if (isComponentSelectedValue) {
      // return `${
      //   nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      // }10`
      return `${
        nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }35`
    }

    if (isLayerSelectedValue || isDragging) {
      return `${
        nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }35`
    }

    return 'transparent'
  }, [
    layer,
    isAnyParentSelectedValue,
    isLayerSelectedValue,
    isComponentSelectedValue,
    isDragging,
    isParentOfDropTarget,
  ])
  const hasChildren = layer.hasAspect(ChildrenRelationsAspect)
    ? layer.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList().length >
      0
    : false
  const visibilityType = layer.getComponentOrThrow(VisibleInViewportComponent)
    .value
    ? VisibilityType.Visible
    : VisibilityType.Hidden
  const lockType = layer.getComponentOrThrow(LockedComponent).value
    ? LockType.Locked
    : LockType.Normal

  /**
   * @todo move to plugins
   */

  const childrenExpanded =
    layer.hasComponent(ChildrenExpandedComponent) &&
    layer.getComponentOrThrow(ChildrenExpandedComponent).value

  const propertiesExpanded =
    layer.hasComponent(PropertiesExpandedComponent) &&
    layer.getComponentOrThrow(PropertiesExpandedComponent).value

  const onExpandChildrenClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      updates.batch(() => {
        const selectedNodes = getSelection(project, EntityType.Node)
        const layersToWork: Entity[] =
          selectedNodes.length === 0 || isLayerSelectedValue === false
            ? [layer]
            : selectedNodes

        // @NOTE: required to make the same state for all selected layers.
        // So layers.forEach((layer) => layer.toggle()) doesn't work for that case.
        if (childrenExpanded) {
          layersToWork.forEach((_layer) => {
            collapseChildren(_layer)
            collapseProperties(_layer)
          })
          return
        }

        layersToWork.forEach((_layer) => {
          expandChildren(_layer)
        })
      })
      commitUndo(project)
    },
    [layer, isLayerSelectedValue, childrenExpanded, project, updates]
  )

  const onExpandPropertiesClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      updates.batch(() => {
        const selectedNodes = getSelection(project, EntityType.Node)
        const layersToWork: Entity[] =
          selectedNodes.length === 0 || isLayerSelectedValue === false
            ? [layer]
            : selectedNodes

        // @NOTE: required to make the same state for all selected layers.
        // So layers.forEach((layer) => layer.toggle()) doesn't work for that case.
        if (propertiesExpanded) {
          layersToWork.forEach((_layer) => {
            collapseProperties(_layer)
          })
          return
        }

        layersToWork.forEach((_layer) => {
          expandProperties(_layer)
        })
      })
      commitUndo(project)
    },
    [layer, isLayerSelectedValue, propertiesExpanded, project, updates]
  )

  React.useEffect(() => {
    if (isNameFocused && nameRef.current) {
      nameRef.current.focus()
      nameRef.current.select()
    }
  }, [isNameFocused])

  const renameLayer = React.useCallback((): void => {
    layer.updateComponent(NameComponent, tempName)
    setIsNameFocused(false)
  }, [layer, tempName])

  const onColorUpdate = React.useCallback(
    (color: LayerColor): void => {
      updates.batch(() => {
        const nodesToUpdate = getSelection(project, EntityType.Node)

        if (nodesToUpdate.findIndex((node) => node.id === layer.id) === -1) {
          nodesToUpdate.push(layer)
        }

        const updateColor = (node: Entity) => {
          node.updateComponent(NodeColorComponent, color)

          const children = node
            .getAspect(ChildrenRelationsAspect)
            ?.getChildrenList()
            .filter(
              (c) =>
                c.getComponent(EntityTypeComponent)?.value === EntityType.Node
            )

          children?.forEach(updateColor)
        }

        nodesToUpdate.forEach(updateColor)
      })
      commitUndo(project)
    },
    [layer, updates, project]
  )

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()

      updates.batch(() => {
        // @NOTE: also used in the tracks on timeline
        if (session.keyModificators.includes(KeyModificator.Shift)) {
          const selectedNodes = getSelection(project, EntityType.Node)
          if (selectedNodes.length === 0) {
            selection.select([layer.id])
            return
          }
          const selectedNodeIds = selectedNodes.map((_node) => _node.id)
          const parent = layer
            .getAspectOrThrow(ParentRelationAspect)
            .getParentEntityOrThrow()
          const nodesToSelect = parent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
          const minSelectedIndex = R.findIndex(
            (_node: any) => selectedNodeIds.includes(_node.id),
            nodesToSelect
          )
          const currentIndex = R.findIndex(
            (_node: any) => _node.id === layer.id,
            nodesToSelect
          )
          const maxSelectedIndex = R.findLastIndex(
            (_node: any) => selectedNodeIds.includes(_node.id),
            nodesToSelect
          )
          // @NOTE: reversed
          if (currentIndex < minSelectedIndex) {
            R.reverse(
              R.range(
                R.min(currentIndex, minSelectedIndex),
                R.max(currentIndex, minSelectedIndex) + 1
              )
            ).forEach((i) => {
              if (nodesToSelect[i] == null) {
                return
              }
              selection.select([nodesToSelect[i].id])
            })
            return
          }
          R.range(
            R.min(currentIndex, maxSelectedIndex),
            R.max(currentIndex, maxSelectedIndex) + 1
          ).forEach((i) => {
            if (nodesToSelect[i] == null) {
              return
            }
            selection.select([nodesToSelect[i].id])
          })
          return
        }
        if (session.keyModificators.includes(KeyModificator.Ctrl)) {
          if (selection.isSelected(layer.id)) {
            selection.deselect([layer.id])
            return
          }
          selection.select([layer.id])
          return
        }
        selection.replace([layer.id])
      })
      commitUndo(project)
    },
    [layer, project, updates, selection, session]
  )

  const nextSoloType = React.useMemo(() => {
    // @TODO: implement
    // const isAltPressed = session.keyModificators.includes('alt')
    const isAltPressed = false

    if (isAltPressed) {
      if (solo === NodeSoloType.None) {
        return NodeSoloType.Both
      }

      return NodeSoloType.None
    }

    if (solo === NodeSoloType.None) {
      return NodeSoloType.Viewport
    }

    if (solo === NodeSoloType.Viewport) {
      return NodeSoloType.Timeline
    }

    if (solo === NodeSoloType.Timeline) {
      return NodeSoloType.Both
    }

    return NodeSoloType.None
  }, [solo])

  const onSoloClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      layer.updateComponent(SoloComponent, nextSoloType)
    },
    [nextSoloType]
  )

  const onLockClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      updates.batch(() => {
        const selectedNodes = getSelection(project, EntityType.Node)
        const isCurrentNodeSelected = selectedNodes
          .map((node) => node.id)
          .includes(layer.id)
        if (isCurrentNodeSelected === false) {
          if (layer.getComponentOrThrow(LockedComponent).value) {
            layer.updateComponent(LockedComponent, false)
            return
          }
          layer.updateComponent(LockedComponent, true)
          selection.deselect([layer.id])
          return
        }
        selectedNodes.forEach((_node) => {
          if (_node.getComponentOrThrow(LockedComponent).value) {
            _node.updateComponent(LockedComponent, false)
            return
          }
          _node.updateComponent(LockedComponent, true)
        })
        selection.deselectAll()
      })
      commitUndo(project)
    },
    [layer, project, updates, selection]
  )

  const onVisibilityClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()

      updates.batch(() => {
        const selectedNodes = getSelection(project, EntityType.Node)

        const isCurrentNodeSelected = selectedNodes
          .map((node) => node.id)
          .includes(layer.id)

        if (isCurrentNodeSelected === false) {
          if (layer.getComponentOrThrow(VisibleInViewportComponent).value) {
            layer.updateComponent(VisibleInViewportComponent, false)
            return
          }

          layer.updateComponent(VisibleInViewportComponent, true)
          return
        }

        selectedNodes.forEach((_node) => {
          _node.updateComponent(VisibleInViewportComponent, (v) => !v)
        })
      })

      commitUndo(project)
    },
    [layer, updates, project]
  )

  const enableHighlight = React.useCallback(() => {
    session.setBuffer(layer.id)
  }, [layer.id])

  const disableHighlight = React.useCallback(() => {
    session.cleanBuffer()
  }, [])

  const maskType = (() => {
    if (isRoot) {
      return 'none'
    }

    const children = layer
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()
    const maskIdx = children.findIndex(
      (_node) => _node.getComponent(MaskComponent)?.value
    )
    const hasMask = maskIdx !== -1

    if (!hasMask) {
      return 'none'
    }

    if (layer.getComponent(MaskComponent)?.value) {
      return 'start'
    }

    const currentIdx = children.findIndex((_node) => _node.id === layer.id)
    const isLast = currentIdx === children.length - 1

    if (hasMask && isLast) {
      return 'end'
    }

    if (hasMask && currentIdx < maskIdx) {
      return 'in-scope'
    }

    return 'between'
  })()

  const openContextMenu = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (selection.isSelected(layer.id) === false) {
        selection.select([layer.id])
      }
      const array: Array<ContextMenuItem | 'divider'> = []
      // // @TODO: IMPORTANT refactor. Move to common place or service
      array.push({
        title: 'Copy as position keyframes',
        // @TODO: enable
        onClick: copyPositionKeyframes({
          layer,
          project,
          clipboard,
          uiStore,
        }),
      })
      array.push({
        title: 'Clear animation',
        onClick: clearAnimation({ project, uiStore }),
      })
      uiStore.openContextMenu(array, e)
    },
    [uiStore]
  )

  const handleColorSelectionOpen = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      event.preventDefault()
      setColorSelectionAnchorEl(event.currentTarget)
    },
    []
  )

  const handleColorSelectionClose = React.useCallback(() => {
    setColorSelectionAnchorEl(null)
  }, [])

  const handleColorSelection = React.useCallback(
    (newColor: string) => {
      onColorUpdate(newColor.toUpperCase() as LayerColor)
      handleColorSelectionClose()
    },
    [handleColorSelectionClose]
  )

  const handleHoverIn = React.useCallback(() => {
    setIsHovering(true)
    enableHighlight()
  }, [enableHighlight])

  const handleHoverOut = React.useCallback(() => {
    setIsHovering(false)
    disableHighlight()
  }, [disableHighlight])

  return (
    <TimelineTrack
      isSelected={false}
      isHighlighted={isHighlighted}
      isDropTarget={isDropTarget}
      onClick={onClick}
      className={styles.container}
      alignment="center"
      highlightColor={
        nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
      }
      color={color}
      maskColor={maskColor}
      onContextMenu={openContextMenu}
      onMouseDown={(e) => dndHandlers.onMouseDown(e, layer)}
      onMouseMove={(e) => dndHandlers.onMouseMove(e, layer)}
      onMouseUp={(e) => dndHandlers.onMouseUp(e, layer)}
      onHoverIn={(e) => {
        handleHoverIn()
        dndHandlers.onMouseOver(e, layer)
      }}
      onHoverOut={(e) => {
        handleHoverOut()
        dndHandlers.onMouseOut(e, layer)
      }}
      highlightIndent={getDropZoneIndent(indent)}
    >
      <div className={styles.left}>
        {R.range(0, indent || 0).map((spacer) => (
          <div key={spacer} className={styles.spacer}>
            <span style={{ width: 16 }} />
          </div>
        ))}

        {isRoot === false && (
          <>
            {maskType !== 'none' ? (
              <div
                className={classNames(styles.mask, {
                  [styles.mask__start]: maskType === 'start',
                  [styles.mask__between]: maskType === 'between',
                  [styles.mask__end]: maskType === 'end',
                })}
              />
            ) : (
              <div className={styles.spacer}>
                <span style={{ width: 16 }} />
              </div>
            )}
          </>
        )}

        {hasChildren ? (
          <div className={styles.component}>
            <buttons.Icon
              onClick={onExpandChildrenClick}
              btnSize={iconButtonSize}
            >
              <icons.ExpandChildren
                size={iconSize}
                type={
                  childrenExpanded
                    ? NodeExpandType.ChildrenVisible
                    : NodeExpandType.Normal
                }
              />
            </buttons.Icon>
          </div>
        ) : (
          <div className={styles.spacer}>
            <span style={{ width: 16 }} />
          </div>
        )}

        <div className={styles.component}>
          <button
            type="button"
            className={styles['color-button']}
            onClick={handleColorSelectionOpen}
          >
            <span
              className={styles.color}
              style={{
                backgroundColor:
                  nodeColors[
                    layer.getComponentOrThrow(NodeColorComponent).value
                  ],
              }}
            />
          </button>
        </div>

        <Popover
          open={!!colorSelectionAnchorEl}
          anchorEl={colorSelectionAnchorEl}
          onClose={handleColorSelectionClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus
        >
          <ColorSelector
            color={color}
            onSelect={handleColorSelection}
            colors={selectorColors}
          />
        </Popover>

        {hasAnimatedProperties ? (
          <div className={styles.component}>
            <buttons.Icon
              onClick={onExpandPropertiesClick}
              btnSize={iconButtonSize}
            >
              <icons.ExpandProperties
                size={iconSize}
                type={
                  propertiesExpanded
                    ? NodeExpandType.PropertiesVisible
                    : NodeExpandType.Normal
                }
              />
            </buttons.Icon>
          </div>
        ) : (
          <div className={styles.spacer}>
            <span style={{ width: 16 }} />
          </div>
        )}

        <div className={styles.component}>
          <icons.Layer type={type} size={iconSize} />
        </div>
        <div
          className={styles['name-wrapper']}
          onDoubleClick={() => setIsNameFocused(true)}
        >
          <p
            className={classNames(styles.name, {
              [styles['name--visible']]: !isNameFocused,
            })}
          >
            {trimString(48, name)}
          </p>

          <div
            className={classNames(styles['name-input-wrapper'], {
              [styles['name-input-wrapper--visible']]: isNameFocused,
            })}
          >
            <InputTimeline
              value={tempName}
              onChange={setTempName}
              fullWidth
              ref={nameRef}
              className={styles['name-input']}
              onBlur={renameLayer}
              onEnterPress={renameLayer}
              onEscPress={() => {
                setIsNameFocused(false)
                setTempName(name)
              }}
              fontSize="medium"
            />
          </div>
        </div>
      </div>

      <div className={styles.right}>
        <div
          className={classNames(styles.component, {
            [styles['component--hidden']]:
              isHovering === false && lockType === LockType.Normal,
          })}
        >
          <buttons.Icon onClick={onLockClick} btnSize={iconButtonSize}>
            <icons.Lock size={iconSize} type={lockType} />
          </buttons.Icon>
        </div>

        <div
          className={classNames(styles.component, {
            [styles['component--hidden']]:
              isHovering === false && visibilityType === VisibilityType.Visible,
          })}
        >
          <buttons.Icon onClick={onVisibilityClick} btnSize={iconButtonSize}>
            <icons.Visible size={iconSize} type={visibilityType} />
          </buttons.Icon>
        </div>

        {/* @TODO: implement solo in the next release */}
        {/* <div
          className={classnames(styles.component, {
            [styles['component--hidden']]:
              isHovering === false && soloType === NodeSoloType.None,
          })}
        >
          <buttons.Icon onClick={onSoloClick} size={iconButtonSize}>
            <icons.Solo size={iconSize} type={soloType} />
          </buttons.Icon>
        </div> */}

        <div className={styles.spacer}>
          <span style={{ width: 4 }} />
        </div>
      </div>
    </TimelineTrack>
  )
}
