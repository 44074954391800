import {
  Component,
  HashComponent,
  VisibleInViewportComponent,
  commitUndo,
  getNode,
  mixed,
} from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { VisibilityType } from '@aninix/app-design-system/components/common/icons'
import { useImagesStore } from '@aninix/core'
import { useNotifications } from '@aninix/notifications'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import * as styles from './index.scss'

export interface IProps {
  components: Component[]
}
export const ImageValue: React.FCC<IProps> = observer(({ components }) => {
  const images = useImagesStore()
  const notifications = useNotifications()
  const value = R.all((c) => c instanceof HashComponent, components)
    ? (components[0].value as string)
    : mixed
  const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())
  const node = getNode(components[0])

  if (node === undefined) {
    throw new Error('Invalid state. Node not found')
  }

  const isVisible = node.getComponentOrThrow(VisibleInViewportComponent).value
  const toggleVisibilibity = () => {
    components.forEach((component) => {
      component.entity.updateComponent(VisibleInViewportComponent, (v) => !v)
    })
    endChange()
  }

  const handleClick = () => {
    notifications.showNotification(<>Image editing is not supported yet</>, {
      id: 'image-editing-not-supported',
    })
  }

  // @TODO: implement
  const isExpanded = false
  const isHovered = false

  const iconBtnSize = React.useMemo(
    () => ({
      width: 32,
      height: 32,
    }),
    []
  )

  const iconSize = React.useMemo(
    () => ({
      x: 16,
      y: 16,
    }),
    []
  )

  return (
    <div className="flex flex-col">
      {value === mixed ? (
        <p className={styles.mixed}>Click + to replace mixed content</p>
      ) : (
        <div
          className={classNames('flex flex-row justify-between items-center', {
            'bg-[#f0f0f0]': isExpanded,
          })}
        >
          <button
            className={classNames(
              'flex flex-row items-center justify-start w-full h-full cursor-default border-[1px] border-solid border-transparent rounded-[2px] gap-2 pl-[6px]',
              {
                '!border-[var(--aninix-color-foreground-black-1)]': isHovered,
              }
            )}
            onClick={handleClick}
          >
            <img
              width={16}
              height={16}
              className="flex-shrink-0 w-4 h-4 border-[1px] border-[rgba(127, 127, 127, 0.2)] border-solid rounded-[2px] object-cover"
              src={images.getImageSync(value)?.src}
            />

            <p className="text-[11px] text-left w-full text-[var(--figma-color-text)]">
              Image
            </p>
          </button>

          <div className="flex flex-row">
            <buttons.Icon btnSize={iconBtnSize} onClick={toggleVisibilibity}>
              <icons.Visible
                type={
                  isVisible ? VisibilityType.Visible : VisibilityType.Hidden
                }
                size={iconSize}
              />
            </buttons.Icon>
          </div>
        </div>
      )}
    </div>
  )
})
