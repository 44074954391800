import Card from '@material-ui/core/Card'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { CurveStyle } from '@aninix-inc/model'
import { Add } from '../../../icons/Add'
import Header from '../Header'
import { CurveStyles } from './curve-styles'
import * as styles from './index.scss'

export type TNormalizedUserPreset = {
  name: string
  value?: CurveStyle
  children?: TNormalizedUserPreset[]
}

export interface IAddPathProps {
  splittedArray: string[]
  acc: TNormalizedUserPreset[]
  value: CurveStyle
}

export interface IProps {
  selectedCurveStyleId?: string
  curveStyles: CurveStyle[]
  onAddPreset: () => void
  onEditPreset: (presetId: string) => void
  onApply: (presetId: string) => void
  disabled?: boolean
  openAllGroupsByDefault?: boolean
}

export const UserPresets: React.FCC<IProps> = observer(
  ({
    selectedCurveStyleId,
    curveStyles,
    onAddPreset,
    onEditPreset,
    onApply,
    disabled,
    openAllGroupsByDefault = false,
  }) => (
    <Card classes={{ root: styles.card }}>
      <Header
        title="Curve Styles"
        icon={<Add />}
        onClick={onAddPreset}
        disabled={disabled}
      />

      <div className={styles['presets-wrapper']}>
        <CurveStyles
          curveStyles={curveStyles}
          onEditPreset={onEditPreset}
          onApply={onApply}
          selectedCurveStyleId={selectedCurveStyleId}
          openAllGroupsByDefault={openAllGroupsByDefault}
        />
      </div>
    </Card>
  )
)
