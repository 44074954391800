import { buttons, icons } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { usePlayback } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { hotkeysLabels } from '../../../../defaults'

export interface IProps {}
export const TimeControlPlayback: React.FCC<IProps> = observer(() => {
  const playback = usePlayback()

  // @NOTE: disabled because it's very expensive for rerender in each frame
  // @TODO: maybe uncomment and implement later >
  // goToStartDisabled: time <= project.startTime,
  // goToPrevTimeUnitDisabled: prevFrameTime <= project.startTime,
  // goToNextTimeUnitDisabled: nextFrameTime >= project.endTime,
  // goToEndDisabled: time >= project.endTime,
  // <
  const goToStartDisabled = false
  const goToPrevTimeUnitDisabled = false
  const goToNextTimeUnitDisabled = false
  const goToEndDisabled = false

  const buttonSize = React.useMemo(() => ({ width: 24, height: 24 }), [])
  const buttonIconSize = React.useMemo(() => ({ x: 16, y: 16 }), [])

  return (
    <div style={{ display: 'flex' }}>
      <buttons.Icon
        btnSize={buttonSize}
        onClick={playback.toStart}
        disabled={goToStartDisabled}
        tooltip={
          <p>
            Go to start{' '}
            <HotkeyCombination keys={[hotkeysLabels().shift, 'F']} />
          </p>
        }
      >
        <icons.GoToStart size={buttonIconSize} />
      </buttons.Icon>

      <buttons.Icon
        btnSize={buttonSize}
        onClick={playback.toPreviousKeyframe}
        // disabled={prevKeyframe == null}
        tooltip={
          <p>
            Go to previous keyframe{' '}
            <HotkeyCombination keys={[hotkeysLabels().ctrl, 'F']} />
          </p>
        }
      >
        <icons.GoToPrevKeyframe size={buttonIconSize} />
      </buttons.Icon>

      <buttons.Icon
        btnSize={buttonSize}
        onClick={() => playback.toPreviousTimeUnit()}
        disabled={goToPrevTimeUnitDisabled}
        tooltip={
          <p>
            Go to previous frame <HotkeyCombination keys={['F']} />
          </p>
        }
      >
        <icons.GoToPrevTimeUnit size={buttonIconSize} />
      </buttons.Icon>

      {playback.isPlaying ? (
        <buttons.Icon
          btnSize={buttonSize}
          onClick={playback.pause}
          tooltip={
            <p>
              Pause <HotkeyCombination keys={[hotkeysLabels().space]} />
            </p>
          }
        >
          <icons.Pause size={buttonIconSize} />
        </buttons.Icon>
      ) : (
        <buttons.Icon
          btnSize={buttonSize}
          onClick={playback.play}
          tooltip={
            <p>
              Play <HotkeyCombination keys={[hotkeysLabels().space]} />
            </p>
          }
        >
          <icons.Play size={buttonIconSize} />
        </buttons.Icon>
      )}

      <buttons.Icon
        btnSize={buttonSize}
        onClick={() => playback.toNextTimeUnit()}
        disabled={goToNextTimeUnitDisabled}
        tooltip={
          <p>
            Go to next frame <HotkeyCombination keys={['G']} />
          </p>
        }
      >
        <icons.GoToNextTimeUnit size={buttonIconSize} />
      </buttons.Icon>

      <buttons.Icon
        btnSize={buttonSize}
        onClick={playback.toNextKeyframe}
        // disabled={nextKeyframe == null}
        tooltip={
          <p>
            Go to next keyframe{' '}
            <HotkeyCombination keys={[hotkeysLabels().ctrl, 'G']} />
          </p>
        }
      >
        <icons.GoToNextKeyframe size={buttonIconSize} />
      </buttons.Icon>

      <buttons.Icon
        btnSize={buttonSize}
        onClick={playback.toEnd}
        disabled={goToEndDisabled}
        tooltip={
          <p>
            Go to end <HotkeyCombination keys={[hotkeysLabels().shift, 'G']} />
          </p>
        }
      >
        <icons.GoToEnd size={buttonIconSize} />
      </buttons.Icon>
    </div>
  )
})
