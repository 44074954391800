import {
  LocalStorageIo,
  useCurrentFolder,
  useDeleteProject,
  useLinkProjectToFolder,
  useSetProjectAccessByLink,
  useSetProjectName,
  useTransferProjectToTeam,
  useTransferProjectToUser,
} from '@aninix/api'
import classNames from 'classnames'
import * as DateFNS from 'date-fns'
import * as React from 'react'
import { useHref, useNavigate } from 'react-router-dom'

import { ApiProject } from '@aninix/api/helpers'
import { UserRole } from '@aninix/core'
import { config } from '../../config'
import { toast } from '../../modules/toasts'
import {
  halfScreenCheck,
  useCurrentSpaceStore,
  useCurrentTeamStore,
  useCurrentUser,
} from '../../use-cases'
import { Typography } from '../design-system/typography'
import { Icons } from '../icons'
import { Delete } from './delete'
import { MoveTo } from './move-to'
import { Rename } from './rename'
import { useAnalytics } from '@aninix/analytics'
import { AnalyticsEvent } from '@aninix/analytics'

interface IProjectOption {
  Icon?: React.ReactNode
  label: string
  openModal?: React.MouseEventHandler<HTMLDivElement>
  options?: IProjectOption[]
  showBadgeOnIcon?: boolean
  isEndOfSection?: boolean
}

interface IProjectPrivacyOption {
  projectId: string
  status: ApiProject['accessByLink']
  label: string
  description: string
  isSelected?: boolean
}

const localProjectCommentsCount = new LocalStorageIo<Record<string, number>>(
  'aninix.project-comments-counters',
  {}
)

const ProjectPrivacyOption: React.FC<IProjectPrivacyOption> = ({
  projectId,
  status,
  label,
  description,
  isSelected = true,
}) => {
  const currentFolder = useCurrentFolder().folder!
  const setProjectAccessByLink = useSetProjectAccessByLink()
  //@TODO: make more responive by triggering privacy icon state change before request
  const setPrivacy = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isSelected) return
      e.preventDefault()
      e.stopPropagation()
      setProjectAccessByLink
        .mutateAsync({
          projectId: projectId,
          accessByLink: status,
        })
        .then(() => currentFolder.refetch())
    },
    [isSelected]
  )
  return (
    <div
      onClick={setPrivacy}
      className="flex flex-row items-center gap-2 px-4 py-2 hover:bg-white hover:bg-opacity-[15%]"
    >
      <div className="flex flex-row items-center gap-2">
        <Icons.Check
          className={classNames('stroke-white', {
            ['invisible']: !isSelected,
          })}
        />
        <div className="flex flex-col items-start">
          <p className="font-body text-sm font-normal text-white">{label}</p>
          <p className="font-body text-sm font-normal text-gray-400">
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

interface IProjectPrivacyOptions {
  handleClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isShown: boolean
  status: ApiProject['accessByLink']
  projectId: string
}

const ProjectPrivacyOptions = ({
  projectId,
  handleClose,
  isShown,
  status,
}: IProjectPrivacyOptions) => {
  const projectPrivacyOptions: Pick<
    IProjectPrivacyOption,
    'status' | 'label' | 'description'
  >[] = [
    {
      status: 'team_only',
      label: 'Private',
      description: 'Only your team has access',
    },
    {
      status: 'anyone',
      label: 'Public',
      description: 'Everyone with the link can access',
    },
  ]

  return (
    <div
      onMouseLeave={handleClose}
      className={classNames(
        'absolute -right-0 top-[calc(100%+2px)] flex w-max flex-col overflow-clip rounded-md bg-secondary transition-all  duration-300',
        {
          ['invisible opacity-0']: isShown === false,
        }
      )}
    >
      {projectPrivacyOptions.map((i, key) => (
        <ProjectPrivacyOption
          {...i}
          key={`${projectId}-${i.label}`}
          isSelected={i.status === status}
          projectId={projectId}
        />
      ))}
    </div>
  )
}

export const ProjectOption: React.FC<
  IProjectOption & { projectId: string } & {
    relativePosition?: 'first' | 'last'
  }
> = ({
  projectId,
  Icon,
  label,
  openModal,
  options,
  relativePosition,
  isEndOfSection = false,
  showBadgeOnIcon = false,
}) => {
  const [isShown, setIsShow] = React.useState(false)

  const show = React.useCallback(() => {
    setIsShow(true)
  }, [])

  const hide = React.useCallback(() => {
    setIsShow(false)
  }, [])

  return (
    <div
      onClick={openModal}
      className={classNames(
        'group/item flex flex-row items-center gap-2 px-4 py-2 hover:bg-white hover:bg-opacity-[15%]',
        {
          ['relative']: !!options,
          ['rounded-t-md']: relativePosition === 'first',
          ['rounded-b-md']: relativePosition === 'last',
          ['border-b-[1px] border-gray-500']: isEndOfSection,
        }
      )}
      onMouseEnter={!!options ? show : noop}
      onMouseLeave={!!options ? hide : noop}
    >
      <div className="relative">
        {Icon}
        {showBadgeOnIcon && (
          <div className=" absolute right-0 top-0 h-2 w-2 rounded-full bg-error ring-2 ring-secondary group-hover/item:!ring-[#2f343b]" />
        )}
      </div>
      <p className="py-[1px] font-body text-sm text-white">{label}</p>
      {options && <Icons.ArrowRight />}
      {options && (
        <ProjectContextMenu
          projectId={projectId}
          options={options}
          isShown={isShown}
        />
      )}
    </div>
  )
}

interface IProjectContextMenu {
  projectId: string
  owner?: {
    userId?: string | null
    teamId?: string | null
  }
  options?: IProjectOption[]
  canEditProject?: boolean

  // @NOTE: those links are optional in folder
  // @TODO: separate context menu for project and folder
  projectLink?: string
  specificationsLink?: string
  commentsLink?: string

  unreadCommentsCount?: number

  handleClose?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isShown: boolean
  handleModal?: React.Dispatch<React.SetStateAction<Modal>>
}

const noop = () => {}

const ProjectContextMenu: React.FC<IProjectContextMenu> = ({
  projectId,
  owner,
  options,
  projectLink = '',
  specificationsLink = '',
  commentsLink = '',
  handleClose = noop,
  canEditProject = true,
  isShown,
  handleModal = noop,
  unreadCommentsCount = 0,
}) => {
  const { currentUser } = useCurrentSpaceStore()

  // const {projectId}
  const transferProjectToTeam = useTransferProjectToTeam()
  const transferProjectToUser = useTransferProjectToUser()

  const {
    folder: currentFolder,
    selectedProjectsIds,
    deleteSelected,
    moveSelected,
  } = useCurrentFolder()

  const isSelected = React.useMemo(
    () => selectedProjectsIds?.has(projectId) ?? false,
    [selectedProjectsIds]
  )

  const showOnlyGlobalActions =
    isSelected && (selectedProjectsIds?.size ?? 0) >= 2

  const moveToSpaceOptions: IProjectOption[] = React.useMemo(() => {
    {
      const teamOptions =
        currentUser?.teams
          ?.filter((team) => team.id !== owner?.teamId)
          .map((team) => {
            return {
              label: team.name,
              openModal: () =>
                transferProjectToTeam
                  .mutateAsync({
                    teamId: team.id,
                    projectId: projectId,
                  })
                  .then((value) => {
                    toast(`Project moved to "${team.name}" space`, {
                      variant: 'success',
                    })
                    currentFolder?.refetch()
                  })
                  .catch((error) =>
                    toast(`Couldn't move project`, { variant: 'error' })
                  ),
            }
          }) ?? []

      const personalSpace =
        currentUser && currentUser.id !== owner?.userId
          ? [
              {
                label: 'Personal space',
                openModal: () =>
                  transferProjectToUser
                    .mutateAsync({
                      userId: currentUser.id,
                      projectId: projectId,
                    })
                    .then((value) => {
                      toast(`Project moved to "${currentUser.name}" space`, {
                        variant: 'success',
                      })
                      currentFolder?.refetch()
                    })
                    .catch((error) =>
                      toast(`Couldn't move project`, { variant: 'error' })
                    ),
              },
            ]
          : []

      return [...teamOptions, ...personalSpace] as IProjectOption[]
    }
  }, [currentUser, owner, canEditProject])

  const localProjectOptions: IProjectOption[] =
    options ??
    React.useMemo(
      () => [
        {
          Icon: <Icons.InspectCode />,
          label: 'Specifications',
          openModal: (e) => {
            window.open(specificationsLink, '_blank')
          },
        },
        {
          Icon: <Icons.Comments />,
          label: 'Comments',
          openModal: (e) => {
            window.open(commentsLink, '_blank')
          },
          isEndOfSection: true,
          showBadgeOnIcon: unreadCommentsCount > 0,
        },
        ...(canEditProject
          ? [
              {
                Icon: <Icons.GetLink />,
                label: 'Get link',
                openModal: (e: any) => {
                  navigator.clipboard.writeText(projectLink)
                  toast('🔗 Link copied', {
                    variant: 'info',
                  })
                },
              },
            ]
          : []),

        ...(canEditProject
          ? [
              {
                Icon: <Icons.Rename />,
                label: 'Rename',
                openModal: (e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleModal('rename')
                },
              },
            ]
          : []),
        ...(moveToSpaceOptions.length > 0 && canEditProject
          ? [
              {
                Icon: <Icons.MoveToSpaces />,
                label: 'Move to space',
                options: moveToSpaceOptions,
              },
            ]
          : []),
        ...(canEditProject
          ? [
              {
                Icon: <Icons.MoveTo />,
                label: 'Move to...',
                openModal: (e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleModal('moveto')
                },
              },
              {
                Icon: <Icons.Delete />,
                label: 'Delete',
                openModal: (e: any) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleModal('delete')
                },
              },
            ]
          : []),
      ],
      [handleModal, moveToSpaceOptions, canEditProject, unreadCommentsCount]
    )

  const globalProjectOptions: IProjectOption[] = [
    {
      Icon: <Icons.MoveTo />,
      label: 'Move to...',
      openModal: (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        moveSelected?.()
      },
    },
    {
      Icon: <Icons.Delete />,
      label: 'Delete',
      openModal: (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        deleteSelected?.()
      },
    },
  ]

  const projectOptions: IProjectOption[] = showOnlyGlobalActions
    ? globalProjectOptions
    : localProjectOptions

  const ref = React.useRef(null)

  const { isLeftHalf } = halfScreenCheck(ref)

  return (
    <div
      onMouseLeave={handleClose}
      ref={ref}
      className={classNames(
        'absolute -right-0 top-[calc(100%+2px)] flex w-max flex-col rounded-md border-[0.5px] border-gray-800 bg-secondary  transition-all duration-300',
        {
          ['pointer-events-none invisible opacity-0']: isShown === false,
          ['!left-full !top-0']: !!options && isLeftHalf,
          ['!right-full !top-0']: !!options && !isLeftHalf,
        }
      )}
    >
      {projectOptions.map((i, index) => (
        <ProjectOption
          {...i}
          relativePosition={
            index === projectOptions.length - 1
              ? 'last'
              : index === 0
                ? 'first'
                : undefined
          }
          projectId={projectId}
          key={`${projectId}-${i.label}-${index}`}
        />
      ))}
    </div>
  )
}

const projectLastEditedLabel = (dateString: string) => {
  const date = new Date(dateString)
  const verboseTimeElapsed = DateFNS.formatDistance(date, new Date())
  return `Edited ${verboseTimeElapsed} ago`
}

const projectPrivacyIcon = {
  ['anyone']: (isSelected: boolean) => (
    <Icons.LockOpen
      className={classNames(
        'stroke-gray-300 transition-all duration-300 group-hover:stroke-black',
        {
          ['!stroke-white']: isSelected,
        }
      )}
    />
  ),
  ['team_only']: (isSelected: boolean) => (
    <Icons.Lock
      className={classNames(
        'stroke-gray-300 transition-all duration-300 group-hover:stroke-black',
        {
          ['!stroke-white']: isSelected,
        }
      )}
    />
  ),
  ['example']: (isSelected: boolean) => (
    <Icons.Lock
      className={classNames(
        'stroke-gray-300 transition-all duration-300 group-hover:stroke-black',
        {
          ['!stroke-white']: isSelected,
        }
      )}
    />
  ),
}

export type Modal = 'none' | 'rename' | 'moveto' | 'delete'

export const ProjectItemPlaceholder: React.FC = () => (
  <div className="z-1 group relative flex w-full flex-col rounded-[8px] border-[1px] border-gray-200 bg-white transition-all duration-300 hover:shadow-lg">
    <div className="flex h-48 w-full items-center overflow-clip  rounded-t-[7px]">
      <div className="h-full w-full animate-pulse bg-slate-100 object-cover" />
    </div>
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row justify-between">
          <div className="h-6 w-1/3 animate-pulse rounded-md bg-gray-200" />
          <div
            className={classNames(
              'relative z-10 rounded-md p-1 transition-all duration-300'
            )}
          >
            <div className="h-6 w-6 animate-pulse rounded-md bg-slate-200" />
          </div>
        </div>
        <div className="animate-puls h-4 w-2/3 rounded-md bg-gray-200" />
      </div>
    </div>
  </div>
)

export const ProjectItem: React.FC<
  Pick<
    ApiProject,
    | 'id'
    | 'name'
    | 'updatedAt'
    | 'accessByLink'
    | 'userId'
    | 'teamId'
    | 'lastEdit'
    | 'totalCommentsCount'
    | 'coverUrl'
  >
> = (
  {
    id,
    name,
    updatedAt,
    accessByLink,
    userId,
    teamId,
    lastEdit,
    totalCommentsCount,
    coverUrl,
  } // accessByLink = ProjectAccessByLink,
) => {
  const [showProjectOptions, setShowProjectOptions] =
    React.useState<boolean>(false)
  const [showPrivacyOptions, setShowPrivacyOptions] =
    React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<Modal>('none')

  const currentFolder = useCurrentFolder().folder!

  const onProjectUpdate = React.useCallback(() => {
    currentFolder.refetch()
  }, [])

  const linkProjectToFolder = useLinkProjectToFolder()

  const navigate = useNavigate()

  const analytics = useAnalytics()

  const trackProjectAction = React.useCallback(
    (eventName: AnalyticsEvent, properties: Record<string, any> = {}) => {
      analytics.track({
        eventName,
        properties: {
          space: teamId ? 'team' : 'personal',
          ...properties,
        },
      })
    },
    [analytics, teamId]
  )

  const moveProjectTo = React.useCallback(
    (folderId?: string | null) => {
      linkProjectToFolder
        .mutateAsync({
          projectId: id,
          folderId,
        })
        .then((value) => {
          setShowModal('none')
          currentFolder.refetch()

          setSelectedProjectsIds?.(new Set([]))

          toast('Project moved successfully. Click to open the folder', {
            variant: 'info',
            toastProps: {
              icon: <Icons.Folder />,
              onClick: () =>
                navigate('../projects' + (folderId ? `/${folderId}` : '')),
            },
          })
          trackProjectAction(AnalyticsEvent.ProjectMove, { projectCount: 1 })
        })
    },
    [trackProjectAction]
  )

  const setProjectName = useSetProjectName()

  const renameProject = React.useCallback((name: string) => {
    setProjectName
      .mutateAsync({ projectId: id, name })
      .then((value) => {
        setShowModal('none')
        currentFolder.refetch()
        toast(`Project renamed to ${name}`)
      })
      .catch((error) => toast("Couldn't rename project", { variant: 'error' }))
  }, [])

  const handleModalClose = React.useCallback(() => {
    setShowModal('none')
  }, [])

  const { currentUser } = useCurrentSpaceStore()
  const { role } = useCurrentUser()
  const { currentTeam } = useCurrentTeamStore()

  const isPro = React.useMemo(
    () =>
      currentTeam != null ? currentTeam.plan === 'pro' : role === UserRole.Pro,
    [currentTeam, role]
  )

  const deleteProject = useDeleteProject()

  const handleDeleteProject = React.useCallback(async () => {
    await deleteProject({ projectId: id })
      .then(() => {
        onProjectUpdate()
        toast('Project moved to trash', {
          variant: 'info',
        })
        trackProjectAction(AnalyticsEvent.ProjectTrashed)
      })
      .catch(() => {
        toast('Project deleting failed', {
          variant: 'error',
        })
      })
  }, [deleteProject, trackProjectAction])

  const relativeProjectLink = `/edit/${id}`
  const relativeSpecificationsLink = `/inspect/${id}/details`
  const absoluteProjectLink = config.webAppUrl + useHref(relativeProjectLink)
  const absoluteSpecificationsLink =
    config.webAppUrl + useHref(`/inspect/${id}/details`)
  const absoluteCommentsLink =
    config.webAppUrl + useHref(`/inspect/${id}/comments`)

  const currentFolderId = useCurrentFolder().payload?.folderId ?? null
  const {
    draggedFolderOverId,
    setDraggedFolderOverId,
    setCurrentMousePosition,
    currentMousePosition,
    draggedProjectId,
    setDraggedProjectId,
    selectedProjectsIds,
    setSelectedProjectsIds,
    setSelectedFoldersIds,
  } = useCurrentFolder()

  const moveSelectedProjectsTo = React.useCallback(
    (folderId?: string | null) => {
      if (!selectedProjectsIds) return

      const promises = Promise.all(
        [...selectedProjectsIds]
          .filter((e) => e !== undefined)
          .map((projectId) => {
            return linkProjectToFolder.mutateAsync({
              projectId: projectId,
              folderId,
            })
          })
      )
      promises.then((value) => {
        setShowModal('none')
        currentFolder.refetch()

        setSelectedProjectsIds?.(new Set([]))

        toast('Projects moved successfully. Click to open the folder', {
          variant: 'info',
          toastProps: {
            icon: <Icons.Folder />,
            onClick: () =>
              navigate('../projects' + (folderId ? `/${folderId}` : '')),
          },
        })
        trackProjectAction(AnalyticsEvent.ProjectMove, {
          projectCount: selectedProjectsIds.size,
        })
      })
    },
    [selectedProjectsIds, trackProjectAction]
  )

  const dragImage = React.useMemo(() => {
    let img = new Image()
    img.src = '/images/transparentPixel.png'
    return img
  }, [])

  const teamUser = React.useMemo(
    () => currentUser?.teams.find((e) => e.id === teamId),
    [currentUser, teamId]
  )

  const canEditProject = React.useMemo(() => {
    return (
      ['editor'].includes(teamUser?.aninixRole ?? 'viewer') ||
      userId === currentUser?.id
    )
  }, [teamUser, userId, currentUser?.id])

  function signedLastEditLabel(lastEdit: ApiProject['lastEdit']) {
    if (lastEdit === undefined) return <span></span>

    let { avatarUrl, name, date } = lastEdit

    const jsDate = new Date(date)
    const verboseTimeElapsed = DateFNS.formatDistance(jsDate, new Date())

    return (
      <span>
        {name} edited{' '}
        <span className="whitespace-nowrap">{verboseTimeElapsed} ago</span>
      </span>
    )
  }

  const metaHeaderRef = React.useRef<HTMLDivElement>(null)

  const [fauxMetaHeaderStyle, setFauxMetaHeaderStyle] =
    React.useState<React.CSSProperties>({ display: 'none' })

  const isMouseInZero =
    currentMousePosition?.x === 0 && currentMousePosition?.y === 0

  const isSelected = React.useMemo(
    () => selectedProjectsIds?.has(id) ?? false,
    [selectedProjectsIds]
  )

  const selectionPosition = React.useMemo(() => {
    return (
      currentFolder.data?.projects
        .map((e) => e.id)
        .filter((id) => selectedProjectsIds?.has(id))
        .indexOf(id) ?? 0
    )
  }, [currentFolder, selectedProjectsIds])

  const isDragged =
    draggedProjectId !== undefined &&
    !isMouseInZero &&
    currentMousePosition !== undefined &&
    isSelected

  const isDraggedOver = draggedFolderOverId !== undefined

  const [isTransitionEnabled, setIsTransitionEnabled] = React.useState(true)

  React.useEffect(() => {
    if (isDragged && !isDraggedOver)
      setTimeout(() => setIsTransitionEnabled(false), 200)
    else setIsTransitionEnabled(true)
  }, [isDragged, !isDraggedOver])

  const [allowImmediateUpdate, setAllowImmediateUpdate] = React.useState(true)

  React.useEffect(() => {
    if (metaHeaderRef.current) {
      const rect = metaHeaderRef.current.getBoundingClientRect()

      const itemOpacity = (() => {
        if (selectionPosition < 5) return 1
        if (selectionPosition === 5) return 0.66
        if (selectionPosition === 6) return 0.33
        return 0
      })()

      const style = {
        transitionDuration: 250 + 'ms',
        position: 'absolute',
        zIndex: 9999 - (isDraggedOver ? selectionPosition : 0),
        width: `${rect.width}px`,
        height: `${rect.height}px`,
        opacity: isDragged && isSelected && !isDraggedOver ? itemOpacity : 0,
        transform:
          isDragged && isSelected
            ? `translate(${
                (currentMousePosition?.x ?? 0) - rect.left - rect.width / 2
              }px, ${
                (currentMousePosition?.y ?? 0) -
                rect.top -
                rect.height / 2 +
                selectionPosition *
                  (rect.height * 0.75 + 4) *
                  (isDraggedOver ? 0 : 1)
              }px) scale(${isDraggedOver ? 0.25 : 0.75})`
            : undefined,
        bottom: `0`,
        left: `0`,
      } as React.CSSProperties

      if (isTransitionEnabled) {
        setFauxMetaHeaderStyle(style)
        setTimeout(() => setAllowImmediateUpdate(true), 200)
      } else {
        if (allowImmediateUpdate) setFauxMetaHeaderStyle(style)
      }
    }
  }, [metaHeaderRef.current, currentMousePosition])

  const handleDrag = React.useCallback((e: React.MouseEvent) => {
    setCurrentMousePosition?.({ x: e.clientX, y: e.clientY })
  }, [])

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      setSelectedFoldersIds?.(new Set([]))
      if (e.metaKey) {
        if (isSelected) {
          setSelectedProjectsIds?.((values) => {
            return new Set([...(values ?? [])].filter((value) => id !== value))
          })
        } else {
          setSelectedProjectsIds?.((values) => new Set([...(values ?? []), id]))
        }

        return
      }

      if (e.shiftKey) {
        if (isSelected) {
          setSelectedProjectsIds?.((values) => {
            return new Set([...(values ?? [])].filter((value) => id !== value))
          })
        } else {
          const selectedIds = [...(selectedProjectsIds ?? [])]

          console.log(selectedIds, selectedIds.length)

          if (selectedIds.length < 1) return

          const allProjectsInFolder =
            currentFolder.data?.projects.map((p) => p.id) ?? []

          const fromId = selectedIds.at(-1) ?? selectedIds[0]
          const fromIndex = allProjectsInFolder?.indexOf(fromId)

          const toId = id
          const toIndex = allProjectsInFolder?.indexOf(toId)

          const isAfter = fromIndex < toIndex

          console.log(fromIndex, toIndex, isAfter)

          const projectsToSelect = isAfter
            ? allProjectsInFolder.filter(
                (e, i) => i >= fromIndex && i <= toIndex
              )
            : allProjectsInFolder.filter(
                (e, i) => i >= toIndex && i <= fromIndex
              )

          setSelectedProjectsIds?.(
            (values) => new Set([...(values ?? []), ...projectsToSelect])
          )
        }

        return
      }

      if (isSelected) {
        setSelectedProjectsIds?.(new Set([]))
      } else {
        setSelectedProjectsIds?.(new Set([id]))
      }
    },
    [isSelected, setSelectedProjectsIds, selectedProjectsIds]
  )

  const [unreadCommentsCount, setUnreadCommentsCount] = React.useState(0)

  React.useEffect(() => {
    localProjectCommentsCount.get().then((value) => {
      if (value) {
        setUnreadCommentsCount(
          Math.min(totalCommentsCount - (value[id] ?? 0), 99)
        )
      }
    })
  }, [id, localProjectCommentsCount])

  return (
    <>
      <Rename
        handleRename={renameProject}
        name={name}
        handleClose={handleModalClose}
        isOpen={showModal == 'rename'}
      />
      <MoveTo
        handleClose={handleModalClose}
        isOpen={showModal == 'moveto'}
        handleMoveTo={moveProjectTo}
      />
      <Delete
        header={'Delete project'}
        callToAction={'Delete'}
        Messsage={
          <>
            <Typography style="Body4Regular">
              Are you sure you want to delete "{name}"?
            </Typography>

            <Typography style="Body5Regular" className="text-gray-500">
              {isPro
                ? 'Deleted projects are available in the "Trash" folder for 30 days, before they are permanently deleted.'
                : 'You can’t undo this action'}
            </Typography>
          </>
        }
        handleClose={handleModalClose}
        isOpen={showModal == 'delete'}
        handleDelete={handleDeleteProject}
      />

      <button
        draggable={canEditProject}
        onClick={handleClick}
        onDoubleClick={() => {
          trackProjectAction(AnalyticsEvent.ProjectOpen)
          window.open(
            canEditProject ? relativeProjectLink : relativeSpecificationsLink,
            '_blank'
          )
        }}
        onMouseLeave={(e) => {
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.blur()
        }}
        onDrag={handleDrag}
        className={classNames('group text-left', {})}
        onDragStart={(e) => {
          if (!isSelected) {
            setSelectedProjectsIds?.(new Set([id]))
          }

          setDraggedFolderOverId?.(undefined)
          e.dataTransfer.setDragImage(dragImage, 0, 0)
          e.dataTransfer.effectAllowed = 'move'
          e.dataTransfer.dropEffect = 'move'
          setDraggedProjectId?.(id)
        }}
        onDragEnd={(e) => {
          if (
            currentFolderId !== draggedFolderOverId &&
            draggedFolderOverId !== undefined &&
            draggedFolderOverId !== id
          ) {
            if ([...(selectedProjectsIds ?? [])].length === 1) {
              moveProjectTo(draggedFolderOverId)
            } else {
              moveSelectedProjectsTo(draggedFolderOverId)
            }
          } else setDraggedFolderOverId?.(undefined)

          e.currentTarget.blur()
          setDraggedProjectId?.(undefined)
          setCurrentMousePosition?.(null)
        }}
        onDrop={(e) => {
          setDraggedProjectId?.(undefined)
          setCurrentMousePosition?.(null)
        }}
      >
        <div
          onMouseLeave={() => {
            setShowProjectOptions(false)
          }}
          className={classNames(
            'group relative flex w-full flex-col rounded-[8px] border-[1px] border-gray-200 bg-white transition-all duration-75 hover:shadow-lg group-focus:shadow-lg',
            {
              [' !border-[#374FD580] !bg-[#374FD50D]']:
                isSelected && !isDragged,
            }
          )}
        >
          <div
            className={classNames(
              'pointer-events-none absolute flex flex-col gap-4 rounded-lg bg-white p-4 shadow-xl',
              {
                ['transition-all ease-out']: isTransitionEnabled,
                ['transition-none']: !isTransitionEnabled,
                // ['opacity-0']: !isDragged,
              }
            )}
            style={fauxMetaHeaderStyle}
          >
            <div className="flex flex-col gap-1 ">
              <div className="flex flex-row justify-between">
                <p className="line-clamp-1 h-6 font-body text-base font-medium text-secondary">
                  {name}
                </p>
                <div
                  className={classNames(
                    'relative z-10 rounded-md p-1 opacity-0 transition-all duration-300'
                  )}
                >
                  {canEditProject &&
                    projectPrivacyIcon[accessByLink](showPrivacyOptions)}
                  <ProjectPrivacyOptions
                    projectId={id}
                    isShown={showPrivacyOptions}
                    handleClose={() => setShowPrivacyOptions(false)}
                    status={accessByLink}
                  />
                </div>
              </div>
              <div className="flex h-6 flex-row items-center gap-2">
                {lastEdit && (
                  <img
                    crossOrigin="anonymous"
                    className="h-5 w-5 rounded-full"
                    width={20}
                    height={20}
                    src={lastEdit.avatarUrl ?? '/images/defaultAvatar.png'}
                  />
                )}
                <p className="line-clamp-2 text-left font-body text-xs font-normal text-gray-400">
                  {lastEdit
                    ? signedLastEditLabel(lastEdit)
                    : projectLastEditedLabel(updatedAt ?? '')}
                </p>
              </div>
            </div>
          </div>

          <div
            className={classNames('flex flex-col', {
              ['pointer-events-none animate-pulse opacity-50 !transition-transform !duration-300']:
                isDragged,
            })}
          >
            <div className="flex h-48 w-full items-center overflow-clip rounded-t-[7px] bg-[#f5f5f5]">
              <img
                onLoad={(e) => {
                  e.currentTarget.classList.add('!opacity-100')
                }}
                crossOrigin="anonymous"
                className="pointer-events-none h-full w-full object-contain p-4 opacity-0 transition-opacity duration-500"
                src={`${config.apiUrl}${coverUrl}`}
                onError={(e) => {
                  // @ts-ignore
                  e.target.src = '/images/default-preview.jpg'
                }}
              />
            </div>

            <div
              ref={metaHeaderRef}
              onMouseLeave={() => {
                setShowPrivacyOptions(false)
              }}
              className="flex flex-col gap-4 p-4"
            >
              <div className="flex flex-col gap-1">
                <div className="flex flex-row justify-between">
                  <p className="line-clamp-1 h-6 font-body text-base font-medium text-secondary">
                    {name}
                  </p>

                  <div
                    className={classNames(
                      'relative z-10 rounded-md p-1 transition-all duration-300',
                      {
                        [' bg-black']: showPrivacyOptions,
                      }
                    )}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowPrivacyOptions(!showPrivacyOptions)
                    }}
                  >
                    {canEditProject &&
                      projectPrivacyIcon[accessByLink](showPrivacyOptions)}
                    <ProjectPrivacyOptions
                      projectId={id}
                      isShown={showPrivacyOptions}
                      handleClose={() => setShowPrivacyOptions(false)}
                      status={accessByLink}
                    />
                  </div>
                </div>
                <div className="flex h-6 flex-row items-center gap-2">
                  {lastEdit && (
                    <img
                      crossOrigin="anonymous"
                      className="h-5 w-5 rounded-full"
                      width={20}
                      height={20}
                      src={lastEdit.avatarUrl ?? '/images/defaultAvatar.png'}
                    />
                  )}
                  <p className="line-clamp-2 text-left font-body text-xs font-normal text-gray-400">
                    {lastEdit
                      ? signedLastEditLabel(lastEdit)
                      : projectLastEditedLabel(updatedAt ?? '')}
                  </p>
                </div>
              </div>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowProjectOptions(!showProjectOptions)
              }}
              className={classNames(
                'invisible absolute right-4 top-4 z-10 flex items-center rounded-md bg-white bg-opacity-80 p-1 opacity-0 ring-gray-200 transition-[box-shadow,opacity] duration-300 group-hover:visible group-hover:opacity-100 group-hover:shadow-lg group-hover:ring-[0.5px]',
                {
                  ['!bg-black !ring-gray-800']: showProjectOptions,
                  ['!visible bg-opacity-40 opacity-100']:
                    unreadCommentsCount > 0,
                  ['!hidden duration-0']:
                    !canEditProject &&
                    isSelected &&
                    (selectedProjectsIds?.size ?? 0) > 1,
                }
              )}
            >
              <Icons.Dots
                className={classNames(
                  'transition-all duration-300 group-hover:stroke-gray-900',
                  {
                    ['!stroke-white']: showProjectOptions,
                    ['stroke-gray-400']: unreadCommentsCount > 0,
                  }
                )}
              />
              <div
                className={classNames(
                  'absolute -right-1 -top-1 z-10 flex h-2 w-2 items-center rounded-full bg-red p-0 ring-2 ring-[#f5f5f5]',
                  { ['hidden']: unreadCommentsCount < 1 }
                )}
              />
              <ProjectContextMenu
                projectId={id}
                owner={{ userId, teamId }}
                projectLink={absoluteProjectLink}
                canEditProject={canEditProject}
                specificationsLink={absoluteSpecificationsLink}
                commentsLink={absoluteCommentsLink}
                isShown={showProjectOptions}
                handleClose={() => {
                  setShowProjectOptions(false)
                }}
                handleModal={setShowModal}
                unreadCommentsCount={unreadCommentsCount}
                // @TODO: IMPORTANT refactor. Move to common use cases
              />
            </div>
          </div>
        </div>
      </button>
    </>
  )
}
