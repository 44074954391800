import { commitUndo, Component } from '@aninix-inc/model'
import { InputWithIcon } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValuePoint2d } from '../../../../../hooks/value-controllers/point-2d'

export interface IProps {
  components: Component[]
  time?: number
  iconX?: React.ReactNode
  iconY?: React.ReactNode
  formatValue?: (value: number) => string
}
export const Point2dValue: React.FCC<IProps> = observer(
  ({ components, time, iconX, iconY, formatValue }) => {
    const { x, y, updateX, updateXByDelta, updateY, updateYByDelta } =
      useValuePoint2d({ components, time })
    const id = components.map((component) => component.id).join('-')
    const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <InputWithIcon
            value={x}
            id={id}
            icon={iconX}
            onChange={updateX}
            onDeltaChange={updateXByDelta}
            onEndChange={endChange}
            format={formatValue}
          />
          <InputWithIcon
            value={y}
            id={id}
            icon={iconY}
            onChange={updateY}
            onDeltaChange={updateYByDelta}
            onEndChange={endChange}
            format={formatValue}
          />
        </div>
      </div>
    )
  }
)
