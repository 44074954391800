import {
  ChildrenExpandedComponent,
  ChildrenRelationsAspect,
  Entity,
  EntityType,
  EntryComponent,
  ParentRelationAspect,
  Project,
  Root,
  SelectionSystem,
  UndoRedoSystem,
  commitUndo,
  deleteNode,
  getEntryOrThrow,
  getPosition,
  getSize,
  moveNodes,
  setCoverTime,
} from '@aninix-inc/model'
import {
  createProjectVersionV2,
  syncProjectWithRemote,
  uploadCover,
} from '@aninix/api'
import { useClipboard } from '@aninix/clipboard'
import { Playback, Session, Viewport, useImagesStore } from '@aninix/core'
import { generateCover, svgToAni } from '@aninix/core/use-cases'
import { useUpdates } from '@aninix/editor/hooks/use-updates'
import { useSelection } from '@aninix/editor/modules/selection'
import { useLogger } from '@aninix/logger'
import { useNotifications } from '@aninix/notifications'
import * as R from 'ramda'
import * as React from 'react'
import { Env, env } from '../../../../config'
import { IUseDownloadProjectUseCase } from '../../../../use-cases'
import { userSettingsLocalStorage } from '../../../../user-settings'

type Payload = {
  dashboardUrl: string
  openUrl: (url: string) => void
  downloadFile: (payload: { strinfigiedData: string; fileName: string }) => void
  downloadProjectUseCase: IUseDownloadProjectUseCase
  project: Project
  session: Session
  viewport: Viewport
  playback: Playback
}

export interface IMenuInteractor {
  downloadProject: () => Promise<void>
  downloadProjectSnapshot: () => Promise<void>
  versionHistory: () => void
  importSvg: () => void
  openMenu: () => void
  closeMenu: () => void
  isMenuVisible: boolean
  project: Project

  goToDashboard: () => void

  canUndo: boolean
  undo: () => void
  canRedo: boolean
  redo: () => void

  cut: () => void
  canCut: boolean
  copy: () => void
  canCopy: boolean
  paste: () => void
  canPaste: boolean
  delete: () => void
  canDelete: boolean

  selectAll: () => void
  deselectAll: () => void

  zoomIn: () => void
  zoomOut: () => void
  zoomTo100: () => void
  zoomToFit: () => void

  openReleaseNotes: () => void
  openHotkeys: () => void
  openDiscord: () => void
  openYoutube: () => void
  openWebsite: () => void
  contactSupport: () => void

  legacyExporter: boolean
  toggleLegacyExporter: () => void
  gifInfiniteLoop: boolean
  toggleGifInfiniteLoop: () => void

  flipHorizontally: () => void
  flipVertically: () => void
  canFlip: boolean

  moveUp: () => void
  moveDown: () => void
  canMoveUp: boolean
  canMoveDown: boolean

  setCurrentCoverTime: () => void

  isProduction: boolean
  isViewVisible: boolean
  children: {}
}

export const useMenuInteractor = ({
  dashboardUrl,
  openUrl,
  downloadProjectUseCase,
  project,
  session,
  viewport,
  playback,
}: Payload): IMenuInteractor => {
  const clipboard = useClipboard()
  const notifications = useNotifications()
  const { selection } = useSelection()
  const [isMenuVisible, setIsMenuVisible] = React.useState(false)
  const [legacyExport, setLegacyExporter] = React.useState(false)
  const [gifInfiniteLoop, setGifInfiniteLoop] = React.useState(true)
  const [isDev, setIsDev] = React.useState(false)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = useUpdates()
  const images = useImagesStore()
  const logger = useLogger()

  const downloadProject = React.useCallback(async () => {
    downloadProjectUseCase.execute({ project, type: 'patch' })
  }, [project, downloadProjectUseCase])

  const downloadProjectSnapshot = React.useCallback(async () => {
    downloadProjectUseCase.execute({ project, type: 'snapshot' })
  }, [project, downloadProjectUseCase])

  const openMenu = React.useCallback(() => {
    setIsMenuVisible(true)
  }, [setIsMenuVisible])

  const closeMenu = React.useCallback(() => {
    setIsMenuVisible(false)
  }, [setIsMenuVisible])

  const goToDashboard = React.useCallback(() => {
    openUrl(dashboardUrl)
    closeMenu()
  }, [openUrl, closeMenu, dashboardUrl])

  const importSvg = React.useCallback(async () => {
    const insertSvg = (svg: string) => {
      let isSuccess = false
      try {
        updates.batch(() => {
          const svgRoot = svgToAni(svg, project)
          const selectedEntity = selection
            .getEntitiesByEntityType(EntityType.Node)
            .at(-1)
          const selectedParent = selectedEntity
            ?.getAspect(ParentRelationAspect)
            ?.getParentEntity()
          if (selectedEntity && selectedParent) {
            moveNodes([svgRoot], selectedParent, { before: selectedEntity })
          }
          selection.deselectAll()
          selection.select([svgRoot.id])
        })
        isSuccess = true
      } catch (e) {
        notifications.showNotification(
          'An error occurred while pasting the SVG element.',
          { autoClose: 1000 }
        )
      } finally {
        commitUndo(project)
        if (!isSuccess) updates.batch(() => undoRedo.undo())
      }
    }

    const data = await navigator.clipboard.readText()

    if (data && data.includes('<svg')) {
      const start = data.indexOf('<svg')
      const end = data.indexOf('</svg>') + 6
      const svg = data.slice(start, end)
      insertSvg(svg)
    } else {
      const input = document.createElement('input')
      input.type = 'file'
      input.style.display = 'none'
      input.accept = '.svg'

      document.body.appendChild(input)

      input.addEventListener('change', function (e) {
        const file = (e.target as HTMLInputElement)?.files?.[0]
        if (!file) {
          document.body.removeChild(input)
          return
        }
        if (file.type !== 'image/svg+xml') {
          document.body.removeChild(input)
          notifications.showNotification('Please select a .svg file', {
            variant: 'error',
          })
          return
        }

        const reader = new FileReader()

        reader.onload = (e) => {
          const svg = e.target?.result
          if (typeof svg !== 'string') {
            document.body.removeChild(input)
            notifications.showNotification('Failed to read file', {
              variant: 'error',
            })

            return
          }

          insertSvg(svg)
        }
        reader.onerror = () => {
          document.body.removeChild(input)
          notifications.showNotification('Failed to read file', {
            variant: 'error',
          })
        }
        reader.readAsText(file)
        document.body.removeChild(input)
      })
      input.click()
    }
  }, [project, updates, selection, notifications])

  const versionHistory = React.useCallback(async () => {
    notifications.showNotification('Loading project history. Please wait...', {
      id: 'loading-history',
    })
    closeMenu()

    await generateCover({ project, images })
      .then((image) =>
        uploadCover({ projectId: project.id, hash: 'cover', image })
      )
      .then(() => syncProjectWithRemote(project))
      .then(() => createProjectVersionV2(project.id))
      .then(() => {
        window.location.assign(`/history/${project.id}`)
      })
      .catch((err) => {
        logger.error(err)
        notifications.showNotification("Couldn't load project history", {
          variant: 'error',
        })
      })
  }, [openUrl, closeMenu, project])

  const selectAll = React.useCallback(() => {
    const selection = project.getSystemOrThrow(SelectionSystem)
    const firstLevelOfNodes = project.getEntitiesByPredicate(
      (entity) =>
        entity
          .getAspect(ParentRelationAspect)
          ?.getParentEntity()
          ?.hasComponent(EntryComponent) ?? false
    )
    selection.select(firstLevelOfNodes.map((n) => n.id))
    commitUndo(project)
    const entry = getEntryOrThrow(project)
    entry.updateComponent(ChildrenExpandedComponent, true)
    closeMenu()
  }, [project, closeMenu])

  const deselectAll = React.useCallback(() => {
    selection.deselectAll()
    closeMenu()
  }, [selection, closeMenu])

  const canUndo = undoRedo.canUndo()

  const undo = React.useCallback(() => {
    selection.deselectAll()
    undoRedo.undo()
    closeMenu()
  }, [undoRedo, closeMenu])

  const canRedo = undoRedo.canRedo()

  const redo = React.useCallback(() => {
    undoRedo.redo()
    closeMenu()
  }, [undoRedo, closeMenu])

  const cut = React.useCallback(() => {
    document.execCommand('cut')
    closeMenu()
  }, [session, clipboard, closeMenu])

  const copy = React.useCallback(() => {
    clipboard.copyCurrentSelection()
    closeMenu()
  }, [session, clipboard, closeMenu])

  const paste = React.useCallback(() => {
    document.execCommand('paste')
    closeMenu()
  }, [clipboard, playback, closeMenu])

  const canDelete =
    selection.selection().length !== 0 &&
    selection.getEntitiesByEntityType(EntityType.Node).length !== 0

  // @TODO: move to common place becuase it's using at hotkeys.interactor as well
  const customDelete = React.useCallback(() => {
    const selectedNodes = selection.getEntitiesByEntityType(EntityType.Node)
    selection.deselectAll()
    selectedNodes.forEach(deleteNode)
    closeMenu()
  }, [session, project, closeMenu])

  const zoomIn = React.useCallback(() => {
    viewport.zoomToPoint({
      point: viewport.center,
      zoomStep: 1.25,
    })
    closeMenu()
  }, [viewport, closeMenu])

  const zoomOut = React.useCallback(() => {
    viewport.zoomToPoint({
      point: viewport.center,
      zoomStep: 0.75,
    })
    closeMenu()
  }, [viewport, closeMenu])

  const zoomToFit = React.useCallback(() => {
    const entry = getEntryOrThrow(project)
    const position = getPosition(entry)
    const size = getSize(entry)
    // @NOTE: zoom to fit
    viewport.zoomToRect({
      x: position.x,
      y: position.y,
      width: size.x,
      height: size.y,
    })
    closeMenu()
  }, [viewport, project, playback, closeMenu])

  const zoomTo100 = React.useCallback(() => {
    viewport.updateZoom(1)
    closeMenu()
  }, [viewport, closeMenu])

  const openReleaseNotes = React.useCallback(() => {
    openUrl('http://www.aninix.com/releases')
    closeMenu()
  }, [openUrl, closeMenu])

  const openHotkeys = React.useCallback(() => {
    openUrl('http://www.aninix.com/wiki/hotkeys')
    closeMenu()
  }, [openUrl, closeMenu])

  const openDiscord = React.useCallback(() => {
    openUrl('https://discord.gg/qf4A396qey')
    closeMenu()
  }, [openUrl, closeMenu])

  const openYoutube = React.useCallback(() => {
    openUrl('https://www.youtube.com/channel/UCIOURq8ExveSHjjiMAAz2SQ')
    closeMenu()
  }, [openUrl, closeMenu])

  const openWebsite = React.useCallback(() => {
    openUrl('https://www.aninix.com')
    closeMenu()
  }, [openUrl, closeMenu])

  const contactSupport = React.useCallback(() => {
    openUrl('mailto:info@aninix.com')
    closeMenu()
  }, [openUrl, closeMenu])

  const toggleLegacyExporter = React.useCallback(async () => {
    setLegacyExporter((value) => !value)
  }, [])
  const toggleGifInfiniteLoop = React.useCallback(async () => {
    setGifInfiniteLoop((value) => !value)
  }, [])

  const flipHorizontally = React.useCallback(() => {
    console.warn('@TODO: implement')
    // const selectedNodes = session.getSelectedNodes()
    // const keyframes = session
    //   .getSelectedKeyframes()
    //   .filter((keyframe) => keyframe.getProperty().type === PropertyType.Scale)

    // if (selectedNodes.length === 0 && keyframes.length === 0) {
    //   toast('Nothing to flip, select at least 1 layer or keyframe')
    //   return
    // }

    // if (keyframes.length > 0) {
    //   keyframes.forEach((keyframe) => {
    //     const { x, y } = keyframe.value
    //     keyframe.updateValue({ x: -x, y })
    //   })
    // } else {
    //   selectedNodes.forEach((node) => {
    //     const { x, y } = node.scale.getValue(playback.time)
    //     node.scale.updateValue(playback.time, { x: -x, y })
    //   })
    // }
    // commitUndo(keyframes[0].project)
  }, [session])

  const flipVertically = React.useCallback(() => {
    console.warn('@TODO: implement')
    // const selectedNodes = session.getSelectedNodes()
    // const keyframes = session
    //   .getSelectedKeyframes()
    //   .filter((keyframe) => keyframe.getProperty().type === PropertyType.Scale)

    // if (selectedNodes.length === 0 && keyframes.length === 0) {
    //   toast('Nothing to flip, select at least 1 layer or keyframe')
    //   return
    // }

    // if (keyframes.length > 0) {
    //   keyframes.forEach((keyframe) => {
    //     const { x, y } = keyframe.value
    //     keyframe.updateValue({ x, y: -y })
    //   })
    // } else {
    //   selectedNodes.forEach((node) => {
    //     const { x, y } = node.scale.getValue(playback.time)
    //     node.scale.updateValue(playback.time, { x, y: -y })
    //   })
    // }
    // commitUndo(keyframes[0].project)
  }, [session])

  // const canFlip =
  //   session.getSelectedKeyframes().length > 0 ||
  //   session.getSelectedNodes().length > 0
  const canFlip = false

  const moveDown = React.useCallback(() => {
    const selectedNodes = selection.getEntitiesByEntityType(EntityType.Node)
    if (selectedNodes.length === 0) return

    const groupedByParent = R.groupBy(
      (node) =>
        node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow().id,
      selectedNodes
    )

    updates.batch(() => {
      Object.values(groupedByParent).forEach((nodes) => {
        if (!nodes || nodes.length === 0) return
        const parent = nodes[0]
          .getAspectOrThrow(ParentRelationAspect)
          .getParentEntityOrThrow()

        const sortedNodes = R.sortBy(
          (node) =>
            parent
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getIndexOfById(node.id),
          nodes
        )

        const groupedByAdjacency = sortedNodes.reduce<Entity[][]>(
          (acc, node, index) => {
            if (
              index === 0 ||
              parent
                .getAspectOrThrow(ChildrenRelationsAspect)
                .getIndexOfById(node.id) !==
                parent
                  .getAspectOrThrow(ChildrenRelationsAspect)
                  .getIndexOfById(sortedNodes[index - 1].id) +
                  1
            ) {
              acc.push([node])
            } else {
              acc[acc.length - 1].push(node)
            }
            return acc
          },
          []
        )

        groupedByAdjacency.forEach((group) => {
          const nextSibling = parent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
            .find(
              (_, index, array) =>
                array[index - 1]?.id === group[group.length - 1].id
            )

          if (nextSibling) moveNodes(group, parent, { after: nextSibling })
        })
      })
    })
    commitUndo(project)
  }, [selection])

  const moveUp = React.useCallback(() => {
    const selectedNodes = selection.getEntitiesByEntityType(EntityType.Node)
    if (selectedNodes.length === 0) return

    const groupedByParent = R.groupBy(
      (node) =>
        node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow().id,
      selectedNodes
    )

    updates.batch(() => {
      Object.values(groupedByParent).forEach((nodes) => {
        if (!nodes || nodes.length === 0) return
        const parent = nodes[0]
          .getAspectOrThrow(ParentRelationAspect)
          .getParentEntityOrThrow()

        const sortedNodes = R.sortBy(
          (node) =>
            parent
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getIndexOfById(node.id),
          nodes
        )

        const groupedByAdjacency = sortedNodes.reduce<Entity[][]>(
          (acc, node, index) => {
            if (
              index === 0 ||
              parent
                .getAspectOrThrow(ChildrenRelationsAspect)
                .getIndexOfById(node.id) !==
                parent
                  .getAspectOrThrow(ChildrenRelationsAspect)
                  .getIndexOfById(sortedNodes[index - 1].id) +
                  1
            ) {
              acc.push([node])
            } else {
              acc[acc.length - 1].push(node)
            }
            return acc
          },
          []
        )

        groupedByAdjacency.forEach((group) => {
          const previousSibling = parent
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
            .find((_, index, array) => array[index + 1]?.id === group[0].id)

          if (previousSibling)
            moveNodes(group, parent, { before: previousSibling })
        })
      })
    })
    commitUndo(project)
  }, [selection])

  const hasSelectedNodes =
    selection.getEntitiesByEntityType(EntityType.Node).length > 0

  const canMoveUp = hasSelectedNodes
  const canMoveDown = hasSelectedNodes

  const setCurrentCoverTime = () => {
    const root = project.getEntityByTypeOrThrow(Root)

    setCoverTime(root, playback.time)
  }

  React.useEffect(() => {
    userSettingsLocalStorage.get().then((userSettings) => {
      if (userSettings != null) {
        setLegacyExporter(userSettings.legacyExport ?? false)
        setGifInfiniteLoop(userSettings.gifInfiniteLoop ?? true)
      }
    })
  }, [])
  React.useEffect(() => {
    userSettingsLocalStorage.get().then((userSettings) => {
      if (userSettings?.legacyExport === legacyExport) {
        return
      }

      userSettingsLocalStorage.update({
        legacyExport,
      })
    })
  }, [legacyExport])
  React.useEffect(() => {
    userSettingsLocalStorage.get().then((userSettings) => {
      if (userSettings?.gifInfiniteLoop === gifInfiniteLoop) {
        return
      }

      userSettingsLocalStorage.update({
        gifInfiniteLoop,
      })
    })
  }, [gifInfiniteLoop])

  // @NOTE: assign function to window so we can trigger isProduction switch from console
  React.useEffect(() => {
    // @ts-ignore
    window.__aninix_devtools ??= {}
    // @ts-ignore
    window.__aninix_devtools.enableDevMenu = () => setIsDev(true)
  }, [])

  return {
    downloadProject,
    downloadProjectSnapshot,
    importSvg,
    versionHistory,
    openMenu,
    closeMenu,
    goToDashboard,
    canUndo,
    undo,
    canRedo,
    redo,
    cut,
    canCut: true,
    copy,
    canCopy: true,
    paste,
    canPaste: true,
    delete: customDelete,
    canDelete,
    selectAll,
    deselectAll,
    zoomIn,
    zoomOut,
    zoomTo100,
    zoomToFit,
    openReleaseNotes,
    openHotkeys,
    openDiscord,
    openYoutube,
    openWebsite,
    contactSupport,
    isMenuVisible,
    project,
    isProduction: isDev === false && env === Env.Production,
    isViewVisible: true,
    legacyExporter: legacyExport,
    toggleLegacyExporter,
    gifInfiniteLoop,
    toggleGifInfiniteLoop,

    flipHorizontally,
    flipVertically,
    moveUp,
    moveDown,
    canMoveUp,
    canMoveDown,
    canFlip,

    setCurrentCoverTime,

    children: {},
  }
}
