import {
  DurationComponent,
  Entity,
  EntityType,
  FpsComponent,
  Project,
  Root,
  SelectionSystem,
  UpdatesSystem,
  commitUndo,
} from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import {
  KeyModificator,
  getGroupFromKeyframe,
  getSelection,
  useEntity,
  usePlayback,
  useSession,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import * as timeConverters from '../../../../helpers/timeConverters'
import { useFormattedTime } from '../../../properties-panel/components/formatted-time'
import { useSnappedTime } from '../time-indicators'
import { GhostSliderView } from './ghost-slider-view'
import { TimeSliderView } from './time-slider-view'

// TODO: refactor
const handlerWidth = 6

export interface IProps {
  project: Project
  parentTrackWidth: number
}
export const TimeSlider: React.FCC<IProps> = observer(
  ({ project, parentTrackWidth }) => {
    const root = project.getEntityByTypeOrThrow(Root)
    useEntity(root)
    const playback = usePlayback()
    const session = useSession()
    const projectDuration = root.getComponentOrThrow(DurationComponent).value
    const projectFps = root.getComponentOrThrow(FpsComponent).value
    const selection = project.getSystemOrThrow(SelectionSystem)
    const updates = project.getSystemOrThrow(UpdatesSystem)
    const { getSnappedTime } = useSnappedTime({ project })

    const updateTime = React.useCallback(
      (time: number) => {
        playback.pause()
        if (session.keyModificators.includes(KeyModificator.Shift)) {
          playback.updateTime(getSnappedTime(time))
        } else {
          playback.updateTime(time)
        }
        // @TODO: move to use case
        // @NOTE: deselct keyframes when slider moves
        const selectedKeyframes = getSelection(project, EntityType.Keyframe)
        updates.batch(() => {
          if (selectedKeyframes.length > 0) {
            let nodesToSelect: Entity[] = []
            selectedKeyframes.forEach((keyframe) => {
              const group = getGroupFromKeyframe(keyframe)

              if (group.layer != null) {
                nodesToSelect.push(group.layer)
              }
            })
            selection.replace(R.uniq(nodesToSelect.map((n) => n.id)))
          }
        })
        commitUndo(project)
      },
      [projectFps, playback, session, selection, getSnappedTime, project]
    )

    const releaseGhost = React.useCallback(() => {
      playback.updateTime(playback.ghostTime ?? 0).resetGhostTime()
    }, [])

    const initialTime = React.useRef<number>(0)

    const { offsetX, isListening, startListen } = useMouseMove()

    const time = useFormattedTime(playback.time)
    const ghostTime = useFormattedTime(playback.ghostTime ?? -1)

    React.useEffect(() => {
      if (isListening) {
        const timeOffset = timeConverters.convertPixelsToTime({
          pixels: offsetX,
          projectDuration: projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
        })

        updateTime(timeOffset + initialTime.current)
      }
    }, [isListening, offsetX])

    React.useEffect(() => {
      if (isListening === false) {
        initialTime.current = playback.time as number
      }
    }, [isListening, playback.time])

    const handleMouseDown = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        // @ts-ignore
        startListen(e)
      },
      [startListen]
    )

    const leftCoordForTime = React.useMemo(
      () =>
        timeConverters.convertTimeToPixels({
          time: playback.time,
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
        }) + handlerWidth,
      [playback.time, projectDuration, parentTrackWidth]
    )

    const leftCoordForGhost = React.useMemo(() => {
      return (
        timeConverters.convertTimeToPixels({
          time: ghostTime.value,
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
        }) + handlerWidth
      )
    }, [ghostTime.value, projectDuration, parentTrackWidth])

    return (
      <>
        {ghostTime.value !== -1 && (
          <GhostSliderView
            left={leftCoordForGhost}
            label={`${ghostTime.value}${ghostTime.suffix}`}
            onClick={releaseGhost}
          />
        )}

        <TimeSliderView
          left={leftCoordForTime}
          label={`${time.value}${time.suffix}`}
          onMouseDown={handleMouseDown}
        />
      </>
    )
  }
)
