/**
 * @todo probably not used. If so then remove
 */
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { usePaymentInteractor } from './interactor'
import { PaymentRouter } from './router'

export interface IProps {}
export const Payment: React.FCC<IProps> = observer(() => {
  const location = useLocation()
  const status = new URLSearchParams(location.search).get('status') as any
  const interactor = usePaymentInteractor({ status })

  return <PaymentRouter interactor={interactor} />
})
