import {
  Component,
  ScaleLockedComponent,
  SizeLockedComponent,
  commitUndo,
} from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useValueLockablePoint2d } from '../../../../../hooks/value-controllers/lockable-point-2d'
import { NumericValue } from '../numeric-value'

const btnSize = {
  width: 16,
  height: 16,
}
const iconSize = {
  x: 16,
  y: 16,
}

// @TODO: provide generic types
export interface IProps {
  components: Component[]
  lockableComponents: (ScaleLockedComponent | SizeLockedComponent)[]
  color?: string
  time?: number
  formatValue?: (value: number) => string
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  minX?: number
  maxX?: number
  minY?: number
  maxY?: number
}
export const LockablePoint2dValue: React.FCC<IProps> = observer(
  ({
    components,
    lockableComponents,
    color = 'black',
    time,
    formatValue,
    before = R.identity,
    after = R.identity,
    minX,
    maxX,
    minY,
    maxY,
  }) => {
    const { x, y, lockType, toggleLock, updateX, updateY } =
      useValueLockablePoint2d({
        components,
        lockableComponents,
        time,
      })

    const onEndChange = React.useCallback(() => {
      commitUndo(components[0].entity.getProjectOrThrow())
    }, [components])

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <buttons.Icon btnSize={btnSize} onClick={toggleLock}>
            {/* @ts-ignore */}
            <icons.Link type={lockType} size={iconSize} />
          </buttons.Icon>
          <NumericValue
            onEndChange={onEndChange}
            suffix=", "
            value={before(x as number)}
            onChange={(newValue) => updateX(after(newValue))}
            textColor={color}
            min={minX}
            max={maxX}
            format={formatValue}
          />
          <NumericValue
            onEndChange={onEndChange}
            value={before(y as number)}
            onChange={(newValue) => updateY(after(newValue))}
            textColor={color}
            min={minY}
            max={maxY}
            format={formatValue}
          />
        </div>
      </div>
    )
  }
)
