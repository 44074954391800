import { makeAutoObservable } from 'mobx'

const never = false
const devOnly = process.env.ENV !== 'prod'
const prod = true

class FeatureFlags {
  pixiRenderer = never

  pixiDebug = devOnly

  downloadProjectInErrorBoundary = devOnly

  lottiePreview = devOnly

  newTimeline = devOnly

  overrides = devOnly

  refactoredInputWithIcon = prod

  editPath = devOnly

  timelineDnd = prod

  aninixToFigmaInstruction = prod

  shapesCreation = devOnly

  importSvg = prod

  transformSvg = devOnly

  transformSvgDebug = devOnly

  feedbackPage = prod

  importLottie = devOnly

  smartSvg = prod

  anchorPointDnd = devOnly

  gradientTransform = devOnly

  constructor() {
    makeAutoObservable(this)

    if (window != null) {
      // @ts-ignore
      window.__aninix_devtools ??= {}
      // @ts-ignore
      window.__aninix_devtools.featureFlags = this
    }
  }
}

export default new FeatureFlags()
