import classNames from 'classnames'
import * as React from 'react'
import { Loader } from '../common'

const UnlimitedProjectsIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18.5749 24.8411L18.6239 25.65C18.6239 27.1758 18.1715 28.6672 17.3238 29.9359C16.4762 31.2045 15.2714 32.1932 13.8618 32.7771C12.4522 33.361 10.9011 33.5137 9.40467 33.2161C7.90824 32.9184 6.53369 32.1837 5.45483 31.1049C4.37596 30.026 3.64125 28.6514 3.34359 27.155C3.04593 25.6586 3.1987 24.1075 3.78258 22.6979C4.36646 21.2883 5.35522 20.0835 6.62382 19.2358C7.89243 18.3882 9.375 18 10.875 18C16.125 18 19.875 18.75 25.5 18.75C27 18.75 28.4731 18.3657 29.7417 17.518C31.0103 16.6704 31.999 15.4656 32.5829 14.056C33.1668 12.6464 33.3196 11.0953 33.0219 9.59885C32.7242 8.10242 31.9895 6.72787 30.9107 5.64901C29.8318 4.57014 28.4573 3.83543 26.9608 3.53777C25.4644 3.24011 23.9133 3.39288 22.5037 3.97676C21.0941 4.56063 19.8893 5.54939 19.0417 6.818C18.194 8.08661 17.7416 9.57809 17.7416 11.1038L17.7906 11.9128"
      stroke="#1BC47D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const UnlimitedLinksIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.25 21.9745C15.5046 23.27 17.2217 24 19.0143 24C20.8069 24 22.5241 23.27 23.7786 21.9745L31.4016 14.2621C34.0328 11.6 34.0328 7.28376 31.4016 4.62161C28.7703 1.95946 24.5042 1.95946 21.8729 4.62161L20.92 5.58566"
      stroke="#1BC47D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.75 14.0255C20.4954 12.73 18.7783 12 16.9857 12C15.1931 12 13.4759 12.73 12.2214 14.0255L4.59845 21.7379C1.96718 24.4 1.96718 28.7162 4.59845 31.3784C7.22971 34.0405 11.4958 34.0405 14.1271 31.3784L15.0799 30.4143"
      stroke="#1BC47D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const PrioritySupportIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M18 9.09684C18.7122 7.37186 19.9929 5.94966 21.6244 5.07199C23.256 4.19433 25.1376 3.91541 26.9495 4.28264C28.7614 4.64987 30.3916 5.64059 31.563 7.08636C32.7344 8.53212 33.3747 10.3437 33.375 12.2131C33.375 22.7898 18 31.5 18 31.5C18 31.5 2.625 22.7898 2.625 12.2131C2.62531 10.3437 3.2656 8.53213 4.43701 7.08636C5.60842 5.64059 7.23864 4.64987 9.0505 4.28264C10.5461 3.97951 12.0892 4.11664 13.5 4.66736"
      stroke="#1BC47D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ProPresetsIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.875 23.5661C8.61112 21.605 5.76604 20.2952 2.625 19.9219C3.48076 18.4375 4.63995 17.1884 6 16.236C7.1308 15.4441 8.40046 14.8575 9.75 14.5113C10.5539 14.3051 11.3862 14.1843 12.2344 14.1562C14.5559 7.55697 20.6137 2.625 27.6094 2.625C32.25 2.625 33.375 3.75 33.375 8.39062C33.375 15.3863 28.443 21.4441 21.8437 23.7656C21.7122 27.75 19.5319 31.3839 16.0781 33.375C15.8552 31.4992 15.0802 29.7289 14.25 28.125C11.9406 32.2156 7.5 33 3 33C3 30.4861 3.24481 27.9906 4.17412 25.875"
      stroke="#1BC47D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="24.375"
      cy="11.625"
      r="1.875"
      stroke="#1BC47D"
      strokeWidth="4.69286"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export type Props = {
  isLoading: boolean
  monthlyPriceInUsd?: number
  annualPriceInUsd?: number
  onPurchaseClick: () => void
  onCancelClick?: () => void
  className?: string
}
export const UpgradeToPro: React.FC<Props> = ({
  isLoading,
  monthlyPriceInUsd,
  annualPriceInUsd,
  onPurchaseClick,
  onCancelClick,
  className,
}) => (
  <div className={classNames('flex flex-col gap-6', className)}>
    <p className="txt-[32_48_700] text-center">Experience the might of PRO</p>

    <ul
      className={classNames(
        'txt-[14_18_600]',
        'grid grid-cols-2 gap-2',
        '[&>li]:flex [&>li]:flex-row [&>li]:items-center [&>li]:gap-4 [&>li]:border-[1px] [&>li]:border-solid [&>li]:border-[#1BC47D33] [&>li]:p-4 [&>li]:rounded-[8px]'
      )}
    >
      <li>
        <UnlimitedProjectsIcon className="flex-shrink-0" />
        <p>Unlimited projects</p>
      </li>

      <li>
        <UnlimitedLinksIcon className="flex-shrink-0" />
        <p>Unlimited animation specs</p>
      </li>

      <li>
        <PrioritySupportIcon className="flex-shrink-0" />
        <p>Priority support</p>
      </li>

      <li>
        <ProPresetsIcon className="flex-shrink-0" />
        <p>Pro presets</p>
      </li>
    </ul>

    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center gap-4">
        <p className="txt-[16_18_400]">
          <strong className="txt-[36_40_700]">${monthlyPriceInUsd}</strong>{' '}
          /month*
        </p>

        <div className="flex flex-row items-center gap-4">
          {isLoading && <Loader size={24} color="#18a0fb" />}

          {isLoading ? (
            <button
              onClick={onCancelClick}
              className={classNames(
                'txt-[18_24_600] text-white bg-red px-4 py-2 rounded-[8px]',
                'flex flex-row items-center gap-2',
                'hover:scale-105',
                'transition-transform duration-300 hover:duration-150 hover:ease-in ease-out'
              )}
            >
              Cancel payment
            </button>
          ) : (
            <button
              onClick={onPurchaseClick}
              className={classNames(
                'txt-[18_24_600] text-white bg-green px-4 py-2 rounded-[8px]',
                'flex flex-row items-center gap-2',
                'hover:scale-105',
                'transition-transform duration-300 hover:duration-150 hover:ease-in ease-out',
                'disabled:opacity-60 disabled:cursor-not-allowed'
              )}
              disabled={isLoading}
            >
              Upgrade now
            </button>
          )}
        </div>
      </div>

      <p className="txt-[12_16_400] text-[#0000004D] text-center">
        *Or ${annualPriceInUsd} billed annually as a recurrent payment.
        <br />
        Choose subscription recurrence at checkout.
      </p>
    </div>
  </div>
)
