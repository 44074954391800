import { usePlayback, useSession } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import {
  Project,
  SelectionSystem,
  UndoRedoSystem,
  UpdatesSystem,
} from '@aninix-inc/model'
import { usePresetsStore } from '../../stores'
import { useUseCases } from '../../use-cases'
import { useAnimationPresetsInteractor } from './interactor'
import { AnimationPresetsRouter } from './router'

export interface IProps {
  project: Project
}
export const AnimationPresets: React.FCC<IProps> = observer(({ project }) => {
  const session = useSession()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const playback = usePlayback()
  const { useApplyPresetUseCase } = useUseCases()
  const presetsStore = usePresetsStore()
  const applyPresetUseCase = useApplyPresetUseCase({
    playback,
    selection,
    undoRedo,
    updates,
  })
  const interactor = useAnimationPresetsInteractor({
    presetsStore,
    session,
    applyPresetUseCase,
  })

  return <AnimationPresetsRouter interactor={interactor} />
})
