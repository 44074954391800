import { ChildrenRelationsAspect, Entity } from '@aninix-inc/model'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ImagesStore } from '../../../stores'
import { BlendAnimation } from './blend'
import { GeometryAnimation } from './geometry'
import { LayoutAnimation } from './layout'

export interface IProps {
  entity: Entity
  images: ImagesStore
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const AnimatedProperties: React.FCC<IProps> = observer(
  ({ entity, images, timeRange, startEvent, endEvent }) => (
    <>
      <BlendAnimation
        entity={entity}
        timeRange={timeRange}
        startEvent={startEvent}
        endEvent={endEvent}
      />

      <GeometryAnimation
        entity={entity}
        images={images}
        timeRange={timeRange}
        startEvent={startEvent}
        endEvent={endEvent}
      />

      <LayoutAnimation
        entity={entity}
        timeRange={timeRange}
        startEvent={startEvent}
        endEvent={endEvent}
      />

      {entity.hasAspect(ChildrenRelationsAspect) &&
        entity
          .getAspectOrThrow(ChildrenRelationsAspect)
          .getChildrenList()
          .map((child) => (
            <AnimatedProperties
              entity={child}
              images={images}
              key={child.id}
              timeRange={timeRange}
              startEvent={startEvent}
              endEvent={endEvent}
            />
          ))}
    </>
  )
)
