import {
  Entity,
  RgbaValueComponent,
  Root,
  getEntryOrThrow,
  getSize,
} from '@aninix-inc/model'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ImagesStore } from '../../stores'
import { SvgLayerDataProvider } from '../common/renderers/svg-layer-data-provider'

export interface IProps {
  /**
   * @description node to render. It will fit viewbox to bounding box of the provided node
   */
  entity: Entity
  time: number
  imagesStore: ImagesStore
  /**
   * When true renderer will create additional background color from project.backgroundColor.
   * It can be useful in case of render mp4 files.
   */
  forceBgColor?: boolean
}
export const Renderer: React.FCC<IProps> = observer(
  ({ entity, time, imagesStore, forceBgColor = false }) => {
    const project = entity.getProjectOrThrow()
    const root = project.getEntityByTypeOrThrow(Root)
    const entry = getEntryOrThrow(project)
    const size = getSize(entry)
    const backgroundColor = root.getComponentOrThrow(RgbaValueComponent).value

    return (
      <svg
        id="stage"
        width={size.x}
        height={size.y}
        viewBox={`0 0 ${size.x} ${size.y}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {forceBgColor && (
          <rect
            x={0}
            y={0}
            width={size.x}
            height={size.y}
            fill={`rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 1)`}
          />
        )}

        <SvgLayerDataProvider
          entity={entity}
          time={time}
          imagesStore={imagesStore}
        />
      </svg>
    )
  }
)
