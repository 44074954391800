// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KHCW8Iwi2g5w38PW58N6{display:flex;flex-flow:column nowrap;align-items:stretch;justify-content:space-between;width:200px;margin:0 auto;padding:8px 0;overflow:visible;background-color:#0b1118;border-radius:2px}.KHCW8Iwi2g5w38PW58N6 hr{width:100%;height:1px;margin-top:8px;margin-bottom:8px;background-color:rgba(255,255,255,.2);border:none}.KHCW8Iwi2g5w38PW58N6 li{color:#fff;font-size:12px;list-style-type:none}.KHCW8Iwi2g5w38PW58N6 li a{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:space-between;padding:4px 16px;color:inherit;text-decoration:none;cursor:default}.KHCW8Iwi2g5w38PW58N6 li a:link{color:inherit}.KHCW8Iwi2g5w38PW58N6 li a:hover{background-color:var(--figma-color-bg-brand)}.KHCW8Iwi2g5w38PW58N6 li a span{display:block;margin:0;color:var(--aninix-color-foreground-white-4)}.LAfNaQkhmpzFx7YV4yhM{position:absolute;right:12px;bottom:0;width:10px;height:10px;overflow:hidden;background-color:#0b1118;transform:translate(-50%, 50%) rotate(-45deg)}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/helper/popup/index.scss","webpack://./packages/app-design-system/src/theme/colors.scss","webpack://./packages/app-design-system/src/theme/figma-colors.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,uBAAA,CACA,mBAAA,CACA,6BAAA,CACA,WAAA,CACA,aAAA,CACA,aAAA,CACA,gBAAA,CAEA,wBCkCe,CDjCf,iBAAA,CAEA,yBACE,UAAA,CACA,UAAA,CACA,cAAA,CACA,iBAAA,CACA,qCCCc,CAAA,WAAA,CDGhB,yBACE,UCEe,CDDf,cAAA,CACA,oBAAA,CAEA,2BACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,6BAAA,CACA,gBAAA,CACA,aAAA,CACA,oBAAA,CACA,cAAA,CAEA,gCACE,aAAA,CAGF,iCACE,4CE1CY,CF6Cd,gCACE,aAAA,CACA,QAAA,CACA,4CGjDc,CHuDtB,sBACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,wBClBe,CDmBf,6CAAA","sourceRoot":""}]);
// Exports
export var ul = `KHCW8Iwi2g5w38PW58N6`;
export var romb = `LAfNaQkhmpzFx7YV4yhM`;
export default ___CSS_LOADER_EXPORT___;
