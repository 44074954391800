import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { UpgradeToPro, WelcomeToTheProClub } from '@aninix/app-design-system'
import { useNotifications } from '@aninix/notifications'
import { Backdrop, Modal } from '@material-ui/core'
import * as React from 'react'
import { useSessionStore } from '../../stores'
import { useUpgradeToPro } from '../../use-cases'

export type Props = {
  isVisible: boolean
  onClose: () => void
  /**
   * Provide context where this modal appeared.
   * @example editor
   */
  context: string
  /**
   * Provide if upgrade required for the team
   */
  teamId?: string
}
export const Paywall: React.FC<Props> = ({
  isVisible,
  context,
  onClose,
  teamId,
}) => {
  const sessionStore = useSessionStore()
  const upgradeToPro = useUpgradeToPro({ sessionStore, teamId })
  const analytics = useAnalytics()
  const notifications = useNotifications()

  const startUpgradeToPro = React.useCallback(async () => {
    const result = await upgradeToPro.start()

    if (result?.url == null) {
      notifications.showNotification(
        'Purchase initiation failed. Please try again',
        { variant: 'error', id: 'start-upgrade-to-pro' }
      )
      return
    }

    analytics.track({
      eventName: AnalyticsEvent.PaywallModalUpgradeClicked,
      properties: {
        team: teamId,
        context,
      },
    })
    window.open(result.url, '_blank')
  }, [notifications, upgradeToPro, analytics, teamId, context])

  React.useEffect(() => {
    if (!isVisible) {
      return
    }

    analytics.track({
      eventName: AnalyticsEvent.PaywallModalViewed,
      properties: {
        team: teamId,
        context,
      },
    })
  }, [isVisible])

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      className="flex h-full w-full flex-col items-center justify-center"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      {upgradeToPro.state === 'success' ? (
        <WelcomeToTheProClub
          onClick={onClose}
          className="bg-white p-6 rounded-[12px] max-w-[500px]"
        />
      ) : (
        <UpgradeToPro
          isLoading={upgradeToPro.state === 'pending'}
          // @TODO: make price dynamic
          monthlyPriceInUsd={teamId != null ? 15 : 10}
          annualPriceInUsd={teamId != null ? 144 : 96}
          onPurchaseClick={startUpgradeToPro}
          onCancelClick={upgradeToPro.cancel}
          className="bg-white p-6 rounded-[12px] max-w-[500px]"
        />
      )}
    </Modal>
  )
}
